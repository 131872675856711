import APIDetails from "views/APIs/APIDetails.js";
import QUApi from "views/Dashboard/QUApi.js";

import adminErrorPage from "views/Pages/AdminErrorPage.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import VerificationPage from "views/Pages/VerificationPage";
import RegisterPage from "views/Pages/RegisterPage.js";
import UpdatePasswordPage from "views/Pages/UpdatePasswordPage";
import PricingPage from "views/Pages/PricingPage";
import ResetPasswordPage from "views/Pages/ResetPasswordPage";
import UserProfile from "views/Pages/UserProfile.js";
import QuApiVault from "views/Dashboard/QuApiVault.js";
import AppsIcon from "@material-ui/icons/Apps";
import StorageIcon from "@material-ui/icons/Storage";
import ExploreIcon from "@material-ui/icons/Explore";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import BuildIcon from "@material-ui/icons/Build";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TableChartIcon from "@material-ui/icons/TableChart";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WorkIcon from "@material-ui/icons/Work";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Projects from "views/Dashboard/Projects";
import Resources from "views/Dashboard/Resources";
import Experiments from "views/Dashboard/Experiments";
import UserTablePage from "views/Pages/UserTablePage";
import CourseProjects from "views/Dashboard/CourseProjects";
import FilePage from "views/Dashboard/FilePage";
import ModuleLinks from "views/Dashboard/ModuleLinks";
import Notes from "views/Components/Notes";
import CourseDesign from "views/Dashboard/Course/CourseDesign";
import ModulesDesign from "views/Dashboard/ModulesDesign";
import ModulesBoard from "views/Dashboard/Course/ModulesBoard";
import LandingPage from "views/Dashboard/LandingPage";
import LectureDesign from "views/Dashboard/Lecture/LectureDesign";
import LectureBoard from "views/Dashboard/Lecture/LectureBoard";
import WritingsBoard from "views/Dashboard/Writing/WritingsBoard";
import WritingPublisher from "views/Dashboard/Writing/WritingPublisher";
import LabsDesign from "views/Dashboard/Lab/LabsDesign";
import LabsBoard from "views/Dashboard/Lab/LabsBoard";
import PodcastDesign from "views/Dashboard/Podcast/PodcastDesign";
import PodcastBoard from "views/Dashboard/Podcast/PodcastBoard";

var dashRoutes = [
  {
    path: "/auth/login-page",
    hidden: true,
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/error-page",
    hidden: true,
    name: "error",
    component: ErrorPage,
    layout: "/auth",
  },
  {
    path: "/error-page",
    hidden: true,
    name: "error",
    component: adminErrorPage,
    layout: "/admin",
  },
  {
    path: "/login-page",
    hidden: true,
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/verification-page",
    hidden: true,
    name: "Verification Page",
    component: VerificationPage,
    layout: "/auth",
  },
  {
    path: "/register-page",
    hidden: true,
    name: "Register Page",
    component: RegisterPage,
    layout: "/auth",
  },
  {
    path: "/reset-password-page",
    hidden: true,
    name: "Reset Password Page",
    component: ResetPasswordPage,
    layout: "/auth",
  },
  {
    path: "/update-password-page",
    hidden: true,
    name: "Update Password Page",
    component: UpdatePasswordPage,
    layout: "/auth",
  },
  {
    path: "/pricing-page",
    hidden: true,
    name: "Pricing Page",
    component: PricingPage,
    layout: "/auth",
  },
  {
    path: "/user-page",
    hidden: true,
    name: "User Profile",
    mini: "UP",
    component: UserProfile,
    layout: "/admin",
    redirect: "/auth/login-page",
  },

  {
    path: "/qu-api-vault",
    hidden: true,
    name: "QuApiVault",
    mini: "AV",
    component: QuApiVault,
    layout: "/admin",
    redirect: "/auth/login-page",
  },
  {
    path: "/users",
    hidden: false,
    name: "Users",
    mini: "U",
    component: UserTablePage,
    layout: "/admin",
    redirect: "/auth/login-page",
    userTypes: ["admin"],
  },
  {
    path: "/landing-page",
    name: "QuCreate",
    hidden: true,
    component: LandingPage,
    layout: "/admin",
  },
  {
    path: "/projects/experiments/:projectID",
    name: "Experiments",
    hidden: true,
    component: Experiments,
    layout: "/admin",
  },
  {
    path: "/writings/:writingID",
    name: "Writings 2",
    hidden: true,
    component: WritingPublisher,
    layout: "/admin",
  },
  {
    path: "/writings",
    name: "Writings",
    hidden: true,
    component: WritingsBoard,
    layout: "/admin",
  },
  {
    path: "/coursedesign/courses/:courseID/module/:moduleID",
    name: "Module Design",
    hidden: true,
    component: ModulesBoard,
    layout: "/admin",
  },
  {
    path: "/coursedesign/courses/:courseID",
    name: "Course Design",
    hidden: true,
    component: ModulesDesign,
    layout: "/admin",
  },
  {
    path: "/coursedesign",
    name: "Course Design Dashboard",
    hidden: true,
    component: CourseDesign,
    layout: "/admin",
  },
  {
    path: "/podcastdesign/podcast/:podcastID",
    name: "Podcast Design",
    hidden: true,
    component: PodcastBoard,
    layout: "/admin",
  },
  {
    path: "/podcastdesign",
    name: "Podcast Design Dashboard",
    hidden: true,
    component: PodcastDesign,
    layout: "/admin",
  },
  {
    path: "/labdesign/lab/:labID",
    name: "Labs Design Dashboard",
    hidden: true,
    component: LabsBoard,
    layout: "/admin",
  },
  {
    path: "/labdesign",
    name: "Lab Design Dashboard",
    hidden: true,
    component: LabsDesign,
    layout: "/admin",
  },
  {
    path: "/lecturedesign/lecture/:lectureID",
    name: "Lecture Design Dashboard1",
    hidden: true,
    component: LectureBoard,
    layout: "/admin",
  },
  {
    path: "/lecturedesign",
    name: "Lecture Design Dashboard",
    hidden: true,
    component: LectureDesign,
    layout: "/admin",
  },

  {
    path: "/projects",
    name: "Projects",
    hidden: true,
    component: Projects,
    layout: "/admin",
  },
  {
    path: "/courseprojects",
    name: "Courses",
    hidden: true,
    component: CourseProjects,
    layout: "/admin",
  },
  {
    collapse: true,
    hidden: true, //Disabled QuToolBox
    name: "QuToolBox",
    icon: AppsIcon,
    layout: "/admin",
    views: [
      {
        path: "/QuToolBox/Data",
        name: "Data",
        icon: StorageIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Explore",
        name: "Explore",
        icon: ExploreIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Processing",
        name: "Data Processing",
        icon: CompareArrowsIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Modeling-Tools",
        name: "Modeling Tools",
        icon: BuildIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Models",
        name: "Models",
        icon: ViewModuleIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Explain",
        name: "Explain",
        icon: AssessmentIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Fairness",
        name: "Fairness and Bias",
        icon: ThumbsUpDownIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Report",
        name: "Report",
        icon: TableChartIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Audit-Checklist",
        name: "Audit Checklist",
        icon: PlaylistAddCheckIcon,
        component: APIDetails,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Case-studies",
        name: "Case studies",
        icon: WorkIcon,
        component: QUApi,
        layout: "/admin",
      },
      // {
      //   path: "/resources",
      //   name: "Resources",
      //   icon: WorkIcon,
      //   component: Resources,
      //   layout: "/admin",
      // },
    ],
  },
];
export default dashRoutes;
