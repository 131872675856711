import {
  primaryColor,
  dangerColor,
  successColor,
  roseColor,
  infoColor,
  warningColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
  quSandBoxColor,
} from "assets/jss/material-dashboard-pro-react.js";

const alertStyle = (theme) => ({
  root: {
    flexGrow: 1,
    margin: "20px 50px 20px 50px",
  },

  alertTitle: {
    textAlign: "center",
  },
  alertContent:{
      textAlign: "center",
  },
  warning: {
    color: "rgb(238, 162, 54)",
    width: "100px",
    height: "100px",
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
  },
  buttonCenter:{
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
  }
});

export default alertStyle;
