import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar,
    Box,
    Tooltip,
} from '@material-ui/core';
import { Delete, Edit, FileCopy } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import NotificationContext from "views/Components/Context.js";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
    title: { marginBottom: '20px' },
    button: {
        marginBottom: '20px',
        padding: '15px 30px',
        fontSize: '1.2rem',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    dialogContent: {
        width: '500px',
        maxWidth: '90vw',
    },
    dialogButtonSpacing: { margin: '10px 0' },
    urlCell: {
        wordBreak: 'break-word',
        maxWidth: '200px',
        textAlign: 'center',
    },
    centeredCell: {
        textAlign: 'center',
    },
    truncatedText: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '200px',  // Adjust as needed for your cell width
    },
    fullText: {
        display: 'block',
        whiteSpace: 'normal',
    },
    tableCell: {
        maxWidth: '200px',  // Add maxWidth to ensure text truncation
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    headerCell: {
        padding: '26px', // You can adjust this for additional spacing if needed
    },
});

export default function ModuleLinks({ links, setLinks, handleDeleteResource, handleUpdateResource }) {
    console.log("In ModuleLinks", links);

    const { showNotification } = React.useContext(NotificationContext);
    const classes = useStyles();

    const [linkUpdateModal, setLinkUpdateModal] = useState(false);
    const [currentLink, setCurrentLink] = useState({ name: "", url: "" });
    const [nameError, setNameError] = useState(false);
    const [currentLinkIndex, setCurrentLinkIndex] = useState(null);

    const handleLinkUpdateModal = (link = { name: "", url: "" }, index) => {
        setCurrentLink(link);
        setCurrentLinkIndex(index);
        setLinkUpdateModal(true);
    };

    const handleCloseLinkUpdateModal = () => {
        setLinkUpdateModal(false);
        setNameError(false);
    };

    const handleUpdateLink = () => {
        if (!currentLink.name.trim()) {
            setNameError(true);
            return;
        }
        if (!isValidUrl(currentLink.url)) {
            showNotification("Please enter a valid URL.", "danger");
            return;
        }
        handleUpdateResource(currentLink, currentLinkIndex);
        setLinkUpdateModal(false);
    };


    const isValidUrl = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z0-9+&@#\\/%?=~_|!:,.;]*)*$', 'i');
        return !!pattern.test(url);
    };

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        showNotification("Link copied to clipboard!", "quSandBoxColor");
    };

    return (
        <div className={classes.container}>
            {links.length === 0 && (
                <Typography variant="body1" >
                    No links added. You can upload links using the + button above.
                </Typography>
            )}
            {links.length > 0 && (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Name</strong></TableCell>
                                <TableCell><strong>Description</strong></TableCell>
                                <TableCell><strong>Link</strong></TableCell>
                                <TableCell className={classes.headerCell}><strong>Actions</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {links.map((link, index) => (
                                <TableRow key={index}> {/* Using link.id instead of index */}
                                    <TableCell className={classes.tableCell}>
                                        <Tooltip title={link.name}>
                                            <span>{link.name}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Tooltip title={link.description}>
                                            <span>{link.description}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                                            <Tooltip title={link.url}>
                                                <span>{link.url}</span>
                                            </Tooltip>
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleCopy(link.url)} color="info">
                                            <FileCopy />
                                        </IconButton>
                                        <IconButton onClick={() => handleLinkUpdateModal(link, index)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteResource(link, index)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
            }

            <Dialog open={linkUpdateModal} onClose={handleCloseLinkUpdateModal} maxWidth="md">
                <DialogTitle>{'Edit Link'}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            type="text"
                            fullWidth
                            value={currentLink.name}
                            onChange={(e) => {
                                setCurrentLink({ ...currentLink, name: e.target.value });
                                setNameError(!e.target.value.trim());
                            }}
                            error={nameError}
                            helperText={nameError ? "Name is required" : ""}
                        />
                        <TextField
                            margin="dense"
                            label="URL"
                            type="text"
                            fullWidth
                            value={currentLink.url}
                            onChange={(e) => setCurrentLink({ ...currentLink, url: e.target.value })}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLinkUpdateModal} color="primary">Cancel</Button>
                    <Button onClick={handleUpdateLink} color="primary">
                        {'Update'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
