import React, { useEffect, useState } from "react";
import ReactEmbedGist from "react-embed-gist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import AdminLinks from "./AdminLinks.js";
import Accordion from "components/Accordion/Accordion.js";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
// Amplify components
import { Auth } from "aws-amplify";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { withRouter } from "react-router";
import SearchIcon from "@material-ui/icons/Search";
import BuildIcon from "@material-ui/icons/Build";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import TableChartIcon from "@material-ui/icons/TableChart";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import StorageIcon from "@material-ui/icons/Storage";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ChatIcon from "@material-ui/icons/Chat";
import GavelIcon from '@material-ui/icons/Gavel';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SweetAlert from "react-bootstrap-sweetalert";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import ExploreIcon from "@material-ui/icons/Explore";
import {
  Card,
  CardActionArea,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  AddAlert,
  Close,
  Dvr,
  Gavel,
  HelpOutline,
  HelpOutlineRounded,
  Info,
  LocationOn,
} from "@material-ui/icons";
import NavPills from "components/NavPills/NavPills.js";
import CardHeader from "components/Card/CardHeader.js";
import NotificationContext from "views/Components/Context.js";
import Tiles from "components/Tiles/Tiles.js";
import { Survey } from "survey-react";
import CustomInput from "components/CustomInput/CustomInput.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardIcon from "components/Card/CardIcon.js";
import CardText from "components/Card/CardText.js";
import Notes from "views/Components/Notes.js";
import Audit from "views/Components/Audit.js";
import ReportTemplate from "views/Components/ReportTemplate.js";
import CardFooter from "components/Card/CardFooter.js";
// import Notes from "views/components/Notes.js";

const useStyles = makeStyles(styles);

function ExperimentDetails(props) {
  const [scoreHTML, setScoreHTML] = React.useState("")
  const images = require.context("assets/img", true);
  const { projectID, exp_id } = props.match.params;
  const history = useHistory();

  const PERMISSION = ["read", "clone", "write", "audit", "own"];
  const [modelSelect, setModelSelect] = React.useState("");
  // const [monitoringSelect, setMonitoringSelect] = React.useState("");
  const [dataOverviewSelect, setDataOverviewSelect] = React.useState("");
  const [dataDiveSelect, setDataDiveSelect] = React.useState("");
  const [dataProfilingSelect, setDataProfilingSelect] = React.useState("");
  const classes = useStyles();

  // const [findings, setFindings] = React.useState([]);

  const [model, setModel] = React.useState({});
  const [data, setData] = React.useState({});
  const [monitoring, setMonitoring] = React.useState({});
  // const [summary, setSummary] = React.useState({});
  const [userType, setUserType] = React.useState("");
  const [user, setUser] = React.useState({});
  const [expData, setExpData] = React.useState({});
  const [modelHTML, setModelHTML] = React.useState("");

  const [dataOverviewHTML, setDataOverviewHTML] = React.useState("");
  const [dataDiveHTML, setDataDiveHTML] = React.useState("");
  const [dataProfilingHTML, setDataProfilingHTML] = React.useState("");
  const [project, setProject] = React.useState({});
  const [dataExplore, setDataExplore] = React.useState({});
  // const [findings, setFindings] = React.useState([]);
  // const [experiment, setExperiment] = React.useState({});
  const [codeModal, setCodeModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [modelHeight, setModelHeight] = React.useState("0");

  const [audit, setAudit] = React.useState({});
  const [dataDiveHeight, setDataDiveHeight] = React.useState("800");
  const [dataProfilingHeight, setDataProfilingHeight] = React.useState("0");
  const [dataOverviewHeight, setDataOverviewHeight] = React.useState("1100");
  const [explainabilityHeight, setExplainabilityHeight] = React.useState("0");
  const [scoreIframeHeight, setScoreIframeHeight] = React.useState("800");

  const [experimentName, setExperimentName] = React.useState();
  const [experimentVersion, setExperimentVersion] = React.useState();
  const [experimentBrief, setExperimentBrief] = React.useState();
  const [experimentJson, setExperimentJson] = React.useState();
  const [expJSON, setExpJSON] = React.useState();
  const [experimentDescription, setExperimentDescription] = React.useState();
  const [experimentGistLink, setExperimentGistLink] = React.useState();
  const [tileActive, setTileActive] = React.useState(0);
  const [stageActive, setStageActive] = React.useState(0);
  // const [notification, setNotification] = React.useState(false);
  // const [operationStatus, setOperationStatus] = React.useState("success");
  // const [notificationMessage, setNotificationMessage] = React.useState("");
  const search = useLocation().search;
  const stage = new URLSearchParams(search).get("stage");
  const reportid = new URLSearchParams(search).get("report");
  const [ignoreKeys, setIgnoreKeys] = React.useState([
    "encoded",
    "entities",
    "stages",
    "id",
    "_id",
    "version",
    "status",
    "output_number",
    "input_number",
    "access_role",
  ]);
  // note states
  const [allNotes, setAllNotes] = React.useState({});
  const { showNotification } = React.useContext(NotificationContext);

  // const [summaryNotes, setSummaryNotes] = React.useState([]);
  // const [modelNotes, setModelNotes] = React.useState([]);
  // const [dataNotes, setDataNotes] = React.useState([]);
  // const [explainabilityNotes, setExplainabilityNotes] = React.useState([]);
  // const [auditNotes, setAuditNotes] = React.useState([]);
  // const [dataExploreNotes, setDataExploreNotes] = React.useState([]);
  // const [fairnessNotes, setFairnessNotes] = React.useState([]);
  // const [environmentNotes, setEnvironmentNotes] = React.useState([]);
  // const [pipelineNotes, setPipelineNotes] = React.useState([]);
  // const [findingsNotes, setFindingsNotes] = React.useState([]);
  // const [monitoringNotes, setMonitoringNotes] = React.useState([]);
  // const [recommendationsNotes, setRecommendationsNotes] = React.useState([]);

  const [reportTemplates, setReportTemplates] = React.useState({});
  const [surveyJson, setSurveyJson] = React.useState({
    ...require('./survey.json'),
    "focusFirstQuestionAutomatic": false,
    "focusOnFirstError": false,
    showQuestionNumbers: "off"
  });
  const [surveyResult, setSurveyResult] = React.useState({});

  const onValueChanged = (res) => {
    setSurveyResult(res.data);
  };

  const onComplete = () => {
    showNotification("Updating score report", "info");
    let url =
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${exp_id}/score/`;
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(surveyResult),
    }).then(
      function (res) {
        res.json().then(function (data) {
          history.push("?stage=11");
          history.go();
          // fetch(
          //   data['s3URL'],
          //   {
          //     method: "GET",
          //     mode: "cors",
          //     credentials: "omit",
          //     cache: "no-cache",
          //   }
          // ).then((res) => {
          //   res.text().then((data) => {
          //     setScoreHTML(data);
          //   });
          // });
          showNotification("Score Report updated successfully", "quSandBoxColor");
        });
      },
      function (err) {
        console.log(props.stageId);
        showNotification("Report updated failed.", "danger");
        console.log(err);
      }
    );
  }
  useEffect(() => {
    if (Object.keys(user).length > 0) {
      console.log('retrieving score report')
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${exp_id}/score`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            if (data.hasOwnProperty('Content')) {
              setSurveyResult(JSON.parse(data['Content']))
            }
            console.log(data)
            if (data.hasOwnProperty('S3URL')) {
              fetch(
                data['S3URL'],
                {
                  method: "GET",
                  mode: "cors",
                  credentials: "omit",
                  cache: "no-cache",
                }
              ).then((res) => {
                res.text().then((data) => {
                  setScoreHTML(data);
                });
              });
            }
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }, [user]);

  const [expLoading, setExpLoading] = React.useState(true);
  const [projectLoading, setProjectLoading] = React.useState(true);
  const { path, url } = useRouteMatch();

  const handleModelSimple = (event) => {
    getHTML(event.target.value, setModelHTML, model);
    setModelSelect(event.target.value);
  };
  const handleDataOverviewSimple = (event) => {
    getHTML(event.target.value, setDataOverviewHTML, data);
    setDataOverviewSelect(event.target.value);
  };

  // const handleMonitoringSelect = (event) =>{
  //   getHTML(event.target.value, setMonitoringHTML, monitoring);
  //   setMonitoringSelect(event.target.value)
  // }

  const handleDataProfilingSimple = (event) => {
    getHTML(event.target.value, setDataProfilingHTML, data);
    setDataProfilingSelect(event.target.value);
  };
  const handleDataDiveSimple = (event) => {
    getHTML(event.target.value, setDataDiveHTML, data);
    setDataDiveSelect(event.target.value);
  };
  // const showNotification = (message, status) => {
  //   setOperationStatus(status);
  //   setNotificationMessage(message);
  //   setNotification(true);
  //   setTimeout(function () {
  //     setNotification(false);
  //   }, 6000);
  // };

  const boardData = ({
    boardName,
    boardComponent,
    boardIcon,
    notesComponent,
    reportsComponent,
  }) => {
    // console.log(data)
    return (
      <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={[
          {
            tabButton: boardName,
            tabIcon: boardIcon,
            tabContent: boardComponent,
          },
          {
            tabButton: "Notes",
            tabIcon: CreateIcon,
            tabContent: notesComponent,
          },
          {
            tabButton: "Reports",
            tabIcon: AssessmentIcon,
            tabContent: reportsComponent,
          },
        ]}
      />
    );
  };

  // Fetching notes
  useEffect(() => {
    setProjectLoading(true);
    if (Object.keys(user).length > 0) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${exp_id}/note`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            // console.log(data);
            // setAllNotes(data.Items);
            var tmp = {}
            data.Items.map((note) => {
              if (tmp.hasOwnProperty(note.StageType)) {
                tmp[note.StageType].push(note)
              } else {
                tmp[note.StageType] = [note]
              }
              // if (note.StageType === "Summary") {
              //   setSummaryNotes((prevState) => [...prevState, note]);
              // } else if (note.StageType === "Audit") {
              //   setAuditNotes([...auditNotes, note]);
              // } else if (note.StageType === "DataExplore") {
              //   setDataExploreNotes([...dataExploreNotes, note]);
              // } else if (note.StageType === "Pipeline") {
              //   setPipelineNotes([...pipelineNotes, note]);
              // } else if (note.StageType === "Data") {
              //   setDataNotes([...dataNotes, note]);
              // } else if (note.StageType === "Model") {
              //   setModelNotes([...modelNotes, note]);
              // } else if (note.StageType === "Environment") {
              //   setEnvironmentNotes([...environmentNotes, note]);
              // } else if (note.StageType === "Explainability") {
              //   setExplainabilityNotes([...explainabilityNotes, note]);
              // } else if (note.StageType === "Fairness") {
              //   setFairnessNotes([...fairnessNotes, note]);
              // } else if (note.StageType === "Monitoring") {
              //   setMonitoringNotes([...monitoringNotes, note]);
              // } else if (note.StageType === "Findings") {
              //   setFindingsNotes([...findingsNotes, note]);
              // } else if (note.StageType === "Recomendations") {
              //   setRecommendationsNotes([...recommendationsNotes, note]);
              // }
            });
            setAllNotes(tmp)
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }, [user]);

  const setNotesHelper = (stageType, data) => {
    setAllNotes(prevState => ({
      ...prevState,
      [stageType]: data
    }))
  }

  useEffect(() => {
    if (!!stage && !!reportid) {
      if (Object.keys(data).length !== 0 && stage === "4") {
        getHTML(reportid, setDataOverviewHTML, data);
        setDataOverviewSelect(reportid);
      }
      if (Object.keys(model).length !== 0 && stage === "5") {
        getHTML(reportid, setModelHTML, model);
        setModelSelect(reportid);
      }
    }
  }, [model, data]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setUserType(
          user.signInUserSession.accessToken.payload["cognito:groups"][0]
        );
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login-page";
        }
      });
  }, []);

  const resizeScoreIframe = (e) => {
    const frame = e.target;
    setScoreIframeHeight(frame.contentWindow.document.documentElement.scrollHeight);
  };

  const resizeModelIframe = (e) => {
    const frame = e.target;
    setModelHeight(frame.contentWindow.document.documentElement.scrollHeight);
  };
  const resizeDataProfilingIframe = (e) => {
    const frame = e.target;
    setDataProfilingHeight(
      frame.contentWindow.document.documentElement.scrollHeight
    );
  };

  const resizeDataOverviewIframe = (e) => {
    const frame = e.target;
    setDataOverviewHeight(
      frame.contentWindow.document.documentElement.scrollHeight
    );
  };
  const resizeDataDiveIframe = (e) => {
    const frame = e.target;
    setDataDiveHeight(
      frame.contentWindow.document.documentElement.scrollHeight
    );
  };
  useEffect(() => {
    setProjectLoading(true);
    if (props.location.state) {
      const { project } = props.location.state;
      setProject(project);
      setProjectLoading(false);
    } else {
      if (Object.keys(user).length > 0) {
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(
          process.env.REACT_APP_BACK_END_URL +
          `/project/${projectID}?type=project`,
          {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        ).then(
          function (res) {
            res.json().then(function (data) {
              setProject(data.Items[0]);
              setProjectLoading(false);
            });
          },
          function (err) {
            console.log(err);
          }
        );
      }
    }
  }, [user]);

  const drawArrow = () => {
    return (
      <div
        style={{
          margin: "auto",
          position: "absolute",
          left: "48%",
        }}
      >
        <svg width="70" height="200">
          <defs>
            <marker
              id="markerArrow1"
              markerWidth="13"
              markerHeight="13"
              refX="2"
              refY="6"
              orient="auto"
            >
              <path d="M2,2 L2,11 L10,6 L2,2" />
            </marker>
          </defs>
          <path
            d="M10,0 L10,22"
            style={{
              stroke: "#000000",
              markerEnd: "url(#markerArrow1)",
            }}
          />
        </svg>
      </div>
    );
  };

  const printJson = (json) => {
    // console.log(json)
    return Object.keys(json).map(
      (key, index) =>
        // console.log(key)
        // {
        !ignoreKeys.includes(key) &&
        (json[key] instanceof Object ? (
          <div key={index}>
            <div>{printJson(json[key])}</div>
          </div>
        ) : (
          !!json[key] && (
            <div key={index} style={{ overflowWrap: "anywhere" }}>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {key}:
              </span>{" "}
              <span>{json[key].toString()}</span>
            </div>
          )
        ))
      // }
    );
  };

  const getHTML = (index, setHTML, obj, bucket = "qu-artifact-sample") => {
    fetch(
      `https://${bucket}.s3-us-west-2.amazonaws.com/${exp_id}/${obj.PK.split("#")[1]
      }/${obj.Reports[index]._id}.html`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        cache: "no-cache",
      }
    ).then((res) => {
      res.text().then((data) => {
        setHTML(data);
      });
    });
  };

  useEffect(() => {
    setExpLoading(true);
    if (Object.keys(user).length > 0) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/experiment/" + exp_id, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
          res.json().then((data) => {
            data.Items.forEach((item) => {
              if (item.PK.startsWith("PROJECT#")) {
                setExpData(item);
                if (item.hasOwnProperty("experimentTemplate")) {
                  console.log(JSON.parse(item.experimentTemplate))
                  setReportTemplates(JSON.parse(item.experimentTemplate));
                }
                setExperimentBrief(item.experimentBrief);
                setExperimentName(item.experimentName);
                setExperimentVersion(item.experimentVersion);
                setExperimentJson(JSON.parse(item.json));
                setExpJSON(item.json);
                setExperimentDescription(item.experimentDescription);
                setExperimentGistLink(item.experimentGistLink);
              } else {
                if (item.StageType === "Model") {
                  setModel(item);
                } else if (item.StageType === "Data") {
                  setData(item);
                } else if (item.StageType === "Explore") {
                  setDataExplore(item);
                } else if (item.StageType === "Monitoring") {
                  setMonitoring(item);
                } else if (item.StageType === "Audit") {
                  setAudit(item);
                }
              }
            });
            setExpLoading(false);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const handleCodeModal = () => {
    setCodeModal(true);
  };
  const closeCodeModal = () => {
    setCodeModal(false);
  };
  const editExperiment = () => {
    setEditModal(true);
  };
  const closeEditModal = () => {
    setEditModal(false);
  };

  const getTabs = () => {
    return [
      {
        tabButton: "Project",
        tabContent: (
          <span>
            <p className={classes.summaryTab}>
              <b>Project Name: </b>
              {project.projectName}
            </p>

            <p className={classes.summaryTab}>
              <b>Project Description: </b>
              {project.projectDescription}
            </p>
            <p className={classes.summaryTab}>
              <b>Project Brief Description: </b>
              {project.projectBrief}
            </p>
            <p className={classes.summaryTab}>
              <b>Project ID: </b>
              {projectID}
            </p>
            <p className={classes.summaryTab}>
              <b>Project Version: </b>
              {project.projectVersion}
            </p>
          </span>
        ),
      },
      {
        tabButton: "Experiment",
        tabContent: (
          <span>
            <p className={classes.summaryTab}>
              <b>Experiment Name: </b>
              {expData.experimentName}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment Description: </b>
              {expData.experimentDescription}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment Brief Description: </b>
              {expData.experimentBrief}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment ID: </b>
              {exp_id}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment Version: </b>
              {expData.experimentVersion}
            </p>
          </span>
        ),
      },
    ];
  };

  const handleEditExperiment = () => {
    closeEditModal();
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${exp_id}`, {
      method: "PUT",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        experimentDescription: experimentDescription,
        experimentGistLink: experimentGistLink,
        json: expJSON,
        experimentName: experimentName,
        experimentBrief: experimentBrief,
        experimentVersion: experimentVersion,
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(experimentJson)
        // console.log(JSON.stringify(experimentJson))
        let newExperiment = {
          experimentDescription: experimentDescription,
          experimentGistLink: experimentGistLink,
          json: expJSON,
          experimentName: experimentName,
          experimentBrief: experimentBrief,
          experimentVersion: experimentVersion,
        };
        setExpData({ ...expData, ...newExperiment });
        showNotification("Experiment updated successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        console.log(error);
        showNotification("Update failed.", "danger");
      });
  };

  return (
    <div>
      {/* <Snackbar
        place="br"
        color={operationStatus}
        icon={AddAlert}
        message={notificationMessage}
        open={notification}
        closeNotification={() => setNotification(false)}
        close
      /> */}

      <Dialog
        open={editModal}
        onClose={closeEditModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ fontSize: "larger" }}>Edit Experiment</div>{" "}
          <div>Project: {project.projectName}</div>
          <div>Experiment: {expData.experimentName}</div>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            onChange={(event) => {
              setExperimentName(event.target.value);
            }}
            value={experimentName}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="version"
            label="Version"
            type="text"
            onChange={(event) => {
              setExperimentVersion(event.target.value);
            }}
            value={experimentVersion}
            fullWidth
          />
          <TextField
            margin="dense"
            id="brief"
            label="Brief Description"
            type="text"
            onChange={(event) => {
              setExperimentBrief(event.target.value);
            }}
            value={experimentBrief}
            fullWidth
            multiline={true}
            rows={3}
          />
          <TextField
            margin="dense"
            id="description"
            label="Detailed desciption for this experiment"
            type="text"
            onChange={(event) => {
              setExperimentDescription(event.target.value);
            }}
            value={experimentDescription}
            fullWidth
            multiline={true}
            rows={3}
          />
          <TextField
            margin="dense"
            id="gistLink"
            label="Link to git gist for this experiment"
            type="text"
            onChange={(event) => {
              setExperimentGistLink(event.target.value);
            }}
            fullWidth
            value={experimentGistLink}
          />
          <CustomInput
            labelText="Custom key json"
            id="custom_key_json"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              rows: 5,
              onChange: (event) => {
                setExperimentJson(JSON.parse(event.target.value));
                setExpJSON(event.target.value);
              },
              value: expJSON,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditModal} color="quSandBoxColor">
            Cancel
          </Button>
          <Button onClick={handleEditExperiment} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={codeModal}
        onClose={closeCodeModal}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">Github Gist</DialogTitle>
        <DialogContent>
          {expData.experimentGistLink && (
            <GridItem xs={12} sm={12} md={12}>
              <ReactEmbedGist
                contentClass={classes.gistclass}
                gist={expData.experimentGistLink}
              />
            </GridItem>
          )}
        </DialogContent>
      </Dialog>
      {expLoading || projectLoading ? (
        <Heading title={<CircularProgress />} textAlign="center" />
      ) : (
        <Tiles
          active={tileActive}
          color="warning"
          alignCenter
          tabs={[
            {
              heading: "Summary",
              icon: ChatIcon,
              description: "Summary board",
              summary: <div></div>,
              tabContent: (
                <div>
                  {boardData({
                    boardName: "Summary",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <ChatIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Summary</h4>
                        </CardHeader>

                        <CardBody>
                          <p className={classes.summaryTab}>
                            <b>Project Name: </b>
                            {project.projectName}
                          </p>

                          <p className={classes.summaryTab}>
                            <b>Project Description: </b>
                            {project.projectDescription}
                          </p>
                          <p className={classes.summaryTab}>
                            <b>Project Brief Description: </b>
                            {project.projectBrief}
                          </p>
                          <p className={classes.summaryTab}>
                            <b>Project ID: </b>
                            {projectID}
                          </p>
                          <p className={classes.summaryTab}>
                            <b>Project Version: </b>
                            {project.projectVersion}
                          </p>
                        </CardBody>
                        <CardBody>
                          <p className={classes.summaryTab}>
                            <b>Experiment Name: </b>
                            {expData.experimentName}
                          </p>
                          <p className={classes.summaryTab}>
                            <b>Experiment Description: </b>
                            {expData.experimentDescription}
                          </p>
                          <p className={classes.summaryTab}>
                            <b>Experiment Brief Description: </b>
                            {expData.experimentBrief}
                          </p>
                          <p className={classes.summaryTab}>
                            <b>Experiment ID: </b>
                            {exp_id}
                          </p>
                          <p className={classes.summaryTab}>
                            <b>Experiment Version: </b>
                            {expData.experimentVersion}
                          </p>
                        </CardBody>
                        {/* <CardFooter> */}
                        <GridItem xs={12} style={{ textAlign: "center" }}>
                          {(PERMISSION.indexOf(project.projectPermission) >=
                            PERMISSION.indexOf("audit") ||
                            (PERMISSION.indexOf(project.projectPermission) ===
                              PERMISSION.indexOf("write") &&
                              expData.creator === user.username)) && (
                              <Button
                                color="info"
                                size="lg"
                                // disabled={!expData.experimentGistLink}
                                onClick={editExperiment}
                                className={classes.buttonsTopSpace}
                              >
                                Edit Experiment
                              </Button>
                            )}
                          <Button
                            color="info"
                            size="lg"
                            disabled={!expData.experimentGistLink}
                            onClick={handleCodeModal}
                            className={classes.buttonsTopSpace}
                          >
                            View Code
                          </Button>
                          <Button
                            color="info"
                            size="lg"
                            onClick={() =>
                              window.open(
                                "https://colab.research.google.com/drive/106_xLtSULuAkInhwMxR7UdpuwaPyMnCu?usp=sharing"
                              )
                            }
                            className={classes.buttonsTopSpace}
                          >
                            Run Code
                          </Button>
                          <Button
                            onClick={() =>
                              window.open(
                                "https://modelrisk3.qusandbox.com/#!/pipelines"
                              )
                            }
                            target="_blank"
                            color="info"
                            size="lg"
                            className={classes.buttonsTopSpace}
                          >
                            Run on Model Studio
                          </Button>
                        </GridItem>
                        {/* </CardFooter> */}
                      </Card>
                    ),
                    boardIcon: ChatIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Summary")
                          ? allNotes.Summary
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Summary"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("summary")
                            ? reportTemplates.summary
                            : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        stageType="Summary"
                        notes={allNotes.hasOwnProperty("Summary")
                          ? allNotes.Summary
                          : []}
                      />
                    ),
                  })}
                </div>
              ),
            },
            {
              heading: "Audit Checklist",
              icon: PlaylistAddCheckIcon,
              description: "Audit Checklist board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[...getTabs()]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Audit Checklist",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <PlaylistAddCheckIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            Audit Checklist
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer justify="center">
                            <Button
                              color="info"
                              size="lg"
                              href="https://test2-qs.qusandbox.com/admin/projects/experiments/0d371a9d315447d3af8e9c8adaac23e6/59b00d287b69428b8e6144df25c51d6d"
                              target="_blank"
                            >
                              Audit Checklist
                            </Button>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: PlaylistAddCheckIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Audit")
                          ? allNotes.Audit
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Audit"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        // stageId={Audit.PK}
                        templateId={
                          reportTemplates.hasOwnProperty("audit")
                            ? reportTemplates.audit
                            : ""
                        }
                        stageId={audit.hasOwnProperty("PK") ? audit.PK : ""}
                        // data={findings}
                        // setJson={setFindingsJson}
                        stageType="Audit"
                        notes={allNotes.hasOwnProperty("Audit")
                          ? allNotes.Audit
                          : []}
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Data Explore",
              icon: ExploreIcon,
              description: "Data Exploration board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Data Explore",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Data Explore",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <ExploreIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            Data Explore
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <iframe
                              id="data"
                              src={
                                !!dataExplore &&
                                  dataExplore.hasOwnProperty("Link")
                                  ? dataExplore.Link
                                  : "https://superset.quantuniversity.com/dashboard/list/?pageIndex=0&sortColumn=changed_on_delta_humanized&sortOrder=desc&viewMode=table"
                              }
                              width="100%"
                              frameBorder="0"
                              height={`${dataExplore &&
                                dataExplore.hasOwnProperty("IframeHeight")
                                ? dataExplore.IframeHeight
                                : 600
                                }px`}
                            ></iframe>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: ExploreIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("DataExplore")
                          ? allNotes.DataExplore
                          : []}
                        setNotes={setNotesHelper}
                        stageType="DataExplore"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        // data={findings}
                        // stageId={dataExplore.PK}
                        templateId={
                          reportTemplates.hasOwnProperty("dataExplore")
                            ? reportTemplates.dataExplore
                            : ""
                        }
                        stageId={
                          dataExplore.hasOwnProperty("PK") ? dataExplore.PK : ""
                        }
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("DataExplore")
                          ? allNotes.DataExplore
                          : []}
                        stageType="DataExplore"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Pipeline",
              icon: CompareArrowsIcon,
              description: "Pipeline board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            {
                              tabButton: "Pipeline",
                              tabContent: <span></span>,
                            },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Pipeline",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <CompareArrowsIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Pipeline</h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            {/* {experimentJson} */}
                            <GridItem md={5}>
                              <GridContainer justify="center">
                                <GridItem
                                  style={{
                                    margin: "15px",
                                    position: "relative",
                                  }}
                                  xs={12}
                                >
                                  <Card
                                    className={classes.summaryTab}
                                    style={{
                                      border: "solid 1px black",
                                      textAlign: "center",
                                      paddingBottom: "10px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <CardHeader>
                                      <b>Start</b>
                                    </CardHeader>
                                  </Card>
                                  {drawArrow()}
                                </GridItem>
                                {experimentJson.hasOwnProperty("stages") &&
                                  experimentJson.stages.map((stage, index) => (
                                    <GridItem
                                      style={{
                                        margin: "15px",
                                        position: "relative",
                                      }}
                                      xs={12}
                                      key={index}
                                    >
                                      <Card
                                        className={classes.summaryTab}
                                        style={{
                                          border: "solid 1px black",
                                          textAlign: "center",
                                        }}
                                      >
                                        <CardActionArea
                                          style={{
                                            paddingBottom: "20px",
                                            paddingTop: "10px",
                                          }}
                                          onClick={() => {
                                            setStageActive(index);
                                          }}
                                        >
                                          <CardHeader
                                            style={{ paddingBottom: "0px" }}
                                          >
                                            <b>{stage[0]["name"]}</b>
                                          </CardHeader>
                                          {stage[0]["entities"].map(
                                            (entity, i) => (
                                              <div key={i}>
                                                <hr />
                                                <div
                                                  className={classes.summaryTab}
                                                >
                                                  {entity["name"]}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </CardActionArea>
                                      </Card>
                                      {drawArrow()}
                                    </GridItem>
                                  ))}
                                <GridItem
                                  style={{
                                    margin: "15px",
                                    position: "relative",
                                  }}
                                  xs={12}
                                >
                                  <Card
                                    className={classes.summaryTab}
                                    style={{
                                      border: "solid 1px black",
                                      textAlign: "center",
                                      paddingBottom: "10px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <CardHeader>
                                      <b>Stop</b>
                                    </CardHeader>
                                  </Card>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem md={7} xs={12}>
                              <div
                                style={{ margin: "15px", position: "relative" }}
                              >
                                {printJson(experimentJson)}
                                <p
                                  className={classes.summaryTab}
                                  style={{ paddingTop: "25px" }}
                                >
                                  <b>
                                    Environment:{" "}
                                    {experimentJson.hasOwnProperty("stages") &&
                                      experimentJson.stages[stageActive][0]
                                        .name}
                                  </b>
                                </p>
                                {printJson(experimentJson.stages[stageActive])}
                                <Accordion
                                  active={-1}
                                  collapses={
                                    experimentJson.hasOwnProperty("stages") &&
                                    experimentJson.stages[stageActive][0][
                                      "entities"
                                    ].map((entity, index) => {
                                      return {
                                        title: (
                                          <div className={classes.summaryTab}>
                                            <b>{entity["name"]}</b>
                                          </div>
                                        ),
                                        content: <div>{printJson(entity)}</div>,
                                      };
                                    })
                                  }
                                />
                                <p className={classes.summaryTab}>
                                  <b>Model: </b>
                                  <a
                                    color="info"
                                    size="lg"
                                    // onClick={() => {
                                    //   setTileActive(5);
                                    // }}
                                    href={`?stage=5`}
                                    style={{ textDecoration: "underline" }}
                                  >
                                    View model board
                                  </a>
                                  {/* <Bu>Click here to view the model card</div> */}
                                </p>
                                <p className={classes.summaryTab}>
                                  <b>Data: </b>
                                  <a
                                    color="info"
                                    size="lg"
                                    // onClick={() => {
                                    //   setTileActive(4);
                                    // }}
                                    href={`?stage=4`}
                                    style={{ textDecoration: "underline" }}
                                  >
                                    View data board
                                  </a>
                                </p>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: CompareArrowsIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Pipeline")
                          ? allNotes.Pipeline
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Pipeline"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("pipeline")
                            ? reportTemplates.pipeline
                            : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Pipeline")
                          ? allNotes.Pipeline
                          : []}
                        stageType="Pipeline"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Data",
              icon: StorageIcon,
              description: "Data board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Data",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Data",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <StorageIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Data</h4>
                        </CardHeader>
                        <CardBody>
                          {/* here */}
                          <NavPills
                            color="quSandBoxColor"
                            tabs={[
                              {
                                tabButton: "Facets Overview",
                                tabContent: (
                                  <span>
                                    <GridItem xs={12} sm={6} md={5} lg={5}>
                                      <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          htmlFor="simple-select"
                                          className={classes.selectLabel}
                                        >
                                          Choose Report
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={dataOverviewSelect}
                                          onChange={handleDataOverviewSimple}
                                          inputProps={{
                                            name: "simpleSelect",
                                            id: "simple-select",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose Report
                                          </MenuItem>
                                          {data.hasOwnProperty("Reports") &&
                                            data.Reports.map(
                                              (report, index) =>
                                                report.Type ===
                                                "Facets Overview" && (
                                                  <MenuItem
                                                    key={index}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem,
                                                      selected:
                                                        classes.selectMenuItemSelected,
                                                    }}
                                                    value={index}
                                                  >
                                                    {report.Name}
                                                  </MenuItem>
                                                )
                                            )}
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    <CardBody>
                                      <GridContainer>
                                        <iframe
                                          id="data"
                                          srcDoc={dataOverviewHTML}
                                          width="100%"
                                          frameBorder="0"
                                          height={`${dataOverviewHeight}px`}
                                          scrolling="no"
                                          onLoad={resizeDataOverviewIframe}
                                        ></iframe>
                                      </GridContainer>
                                    </CardBody>
                                  </span>
                                ),
                              },
                              {
                                tabButton: "Facets Dive",
                                tabContent: (
                                  <span>
                                    <GridItem xs={12} sm={6} md={5} lg={5}>
                                      <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          htmlFor="simple-select"
                                          className={classes.selectLabel}
                                        >
                                          Choose Report
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={dataDiveSelect}
                                          onChange={handleDataDiveSimple}
                                          inputProps={{
                                            name: "simpleSelect",
                                            id: "simple-select",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose Report
                                          </MenuItem>
                                          {data.hasOwnProperty("Reports") &&
                                            data.Reports.map(
                                              (report, index) =>
                                                report.Type ===
                                                "Facets Dive" && (
                                                  <MenuItem
                                                    key={index}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem,
                                                      selected:
                                                        classes.selectMenuItemSelected,
                                                    }}
                                                    value={index}
                                                  >
                                                    {report.Name}
                                                  </MenuItem>
                                                )
                                            )}
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    <CardBody>
                                      <GridContainer>
                                        <iframe
                                          id="data"
                                          srcDoc={dataDiveHTML}
                                          width="100%"
                                          frameBorder="0"
                                          height={`${dataDiveHeight}px`}
                                          scrolling="no"
                                          onLoad={resizeDataDiveIframe}
                                        ></iframe>
                                      </GridContainer>
                                    </CardBody>
                                  </span>
                                ),
                              },
                              {
                                tabButton: "Pandas Profiling",
                                tabContent: (
                                  <span>
                                    <GridItem xs={12} sm={6} md={5} lg={5}>
                                      <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          htmlFor="simple-select"
                                          className={classes.selectLabel}
                                        >
                                          Choose Report
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={dataProfilingSelect}
                                          onChange={handleDataProfilingSimple}
                                          inputProps={{
                                            name: "simpleSelect",
                                            id: "simple-select",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose Report
                                          </MenuItem>
                                          {data.hasOwnProperty("Reports") &&
                                            data.Reports.map(
                                              (report, index) =>
                                                report.Type ===
                                                "Pandas Profiling" && (
                                                  <MenuItem
                                                    key={index}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem,
                                                      selected:
                                                        classes.selectMenuItemSelected,
                                                    }}
                                                    value={index}
                                                  >
                                                    {report.Name}
                                                  </MenuItem>
                                                )
                                            )}
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    <CardBody>
                                      <GridContainer>
                                        <iframe
                                          id="data"
                                          srcDoc={dataProfilingHTML}
                                          width="100%"
                                          frameBorder="0"
                                          height={`${dataProfilingHeight}px`}
                                          scrolling="no"
                                          onLoad={resizeDataProfilingIframe}
                                        ></iframe>
                                      </GridContainer>
                                    </CardBody>
                                  </span>
                                ),
                              },
                            ]}
                          />
                        </CardBody>
                        {/* <CardBody>
                  <GridContainer>
                    <iframe
                      id="data"
                      srcDoc={dataHTML}
                      width="100%"
                      frameBorder="0"
                      height={`${dataHeight}px`}
                      scrolling="no"
                      onLoad={resizeDataIframe}
                    ></iframe>
                  </GridContainer>
                </CardBody> */}
                      </Card>
                    ),
                    boardIcon: StorageIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Data")
                          ? allNotes.Data
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Data"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("data")
                            ? reportTemplates.data
                            : ""
                        }
                        stageId={data.hasOwnProperty("PK") ? data.PK : ""}
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Data")
                          ? allNotes.Data
                          : []}
                        stageType="Data"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Model",
              icon: ViewModuleIcon,
              description: "Model board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Model",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Model",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <ViewModuleIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Model</h4>
                        </CardHeader>
                        <CardBody>
                          <GridItem xs={12} sm={6} md={5} lg={5}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Choose Report
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={modelSelect}
                                onChange={handleModelSimple}
                                inputProps={{
                                  name: "simpleSelect",
                                  id: "simple-select",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  Choose Report
                                </MenuItem>
                                {model.hasOwnProperty("Reports") &&
                                  model.Reports.map((report, index) => (
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                          classes.selectMenuItemSelected,
                                      }}
                                      value={index}
                                    >
                                      {report.Name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </CardBody>
                        <CardBody>
                          <GridContainer>
                            {/* <div
                  className={classes.htmlContainer}
                  dangerouslySetInnerHTML={{
                    __html: modelHTML,
                  }}
                ></div> */}
                            <iframe
                              id="model"
                              srcDoc={modelHTML}
                              width="100%"
                              frameBorder="0"
                              height={`${modelHeight}px`}
                              scrolling="no"
                              onLoad={resizeModelIframe}
                            ></iframe>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: ViewModuleIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Model")
                          ? allNotes.Model
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Model"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("model")
                            ? reportTemplates.model
                            : ""
                        }
                        stageId={model.hasOwnProperty("PK") ? model.PK : ""}
                        // template={ReportTemplate}
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Model")
                          ? allNotes.Model
                          : []}
                        stageType="Model"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Environment",
              icon: BuildIcon,
              description: "Environment board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Environment",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {" "}
                  {boardData({
                    boardName: "Environment",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <BuildIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Environment</h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer justify="center">
                            <Button
                              color="info"
                              size="lg"
                              href="https://modelrisk3.qusandbox.com/#!/pipelines"
                              target="_blank"
                            >
                              Run on Model Studio
                            </Button>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: BuildIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Environment")
                          ? allNotes.Environment
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Environment"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("environment")
                            ? reportTemplates.environmnet
                            : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Environment")
                          ? allNotes.Environment
                          : []}
                        stageType="Environment"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Explainability",
              icon: AssessmentIcon,
              description: "Explainability board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Explainability",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Explainability",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <AssessmentIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            Explainability
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <iframe
                            id="explainability"
                            src="https://test.quantuniversity.com/"
                            title="description"
                            width="100%"
                            height="600px"
                          // frameborder="0" height={`${explainabilityHeight}px`} scrolling="no" onLoad={resizeExplainabilityIframe}
                          ></iframe>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: AssessmentIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Explainability")
                          ? allNotes.Explainability
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Explainability"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("explainability")
                            ? reportTemplates.explainability
                            : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Explainability")
                          ? allNotes.Explainability
                          : []}
                        stageType="Explainability"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Fairness",
              icon: ThumbsUpDownIcon,
              description: "Fairness and Bias",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Fairness",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Fairness",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <ThumbsUpDownIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Fairness</h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer justify="center">
                            <Button
                              color="info"
                              size="lg"
                              href="https://test2-qs.qusandbox.com/admin/QuToolBox/Fairness"
                              target="_blank"
                            >
                              Browse Tools
                            </Button>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: ThumbsUpDownIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Fairness")
                          ? allNotes.Fairness
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Fairness"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("fairness")
                            ? reportTemplates.fairness
                            : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Fairness")
                          ? allNotes.Fairness
                          : []}
                        stageType="Fairness"
                      />
                    ),
                  })}

                  {/* <Card style={{ marginBottom: "30px" }}>
                    <CardHeader stats icon>
                      <h3 className={classes.cardTitle}>
                        <small>
                          <img
                            src={images("./qusandbox_logo_v2.5.png")}
                            alt=""
                            className={classes.thumbnail}
                          />
                        </small>
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <NavPills
                        color="quSandBoxColor"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 4 },
                          contentGrid: { xs: 12, sm: 12, md: 8 },
                        }}
                        tabs={[
                          ...getTabs(),
                          // {
                          //   tabButton: "Fairness",
                          //   tabContent: <span></span>,
                          // },
                        ]}
                      />
                    </CardBody>
                  </Card> */}
                </>
              ),
            },
            {
              heading: "Deployment",
              icon: GavelIcon,
              description: "Deployment",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Fairness",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Deployment",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <ThumbsUpDownIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Deployment</h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer justify="center">
                            <Heading title="*Coming Soon*" textAlign="center" />
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: GavelIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Deployment")
                          ? allNotes.Deployment
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Deployment"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("deployment")
                            ? reportTemplates.deployment
                            : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Deployment")
                          ? allNotes.Deployment
                          : []}
                        stageType="Deployment"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Monitoring",
              icon: TableChartIcon,
              description: "Monitoring board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Report",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Monitoring",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <TableChartIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Monitoring</h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <iframe
                              id="monitoring"
                              src={monitoring.Link}
                              width="100%"
                              frameBorder="0"
                              height={`${monitoring.IframeHeight}px`}
                            ></iframe>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: TableChartIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Monitoring")
                          ? allNotes.Monitoring
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Monitoring"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("monitoring")
                            ? reportTemplates.monitoring
                            : ""
                        }
                        stageId={
                          monitoring.hasOwnProperty("PK") ? monitoring.PK : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Monitoring")
                          ? allNotes.Monitoring
                          : []}
                        stageType="Monitoring"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Audit Score",
              icon: DoneAllIcon,
              description: "Audit Score board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Report",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Audit Score",
                    boardComponent: (
                      <>
                        <Card className={classes.card}>
                          <CardHeader icon>
                            <CardIcon color="quSandBox">
                              <DoneAllIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Audit Score</h4>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              <Survey
                                data={surveyResult}
                                json={surveyJson}
                                showCompletedPage={false}
                                onComplete={onComplete}
                                onValueChanged={onValueChanged}
                              />
                            </GridContainer>
                          </CardBody>
                        </Card>
                        <Card className={classes.card}>
                          <CardHeader icon>
                            <CardIcon color="quSandBox">
                              <DoneAllIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Audit Score Report</h4>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              <iframe
                                id="score"
                                srcDoc={scoreHTML}
                                width="100%"
                                frameBorder="0"
                                height={`${scoreIframeHeight}px`}
                                onLoad={resizeScoreIframe}
                              ></iframe>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </>
                    ),
                    boardIcon: DoneAllIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Score")
                          ? allNotes.Score
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Score"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("Score")
                            ? reportTemplates.Score
                            : ""
                        }
                        notes={allNotes.hasOwnProperty("Score")
                          ? allNotes.Score
                          : []}
                        stageType="Score"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Findings",
              icon: SearchIcon,
              description: "Findings board",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Findings",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Findings",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <SearchIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Findings</h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer justify="center">
                            <Heading title="*Coming Soon*" textAlign="center" />
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: SearchIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Findings")
                          ? allNotes.Findings
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Findings"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("findings")
                            ? reportTemplates.findings
                            : ""
                        }
                        notes={allNotes.hasOwnProperty("Findings")
                          ? allNotes.Findings
                          : []}
                        stageType="Findings"
                      />
                    ),
                  })}
                </>
              ),
            },
            {
              heading: "Recommendations",
              icon: ThumbUpIcon,
              description: "Recommendations",
              summary: (
                <Card
                  className={classes.card}
                // style={{ marginBottom: "30px" }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} md={9} lg={10}>
                        <NavPills
                          color="quSandBoxColor"
                          horizontal={{
                            tabsGrid: { xs: 12, sm: 12, md: 4 },
                            contentGrid: { xs: 12, sm: 12, md: 8 },
                          }}
                          tabs={[
                            ...getTabs(),
                            // {
                            //   tabButton: "Recommendations",
                            //   tabContent: <span></span>,
                            // },
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} md={3} lg={2}>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              ),
              tabContent: (
                <>
                  {boardData({
                    boardName: "Recommendations",
                    boardComponent: (
                      <Card className={classes.card}>
                        <CardHeader icon>
                          <CardIcon color="quSandBox">
                            <ThumbUpIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            Recommendations
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <Heading title="*Coming Soon*" textAlign="center" />
                        </CardBody>
                      </Card>
                    ),
                    boardIcon: ThumbUpIcon,
                    notesComponent: (
                      <Notes
                        notes={allNotes.hasOwnProperty("Recommendations")
                          ? allNotes.Recommendations
                          : []}
                        setNotes={setNotesHelper}
                        stageType="Recommendations"
                      />
                    ),
                    reportsComponent: (
                      <ReportTemplate
                        templateId={
                          reportTemplates.hasOwnProperty("recommendations")
                            ? reportTemplates.recommendations
                            : ""
                        }
                        // data={findings}
                        // setJson={setFindingsJson}
                        notes={allNotes.hasOwnProperty("Recommendations")
                          ? allNotes.Recommendations
                          : []}
                        stageType="Recommendations"
                      />
                    ),
                  })}
                </>
              ),
            },
          ]}
        />
      )}
    </div>
  );
}

export default withRouter(ExperimentDetails);
