import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextareaAutosize,
  Grid,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanel,
  Chip,
  Input,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import { saveLabInstructionsAPI } from "views/APIs/APIUtility";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationContext from "views/Components/Context.js";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SaveIcon from "@material-ui/icons/Save";
import { generateBusinessUseCaseAPI } from "views/APIs/APIUtility";
import { fetchLabAPI } from "views/APIs/APIUtility";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function LabRequirementForm({
  projectID,
  projectType,
  files,
  images,
  links,
  setActiveStep,
  setLoadingBusinessUseCase,
}) {
  const classes = useStyles();
  const { showNotification } = React.useContext(NotificationContext);
  if (!files) {
    files = [];
  }
  if (!images) {
    images = [
      {
        name: "Sample Image 1",
        id: 1,
      },
      {
        name: "Sample Image 2",
        id: 2,
      },
      {
        name: "Sample Image 3",
        id: 3,
      },
    ];
  }
  if (!links) {
    links = [
      {
        name: "Sample Link 1",
        id: 1,
      },
      {
        name: "Sample Link 2",
        id: 2,
      },
      {
        name: "Sample Link 3",
        id: 3,
      },
    ];
  }
  const [formData, setFormData] = useState({
    learningOutcomes: "",
    responsive: false,
    datasetType: "",
    links: [],
    datasetFile: [],
    visualizations: "",
    frameworks: "",
    accessibility: "",
    exportFormats: "",
    visualReferences: [],
    documentation: false,
  });
  const [labDetails, setLabDetails] = useState({
    lab_name: "",
    lab_description: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchLabAPI(projectID);
        const data = response;
        if (data.instructions) {
          setFormData(data.instructions);
        }
        if (data.lab_name) {
          setLabDetails({
            lab_name: data.lab_name,
            lab_description: data.lab_description,
          });
        }
      } catch (error) { }
    }
    fetchData();
  }, [projectID]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  function generateInstructions(formData) {
    let instructions =
      "Create an application based on the following requirements:\n";
    if (labDetails.lab_name) {
      instructions += `- Lab Name: ${labDetails.lab_name}.\n`;
    }
    if (labDetails.lab_description) {
      instructions += `- Lab Description: ${labDetails.lab_description}.\n`;
    }
    if (formData.learningOutcomes) {
      instructions += `- The application should depict or achieve the following learning outcomes: ${formData.learningOutcomes}.\n`;
    }

    if (formData.responsive) {
      instructions += "- The application should have a responsive frontend.\n";
    }

    if (formData.datasetType) {
      instructions += `- The dataset type to be used is ${formData.datasetType}.\n`;
    }

    if (formData.links && formData.links.length > 0) {
      instructions += `- The following links or sources should be considered for data: ${formData.links}.\n`;
    }

    if (formData.datasetFile && formData.datasetFile.length > 0) {
      instructions +=
        "- Dataset Files have been uploaded and should be used for the application.\n";
    }

    if (formData.visualizations) {
      instructions += `- The application should include the following visualizations: ${formData.visualizations}.\n`;
    }

    if (formData.frameworks) {
      instructions += `- The application should demonstrate or use the following libraries/frameworks: ${formData.frameworks}.\n`;
    }

    if (formData.accessibility) {
      instructions += `- Accessibility needs include: ${formData.accessibility}.\n`;
    }

    if (formData.exportFormats) {
      instructions += `- The results should be exportable in the following formats: ${formData.exportFormats}.\n`;
    }

    if (formData.documentation) {
      instructions += `- Documentation or tutorials required: ${formData.documentation}.\n`;
    }
    return instructions;
  }

  const handleSaveRequirementForm = async (e) => {
    e.preventDefault();
    const formDataSaveInstructions = new FormData();
    formDataSaveInstructions.append("lab_id", projectID);
    formDataSaveInstructions.append("instructions", JSON.stringify(formData));
    try {
      await saveLabInstructionsAPI(formDataSaveInstructions);
      if (e._reactName === "onSubmit") {
        showNotification("Lab Instructions Saved Successfully", "success");
      }
    } catch (error) {
      showNotification(error, "danger");
    } finally {
    }
  };

  const handleChangeMultiple = (event) => {
    const options = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: options,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleLabBack = () => {
    window.history.back();
  };

  const labSubmitHandler = async () => {
    try {
      setLoadingBusinessUseCase(true);
      await handleSaveRequirementForm(new Event("submit"));
      const formDataSaveInstructions = new FormData();
      formDataSaveInstructions.append("lab_id", projectID);
      const instructions = generateInstructions(formData);
      formDataSaveInstructions.append("instructions", instructions);
      await generateBusinessUseCaseAPI(formDataSaveInstructions);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBusinessUseCase(false);
      setActiveStep(1);
    }
  };
  return (
    <form onSubmit={handleSaveRequirementForm}>
      <Typography
        variant="h5"
        gutterBottom
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        Enter the application requirements below for a comprehensive lab design:
      </Typography>
      {/* <Typography variant="h6" gutterBottom style={{ marginTop: '10px', marginBottom: '10px' }}>
                Application Requirements
            </Typography> */}
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>General Requirements</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Enter learning outcomes for the application"
                  placeholder="Enter learning outcomes for the application"
                  name="learningOutcomes"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={formData.learningOutcomes}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="responsive"
                      checked={formData.responsive}
                      onChange={handleChange}
                      color="quSandBoxColor"
                    />
                  }
                  label="Should the frontend be responsive?"
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Data Requirements</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel>Dataset Type</InputLabel>
                <Select
                  name="datasetType"
                  value={formData.datasetType}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="">Select Dataset Type</MenuItem>
                  <MenuItem value="synthetic">Use Synthetic Dataset</MenuItem>
                  <MenuItem value="realWorld">Use Real World Dataset</MenuItem>
                  <MenuItem value="linked">Attach Links</MenuItem>
                  <MenuItem value="attachedDataset">Attach Dataset</MenuItem>
                </Select>
              </Grid>

              {formData.datasetType === "linked" && (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-mutiple-chip-label">
                    Select the links to be used as the dataset(s).
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    fullWidth
                    name="links"
                    value={formData.links}
                    onChange={handleChangeMultiple}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {links.length === 0 && (
                      <MenuItem disabled value="No Links Available">
                        No Links Available. You can add links by clicking the +
                        button above.
                      </MenuItem>
                    )}
                    {links.map((link) => (
                      <MenuItem
                        key={link.name}
                        value={link.name + ` Link ID: (${link.id})`}
                      >
                        {link.name + ` Link ID: (${link.id})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {formData.datasetType === "attachedDataset" && (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-mutiple-chip-label">
                    Select the files to attach as the dataset(s).
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    fullWidth
                    name="datasetFile"
                    value={formData.datasetFile}
                    onChange={handleChangeMultiple}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {files.length === 0 && (
                      <MenuItem disabled value="No Files Available">
                        No Files Available. You can upload files by clicking the
                        + button above.
                      </MenuItem>
                    )}
                    {files.map((file) => (
                      <MenuItem
                        key={file.name}
                        value={file.name + ` File ID: (${file.id})`}
                      >
                        {file.name + ` File ID: (${file.id})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Visualization Requirements</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Specific Visualizations Required"
                  placeholder="Enter any specific visualizations that you want to be included like bar graphs, historgrams, etc."
                  name="visualizations"
                  variant="outlined"
                  fullWidth
                  value={formData.visualizations}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Libraries/Frameworks to Demonstrate"
                  placeholder="Enter any libraries or frameworks that you want to be demonstrated like Python, Tensorflow, etc."
                  name="frameworks"
                  variant="outlined"
                  fullWidth
                  value={formData.frameworks}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Output and Accessibility</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Accessibility Needs"
                  name="accessibility"
                  placeholder="Enter any accessibility needs that you want to be considered."
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={formData.accessibility}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Output/Exportable Formats for Results"
                  placeholder="Enter any output or exportable formats that you want the results to be in."
                  name="exportFormats"
                  variant="outlined"
                  fullWidth
                  value={formData.exportFormats}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>References and Additional Information</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-mutiple-chip-label">
                  Attach screenshots or images of graphs/charts for the
                  application.
                </InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  fullWidth
                  name="visualReferences"
                  value={formData.visualReferences}
                  onChange={handleChangeMultiple}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {images.length === 0 && (
                    <MenuItem disabled value="No Images Available">
                      No Images Available. You can upload images by clicking the
                      + button above.
                    </MenuItem>
                  )}
                  {images.map((image) => (
                    <MenuItem
                      key={image.name}
                      value={image.name + ` Image ID: (${image.id})`}
                    >
                      {image.name + ` Image ID: (${image.id})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="documentation"
                      checked={formData.documentation}
                      onChange={handleChange}
                      color="quSandBoxColor"
                    />
                  }
                  label="Do you require documentation or tutorials for the application to be created?"
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <Grid item xs={12}>
        <Button
          color="quCreateColor"
          onClick={labSubmitHandler}
          className={classes.submitButton}
          style={{
            float: "right",
          }}
          endIcon={<NavigateNextIcon />}
        >
          NEXT
        </Button>
        <Button
          variant="contained"
          color="quCreateColor"
          type="submit"
          startIcon={<SaveIcon />}
          style={{
            float: "right",
          }}
        >
          Save Requirements
        </Button>
        <Button
          color="quCreateColor"
          onClick={handleLabBack}
          className={classes.submitButton}
          style={{
            float: "left",
            fontWeight: "bold",
          }}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </Grid>
    </form>
  );
}

export default LabRequirementForm;
