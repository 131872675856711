import React, { useEffect } from "react";
import { Grid, Icon, Typography } from "@material-ui/core";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import ArtifactStructureReview from "./ArtifactStructureReview";


export default function SlidesStructureReview(props) {
    console.log("Props are: SlidesStructureReview  ", props);
    const [slides, setSlides] = React.useState([]);
    const { showNotification } = React.useContext(NotificationContext);

    const setSlidesForCard = () => {
        const card = props.slides;

        if (card) {
            console.log("Card is: ", card)
            const updatedSlides = card.map((slide) => {
                return {
                    id: slide.resource_id,
                    name: slide.resource_name,
                    url: slide.resource_link,
                    description: slide.resource_description,
                    type: slide.resource_type,
                };
            });
            setSlides(updatedSlides);
        } else {
            console.log("Card not found");
        }
    };

    // Call setFilesForCard whenever the id changes
    useEffect(() => {
        setSlidesForCard();
    }, []);

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5" style={{
                    marginTop: "20px"
                }}>Slides Structure Review</Typography>
                <Typography variant="body1">
                    Review the the slides by downloading it and checking the content.
                    You can replace the slide deck by uploading another one in its place.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <ArtifactStructureReview
                    files={slides} courseID={props.courseID} moduleID={props.moduleID} courseDesignStep={5}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container spacing={3} style={{
                    marginTop: "10px"
                }}>
                    <Grid item xs={12} sm={4} style={{
                        textAlign: "left",
                    }}>
                        <Button color="danger">
                            <Icon>close</Icon> &nbsp;
                            Exit Review</Button>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{
                        textAlign: "center"
                    }}>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{
                        textAlign: "right",

                    }}>
                        <Button color="success"
                            onClick={
                                () => { showNotification("Changes saved successfully", "quSandBoxColor") }
                            }
                        >
                            <Icon>save</Icon> &nbsp;
                            Save Changes</Button>
                        &nbsp;
                        <Button color="info"
                            onClick={
                                () => {
                                    showNotification("Marked as reviewed. You will be notified when the final deliverables are ready.", "quSandBoxColor");
                                }
                            }
                        >
                            <Icon>check</Icon>
                            &nbsp;
                            Mark as Reviewed</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}