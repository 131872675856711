import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Icon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles({
    rightAlignedCell: {
        textAlign: 'right',
        padding: '16px',
    },
    headerSection: {
        marginTop: '20px',
    },
    footerSection: {
        marginTop: '10px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
    title: { marginBottom: '20px' },
    button: {
        marginBottom: '20px',
        padding: '15px 30px',
        fontSize: '1.2rem',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    dialogContent: {
        width: '500px',
        maxWidth: '90vw',
    },
    dialogButtonSpacing: { margin: '10px 0' },
    urlCell: {
        wordBreak: 'break-word',
        maxWidth: '200px',
        textAlign: 'center',
    },
    centeredCell: {
        textAlign: 'center',
    },
    tableCell: {
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    headerCell: {
        padding: '26px',
    },
});

export default function LinkTable({ labs, title, description, showNotification }) {
    const classes = useStyles();

    return (
        <div>
            <Grid container>
                {/* Dynamic Header Section */}
                <Grid item xs={12} sm={12} >
                    <Typography variant="h5" style={{
                        marginTop: "20px"
                    }}>{title}</Typography>
                    <Typography variant="body1">
                        {description}
                    </Typography>
                </Grid>

                {/* Table Section */}
                <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell><strong>Description</strong></TableCell>
                                    <TableCell className={classes.rightAlignedCell}><strong>Colab Link</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {labs.map((lab, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{lab.name}</TableCell>
                                        <TableCell>{lab.description}</TableCell>
                                        <TableCell className={classes.rightAlignedCell}>
                                            <a href={lab.url} target="_blank" rel="noopener noreferrer">{lab.url}</a>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {/* Footer Section */}
                <Grid item xs={12} sm={12} className={classes.footerSection}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
                            <Button color="danger">
                                <Icon>close</Icon> &nbsp; Exit Review
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                            {/* Empty center grid */}
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
                            <Button
                                color="success"
                                onClick={() => { showNotification("Changes saved successfully", "quSandBoxColor"); }}
                            >
                                <Icon>save</Icon> &nbsp; Save Changes
                            </Button>
                            &nbsp;
                            <Button
                                color="info"
                                onClick={() => {
                                    showNotification("Marked as reviewed. You will be notified when the content is ready for the structure review.", "quSandBoxColor");
                                }}
                            >
                                <Icon>check</Icon> &nbsp; Mark as reviewed
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
