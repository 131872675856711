import React, { useEffect, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { fetchPDFAPI } from 'views/APIs/APIUtility';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Button from 'components/CustomButtons/Button';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};


const useStyles = makeStyles({

    pdfPage: {
        margin: '10px 0',
    },
});

export default function PDFViewer(props) {
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(props.activeSlide || 1);
    const scrollView = props.scrollView || false;
    const [showButtons, setShowButtons] = useState(true);

    useEffect(() => {
        async function fetchPDF(url) {
            try {
                if (url === undefined || url === null) { return; }
                const formData = new FormData();
                formData.append("url", url);
                const response = await fetchPDFAPI(formData); // Convert the response to a Blob
                const pdfBlob = await response.blob(); // Create a URL for the fetched PDF Blob            
                const pdfBlobUrl = URL.createObjectURL(pdfBlob); // Set the Blob URL to your state to display in the viewer            
                setPdfFile(pdfBlobUrl);
            }
            catch (error) {
                window.open(url, '_blank');
            }
        }
        fetchPDF(props.pdfUrl);
    }, [props.pdfUrl]);

    useEffect(() => {
        if (props.activeSlide) {
            setPageNumber(props.activeSlide);
        }
        if (showButtons) {
            setShowButtons(props.showButtons);
        }
    }, [props.activeSlide, props.showButtons]);

    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const goToPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
    const classes = useStyles();

    return (
        <div style={{
            marginTop: '20px',
            border: '1px solid #e0e0e0',
        }}>
            {pdfFile && (
                <Grid container>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '10px'
                    }}>
                        <div>
                            <Document
                                width={props.width}
                                file={pdfFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={options}
                            >
                                {scrollView ? <div style={{
                                    overflowY: 'auto',
                                    height: props.height || '80vh',
                                    overFlowX: 'hidden',
                                }}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            width={props.width}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            className={classes.pdfPage}
                                        />
                                    ))}
                                </div> : (
                                    <Page
                                        key={`page_${pageNumber}`}
                                        pageNumber={pageNumber}
                                        className={classes.pdfPage}
                                    />
                                )}
                            </Document>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                    }}>
                        {showButtons && !scrollView && (
                            <>
                                <Button onClick={goToPreviousPage} color="quCreateColor" disabled={pageNumber <= 1}>
                                    <ArrowBackIos />
                                </Button>

                                {/* Page Number Display */}
                                <span >
                                    {pageNumber} of {numPages}
                                </span>

                                {/* Next Button with Right Arrow */}
                                <Button onClick={goToNextPage} color="quCreateColor" disabled={pageNumber >= numPages}>
                                    <ArrowForwardIos />
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
}