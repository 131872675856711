export const research_report_instructions = `
### **Research Report**
**Sample Input Instructions:**
- **Topic:** "Impact of Artificial Intelligence on Healthcare Operations."
- **Scope:** Include recent technological advancements, ethical considerations, and real-world applications.
- **Objectives:** Provide a comprehensive overview, highlight challenges, and propose future opportunities.
- **Target Audience:** Healthcare professionals, policy-makers, and tech innovators.
`

export const white_paper_instructions = `
### **White Paper**
**Sample Input Instructions:**
- **Title:** "The Case for Renewable Energy in Urban Infrastructure."
- **Focus Areas:** Address current adoption rates, key technologies, and economic benefits.
- **Problem Statement:** Increasing energy demand in urban centers and reliance on fossil fuels.
- **Proposed Solution:** Demonstrate how renewable energy solutions can reduce emissions and costs.
- **Audience:** City planners, policymakers, and renewable energy advocates.
`

export const blog_instructions = `
### **Blog**
**Sample Input Instructions:**
- **Title:** "5 Ways AI is Revolutionizing E-commerce."
- **Tone:** Engaging and conversational, suitable for general readers.
- **Sections:** Include trends, benefits, challenges, and examples.
- **Call-to-Action:** Encourage readers to explore how their business can adopt AI strategies.
`

export const newsletter_instructions = `
### **Newsletter**
**Sample Input Instructions:**
- **Headline:** "Monthly AI Innovations Digest – November 2024."
- **Sections to Include:** 
  - Latest breakthroughs in AI technology.
  - Case studies from the past month.
  - Upcoming AI-related events or conferences.
- **Target Audience:** AI enthusiasts, researchers, and industry professionals.
- **CTA:** Link to a detailed article or invite subscribers to a webinar.
`

export const key_insights_instructions = `
### **Key Insights**
**Sample Input Instructions:**
- **Topic:** "Key Trends in Digital Transformation for 2024."
- **Focus:** Extract the most critical data points, industry insights, and actionable recommendations from the provided files.
- **Target Audience:** Executives and business strategists looking to stay competitive.
- **Output Style:** Include supporting visuals and concise bullet points for easy readability.
`

export const case_study_instructions = `
### **Case Study**
**Sample Input Instructions:**
- **Title:** "Improving Customer Retention with AI at XYZ Corp."
- **Background:** XYZ Corp struggled with a 20% annual churn rate in their customer base.
- **Challenges:** Ineffective engagement strategies and lack of personalized offers.
- **Solution:** Implementation of AI-driven recommendation systems.
- **Results:** Improved retention by 30% and increased sales by 15%.
- **Target Audience:** Business leaders and technology decision-makers.
`

export const handout_instructions = `
### **Handout**
**Sample Input Instructions:**
- **Topic:** "Best Practices for Remote Work Success."
- **Purpose:** Provide actionable tips and tools for improving remote team collaboration.
- **Key Sections:** 
  - Essential tools for remote teams.
  - Tips for maintaining productivity.
  - Best practices for virtual meetings.
- **Audience:** HR professionals and remote team leaders.
`

export const project_plan_instructions = `
### **Project Plan**
**Sample Input Instructions:**
- **Project Title:** "Implementing a Cloud-Based Data Management System."
- **Goals:** Improve data accessibility, scalability, and security across departments.
- **Key Milestones:** 
  - System requirements analysis.
  - Vendor selection.
  - Pilot program rollout.
- **Timeline:** Six months.
- **Team Structure:** Include roles for project manager, data engineers, and IT support.
- **Target Audience:** Internal stakeholders and project sponsors.
`