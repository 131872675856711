import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Dialog,
    DialogContent,
    DialogActions,
    Box,
    CircularProgress,
} from '@material-ui/core';
import { Delete, RestorePage, CloudDownload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import FileUploadDialog from './FileUploadDialog';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI } from 'views/APIs/APIUtility';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    pdfViewer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    pdfPage: {
        margin: '10px 0',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
    },
});

const getFileNameWithoutExtension = (fileName) => fileName.replace(/\.[^/.]+$/, '');

export default function ArtifactStructureReview(props) {
    const { showNotification } = React.useContext(NotificationContext);
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [courseDesignStep, setCourseDesignStep] = useState();

    useEffect(() => {
        // Set files and stop loading when props.files is updated
        if (props.files && Array.isArray(props.files)) {
            setFiles(props.files);
            setCourseDesignStep(props.courseDesignStep);
            setLoading(false);
        }
    }, [props.files]);

    const [open, setOpen] = useState(false);
    const [newFile, setNewFile] = useState({ name: '', description: '', file: null });
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [replaceFileIndex, setReplaceFileIndex] = useState(null);
    const [replace, setReplace] = useState(false);

    const handleReplace = (file, index) => {
        setReplaceFileIndex(index);
        setNewFile({ name: file.name, description: file.description, file: null });
        setReplace(true);
        setOpen(true);
    };

    const handleReplaceFile = async () => {
        setReplace(false);
        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);
        formData.append("resource_id", files[replaceFileIndex].id);
        formData.append("resource_name", newFile.name);
        formData.append("resource_type", files[replaceFileIndex].type);
        formData.append("resource_description", newFile.description);

        if (newFile.file) {
            formData.append("resource_file", newFile.file);
        }

        formData.append("course_design_step", courseDesignStep);

        try {
            replaceResourceAPI(formData);
            showNotification("File Updated Successfully", "success");
        }
        catch (error) {
            console.error("Error in handleReplaceFile:", error);
        }
        finally {
            handleSave();
            window.location.reload();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewFile({ name: getFileNameWithoutExtension(file.name), description: '', file });
        }
    };

    const handleSave = () => {
        if (!newFile.name.trim()) return;

        const { name, description, file } = newFile;
        const lastModified = new Date().toLocaleDateString();
        const size = (file.size / 1024 / 1024).toFixed(2) + ' MB';

        setLoading(true); // Start loading

        const updatedFiles = [...files];
        if (replaceFileIndex !== undefined) {
            updatedFiles[replaceFileIndex] = { name, description, lastModified, size };
        }
        setFiles(updatedFiles);

        setLoading(false); // Stop loading
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePreview = (file) => {
        if (file.url.endsWith('.pdf')) {
            setPdfFile(file.url);
            setPdfModalOpen(true);
        } else {
            window.open(file.url, '_blank');
        }
    };

    const handleDelete = (file) => {
        setLoading(true); // Start loading
        setFiles(files.filter(f => f.name !== file.name));
        setLoading(false); // Stop loading
    };

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handlePdfClose = () => {
        setPdfModalOpen(false);
        setPdfFile(null);
        setNumPages(null);
    };

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div className={classes.container}>

            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Description</strong></TableCell>
                            <TableCell align="center"><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files && files.length > 0 ? (
                            files.map((file, index) => (
                                <TableRow key={index}>
                                    <TableCell>{getFileNameWithoutExtension(file.name)}</TableCell>
                                    <TableCell>{file.description}</TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={() => handleDownload(file)}>
                                            <CloudDownload />
                                        </IconButton>
                                        <IconButton onClick={() => handleReplace(file, index)}>
                                            <RestorePage />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(file)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">No files available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <FileUploadDialog
                open={open}
                onClose={handleClose}
                newFile={newFile}
                setNewFile={setNewFile}
                onSave={replace ? handleReplaceFile : handleSave}
                replace={replace}
            />

            <Dialog open={pdfModalOpen} onClose={handlePdfClose} maxWidth="md" fullWidth>
                <DialogContent dividers>
                    <Box className={classes.pdfViewer}>
                        {pdfFile && (
                            <Document
                                file={pdfFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={options}
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        className={classes.pdfPage}
                                    />
                                ))}
                            </Document>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePdfClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
