import React, { useEffect, useState } from "react";
import {
  useHistory,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Timeline from "@material-ui/icons/Timeline";

// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
// import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import LogChart from "views/Components/LogChart.js";
// import UserTable from "views/Components/UserTable";
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  // Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch as MaterialSwitch,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { Delete, Edit, Favorite, KeyboardArrowRight } from "@material-ui/icons";
import { createNoSubstitutionTemplateLiteral } from "typescript";
import CardFooter from "components/Card/CardFooter";
import SweetAlert from "react-bootstrap-sweetalert";

import routes from "routes.js";

const useStyles = makeStyles(styles);
const alertClasses = makeStyles(sweetAlertStyle);

export default function UserAccessControls(props) {
  const classes = useStyles();
  const [tags, settags] = useState([])
  const history = useHistory();
  const [selectValue, setSelectValue] = React.useState("");
  const fields = ["User ID", "User Name", "Permissions"];
  const [user, setUser] = React.useState();
  // const { accessUser } = props.match.params;
  const { path, url, params } = useRouteMatch();
  const [fetched, setFetched] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [accessorUserID, setAccessorUserID] = useState();
  const [status, setStatus] = useState();
  const [displayAlert, setDisplayAlert] = useState();
  const [check, setCheck] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState();
  const [columns, setColumns] = useState([
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
    },
  ]);

  useEffect(() => {
    var tag_array = []
    for (var i = 0; i < routes.length; i++) {
      if (routes[i].name == "QuToolBox") {
        for (var j = 0; j < routes[i].views.length; j++) {
          tag_array.push(routes[i].views[j].path.split('/QuToolBox/')[1])
        }
      }
    }
    settags(tag_array)

    setAccessorUserID(params["userid"]);
    setUserEmail(params["userEmail"]);
    Auth.currentAuthenticatedUser()
      .then(function (user) {
        setUser(user);
      })
      .catch((err) => {
        // console.log(err);
        history.push("/auth/login-page");
      });
  }, []);

  useEffect(() => {
    setFetched(false);
    if (user) {
      if (selectValue == -1) {
        setColumns([
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Actions",
            accessor: "actions",
            disableSortBy: true,
          },
        ])
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(process.env.REACT_APP_BACK_END_URL + `/project?type=own`, {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }).then(
          function (res) {
            res.json().then(function (data) {
              fetch(
                process.env.REACT_APP_BACK_END_URL +
                `/admin/project/share/${accessorUserID}`,
                {
                  method: "GET",
                  mode: "cors",
                  credentials: "omit",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },
                }
              ).then(
                function (res1) {
                  res1.json().then(function (usersData) {
                    let temp = data.Items.map((item, index) => {
                      let temp = {
                        id: item["PK"].split("#")[1],
                        name: item["projectName"],
                      };
                      if (usersData.hasOwnProperty(item["PK"])) {
                        temp["permission"] =
                          usersData[item["PK"]]["projectPermission"];
                      } else {
                        temp["permission"] = "no";
                      }

                      return temp;
                    });
                    setRowData(temp);
                    setFetched(true);
                    return temp;
                  });
                },
                function (err) {
                  console.log(err);
                }
              );
            });
          },
          function (err) {
            console.log(err);
          }
        );
      } else {
        setColumns([
          {
            Header: "Name",
            accessor: "APIName",
          },
          {
            Header: "Version",
            accessor: "APIVersion",
          },
          {
            Header: "Permission",
            accessor: "APIPermission",
          },
          {
            Header: "Actions",
            accessor: "actions",
            disableSortBy: true,
          },
        ])
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(process.env.REACT_APP_BACK_END_URL + '/apiservice?tag=' + selectValue, {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }).then(
          function (res1) {
            res1.json().then(function (apis) {
              // console.log(apis)
              setRowData(apis.Items);
              setFetched(true);
            });
          },
          function (err) {
            console.log(err);
          }
        );
      }
    }
  }, [selectValue]);

  useEffect(() => {
    if (fetched) {
      if (selectValue == -1) {
        setRowData(
          rowData.map((item, index) => {
            item["actions"] = (
              <div className="actions-center">
                <RadioGroup
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                  aria-label="permission"
                  name="permissions"
                  value={item.permission}
                  onChange={(event) => {
                    setCheck(!check);
                    let newData = [...rowData];
                    newData.find((o, i) => {
                      if (o.id === item["id"]) {
                        o.permission = event.target.value;
                        o.updated = true;
                        return true;
                      }
                      return false;
                    });
                    setRowData(newData);
                  }}
                >
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No Access"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="read"
                    control={<Radio />}
                    label="View"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="clone"
                    control={<Radio />}
                    label="Clone"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="write"
                    control={<Radio />}
                    label="Edit"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="audit"
                    control={<Radio />}
                    label="Audit"
                    labelPlacement="bottom"
                  />
                </RadioGroup>
              </div>
            );
            return item;
          })
        );
      }
      else {
        setRowData(
          rowData.map((item, index) => {
            item["APIPermission"] = item["APIPermission"] ? item["APIPermission"] : 'private';
            if (!item['allowedUsers']) {
              item.allowedUsers = []
            }
            item["actions"] = (
              <div className="actions-center">
                <RadioGroup
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                  value={item['allowedUsers'].includes(accessorUserID)}
                  onChange={(event) => {
                    setCheck(!check);
                    let newData = [...rowData];
                    newData.find((o, i) => {
                      if (o.APIName === item["APIName"] && o.APIVersion === item["APIVersion"]) {
                        console.log(event.target.value)
                        if (event.target.value == 'true') {
                          o.allowedUsers = [accessorUserID]
                        } else {
                          o.allowedUsers = []
                        }
                        console.log(o.allowedUsers)
                        o.updated = true;
                        return true;
                      }
                      return false;
                    });
                    setRowData(newData);
                  }}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Disable"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Allow"
                    labelPlacement="bottom"
                  />
                </RadioGroup>
              </div>
            );
            return item;
          })
        );
      }
    }
  }, [fetched, check]);

  const handleSelectChange = (event) => {
    // console.log(event.target.value);
    setSelectValue(event.target.value);
  };

  const handleSubmit = () => {
    if (selectValue == -1) {
      rowData.map((item, index) => {
        let token = user.signInUserSession.accessToken.jwtToken;
        if (item.hasOwnProperty("updated") && item.updated) {
          setStatus("Save Successful");
          if (item.permission === "no") {
            fetch(
              process.env.REACT_APP_BACK_END_URL +
              `/user/${accessorUserID}/project/${item.id}`,
              {
                method: "DELETE",
                mode: "cors",
                credentials: "omit",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              }
            )
              .then(function (res) {
                // console.log(res);
                delete item.updated;
              })
              .catch((error) => {
                console.log(error);
                setStatus("Save Failed");
              });
          } else {
            fetch(
              process.env.REACT_APP_BACK_END_URL +
              `/user/${accessorUserID}/project/${item.id}?permission=${item.permission}`,
              {
                method: "PUT",
                mode: "cors",
                credentials: "omit",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              }
            )
              .then(function (res) {
                // console.log(res);
                delete item.updated;
              })
              .catch((error) => {
                console.log(error);
                setStatus("Save Failed");
              });
          }
          setDisplayAlert(true);
        }
      });
    } else {
      rowData.map((item, index) => {
        let token = user.signInUserSession.accessToken.jwtToken;
        if (item.hasOwnProperty("updated") && item.updated) {
          setStatus("Save Successful");
          fetch(
            process.env.REACT_APP_BACK_END_URL +
            `/admin/card/${item.APIName}/${item.APIVersion}/user/${accessorUserID}?allow=${item['allowedUsers'].includes(accessorUserID)}`,
            {
              method: "PUT",
              mode: "cors",
              credentials: "omit",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            }
          )
            .then(function (res) {
              // console.log(res);
              delete item.updated;
            })
            .catch((error) => {
              console.log(error);
              setStatus("Save Failed");
            });
          setDisplayAlert(true);
        }
      });
    }
  };

  const hideAlert = () => {
    setDisplayAlert(false);
  };

  return (
    <div>
      {displayAlert && (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={status}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
        />
      )}
      <Switch>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="quSandBox" icon>
                <CardIcon color="quSandBox">
                  <Timeline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  User Access Control - {userEmail}
                </h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Choose Module
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={selectValue}
                    onChange={handleSelectChange}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Module
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={-1}
                    >
                      Projects
                    </MenuItem>
                    {tags.map((tag, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        key={index}
                        value={tag}
                      >
                        {tag}
                      </MenuItem>
                    ))

                    }
                  </Select>
                </FormControl>
                <ReactTable columns={columns} data={rowData} />
              </CardBody>
              <CardFooter>
                <GridContainer justify="center">
                  <GridItem md={2} sm={12}>
                    <Button color="info" size="lg" onClick={handleSubmit}>
                      Save <KeyboardArrowRight className={classes.icon} />
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </Switch>
    </div>
  );
}
