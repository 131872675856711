import React, { useState } from "react";
import {
    Grid,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,
    Divider,
    Stepper,
    Step,
    StepLabel,
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import Button from "components/CustomButtons/Button.js";
import { CloudUpload } from "@material-ui/icons";
import NotificationContext from "views/Components/Context.js";
import { DropzoneArea } from "material-ui-dropzone";
import { generatePodcastOutlineAPI } from "views/APIs/APIUtility";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import SweetAlert from "react-bootstrap-sweetalert";
import StepConnector from "@material-ui/core/StepConnector";

const useStyles = makeStyles({
    ...styles,
    dropzone: {
        minHeight: "100px",
        backgroundColor: "#f0f0f0",
        borderColor: quCreateColor,
        border: "2px dashed",
        borderRadius: "5px",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    previewText: {
        color: "#007BFF", // Change the text color
    },
    dropzoneText: {
        color: "#888888", // Grey color for instructions
    },
});

const QontoConnector = withStyles({
    active: {
        color: quCreateColor,
    },
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: "#784af4",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#784af4",
        },
    },
    line: {
        borderColor: quCreateColor,
        borderTopWidth: 3,
        borderRadius: 1,
    },
    stepLabel: {
        color: quCreateColor,
    },
})(StepConnector);

const usesweetAlertStyle = makeStyles(sweetAlertStyle);
export default function CreatePodcastModal({
    newPodcastDetails,
    setNewPodcastDetails,
    iconImage,
    createPodcastModal,
    handleCreatePodcastModalClose,
    handleCreatePodcast,
    handleIconUpload,
    podcastCreationLoader,
}) {
    const classes = useStyles();
    const { showNotification } = React.useContext(NotificationContext);

    const sweetAlertClasses = usesweetAlertStyle();

    const dialogStages = [
        "Basic Details",
        "Instructions & Files",
        "Podcast Outline",
        "Submit",
    ];
    const stepperDisplayStages = [
        "Basic Details",
        "Instructions & Files",
        "Podcast Outline",
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [currentDialog, setCurrentDialog] = useState(dialogStages[0]);
    const [podcastOutlineLoader, setPodcastOutlineLoader] = React.useState(false);
    const [alert, setAlert] = React.useState(null);

    const generatePodcastOutline = async () => {
        setPodcastOutlineLoader(true);
        const formData = new FormData();
        newPodcastDetails.uploadedFiles.forEach((file) => {
            formData.append("files", file);
        });
        formData.append("instructions", newPodcastDetails.template);

        try {
            const data = await generatePodcastOutlineAPI(formData);
            setNewPodcastDetails({ ...newPodcastDetails, podcastOutline: data });
            setPodcastOutlineLoader(false);
        } catch (error) {
            showNotification(error, "danger");
            setPodcastOutlineLoader(false);
        }
    };

    // Function to handle file upload
    const handleFileUpload = (files) => {
        setNewPodcastDetails({ ...newPodcastDetails, uploadedFiles: files });
    };

    const replacePlaceholders = (template, values) => {
        return Object.keys(values).reduce(
            (result, key) => result.replace(`{{${key}}}`, values[key]),
            template
        );
    };

    // Handle next actions on dialogs
    const handleStep1 = () => {
        if (newPodcastDetails.podcastName === "" || newPodcastDetails.brief === "") {
            showNotification(
                "Please make sure all fields, including the podcast name and description, are properly filled out.",
                "danger"
            );
            return;
        }
        newPodcastDetails.template = replacePlaceholders(newPodcastDetails.template, {
            podcastName: newPodcastDetails.podcastName,
            podcastDescription: newPodcastDetails.brief,
        });
        setActiveStep(1);
        setCurrentDialog(dialogStages[1]);
    };

    const handleStep2 = async () => {
        if (newPodcastDetails.template === "") {
            showNotification(
                "Please fill in the instructions for generating the podcast outline.",
                "danger"
            );
            return;
        }
        generatePodcastOutline();
        setActiveStep(2);
        setCurrentDialog(dialogStages[2]);
    };

    const handleStep3 = async () => {
        if (newPodcastDetails.podcastOutline === "") {
            showNotification("Please fill in the podcast outline.", "danger");
            return;
        }
        setActiveStep(3);
        setCurrentDialog(dialogStages[3]);
        await handleCreatePodcast();
        handleClose();
    };

    const handleStep4 = (includeModules = true) => {
        handleCreatePodcast(includeModules);
        setActiveStep(0);
        setCurrentDialog(dialogStages[0]);
    };

    const handleIncludeModules = (includeModules) => {
        hideAlert();
        setActiveStep(3);
        setCurrentDialog(dialogStages[3]);
        handleStep4(includeModules);
    };

    const hideAlert = () => {
        setAlert(null);
    };


    const handleClose = () => {
        setActiveStep(0);
        setCurrentDialog(dialogStages[0]);
        handleCreatePodcastModalClose();
    };



    const renderDialogContent = () => {
        switch (currentDialog) {
            case "Basic Details":
                return (
                    <div>
                        <Typography variant="h5" style={{ marginBottom: "10px" }}>
                            Enter the podcast details:
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="podcastName"
                            label="Podcast Name"
                            placeholder="AI and Machine Learning in Finance"
                            type="text"
                            fullWidth
                            required
                            value={newPodcastDetails.podcastName}
                            onChange={(event) =>
                                setNewPodcastDetails({
                                    ...newPodcastDetails,
                                    podcastName: event.target.value,
                                })
                            }
                        />
                        <TextField
                            margin="dense"
                            id="brief"
                            label="Brief Description"
                            placeholder="This podcast provides a comprehensive introduction to the fundamentals of Artificial Intelligence (AI) and Machine Learning (ML) in finance."
                            type="text"
                            fullWidth
                            multiline
                            required
                            rows={4}
                            value={newPodcastDetails.brief}
                            onChange={(event) =>
                                setNewPodcastDetails({
                                    ...newPodcastDetails,
                                    brief: event.target.value,
                                })
                            }
                        />
                        {/* Icon Image Upload */}
                        <Button
                            variant="contained"
                            component="label"
                            color="info"
                            style={{ marginTop: "20px" }}
                        >
                            <CloudUpload /> &nbsp; Upload Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                required
                                onChange={handleIconUpload}
                            />
                        </Button>
                        {iconImage && (
                            <div style={{ marginTop: "10px" }}>
                                <strong>Uploaded Image:</strong> {iconImage.name}
                            </div>
                        )}
                        <Typography
                            variant="caption"
                            style={{ display: "block", marginTop: "5px" }}
                        >
                            A 16:9 aspect ratio image is recommended
                        </Typography>
                    </div>
                );
            case "Instructions & Files":
                return (
                    <Grid container spacing={3} alignItems="flex-start">
                        {/* Title for the Template Editing */}
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                Edit the instructions to customize it to your requirements:
                            </Typography>
                        </Grid>

                        {/* MDEditor for Template Editing */}
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <div data-color-mode="light">
                                <MDEditor
                                    value={newPodcastDetails.template}
                                    onChange={(event) =>
                                        setNewPodcastDetails({
                                            ...newPodcastDetails,
                                            template: event,
                                        })
                                    }
                                    height={250}
                                />
                            </div>
                        </Grid>

                        {/* Title for File Upload */}
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <Typography
                                variant="h5"
                                style={{ marginBottom: "10px", marginTop: "10px" }}
                            >
                                Upload supporting files to generate the podcast outline:
                            </Typography>
                        </Grid>

                        {/* DropzoneArea for File Upload */}
                        <Grid item xs={12}>
                            <DropzoneArea
                                initialFiles={newPodcastDetails.uploadedFiles}
                                onChange={handleFileUpload}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                useChipsForPreview
                                maxFileSize={50000000}
                                acceptedFiles={[
                                    "application/pdf",
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    "text/html",
                                    "text/x-c++src",
                                    "application/json",
                                    "text/markdown",
                                ]}
                                previewText="Uploaded files"
                                dropzoneText="Drag and drop files or click here."
                                filesLimit={100}
                                dropzoneClass={classes.dropzone}
                            />
                        </Grid>
                    </Grid>
                );
            case "Podcast Outline":
                return (
                    <div>
                        <Typography variant="h5" style={{ marginBottom: "10px" }}>
                            Podcast outline:
                        </Typography>
                        {podcastOutlineLoader ? (
                            <div style={{ display: "flex" }}>
                                <CircularProgress size={20} /> &nbsp;
                                <Typography variant="body1">
                                    Generating podcast outline...
                                </Typography>
                            </div>
                        ) : (
                            <div data-color-mode="light">
                                <MDEditor
                                    value={newPodcastDetails.podcastOutline}
                                    onChange={(event) =>
                                        setNewPodcastDetails({
                                            ...newPodcastDetails,
                                            podcastOutline: event,
                                        })
                                    }
                                    height={400}
                                />
                            </div>
                        )}
                    </div>
                );
            case "Submit":
                return (
                    <div style={{ display: "flex" }}>
                        <CircularProgress size={20} /> &nbsp;
                        <Typography variant="body1">Creating podcast for you...</Typography>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {alert}
            <Dialog
                open={createPodcastModal}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth
            >
                {/* Stepper Component */}
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<QontoConnector />}
                >
                    {stepperDisplayStages.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Divider />
                <DialogTitle id="form-dialog-title">
                    <div style={{ fontSize: "30px" }}>
                        {newPodcastDetails.podcastName
                            ? newPodcastDetails.podcastName
                            : "Create a New Podcast"}
                    </div>
                </DialogTitle>
                <DialogContent>{renderDialogContent()}</DialogContent>
                <DialogActions>
                    {currentDialog !== "Basic Details" && (
                        <Button
                            onClick={() => {
                                setActiveStep(activeStep - 1);
                                setCurrentDialog(dialogStages[activeStep - 1]);
                            }}
                            color="quCreateColor"
                            disabled={podcastOutlineLoader || podcastCreationLoader}
                        >
                            Back
                        </Button>
                    )}
                    <Button
                        onClick={handleCreatePodcastModalClose}
                        color="quCreateColor"
                        disabled={podcastOutlineLoader || podcastCreationLoader}
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={() => {
                            switch (currentDialog) {
                                case "Basic Details":
                                    return handleStep1();
                                case "Instructions & Files":
                                    return handleStep2();
                                case "Podcast Outline":
                                    return handleStep3();
                                case "Submit":
                                    return handleStep4();
                                default:
                                    return null;
                            }
                        }}
                        disabled={podcastOutlineLoader || podcastCreationLoader}
                        color="quCreateColor"
                    >
                        {currentDialog === "Podcast Outline" ? "Submit" : "Next"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
