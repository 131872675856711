import React, { useState, useEffect } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Box,
} from '@material-ui/core';
import { useModuleID } from "./Data/customhook";
import { cardData } from './Data/modules';
import { Delete, Visibility } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Button from "components/CustomButtons/Button.js";
import NotificationContext from "views/Components/Context.js";


// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
    addButton: {
        backgroundColor: '#3f51b5',
        color: '#fff',
        padding: '10px',
        margin: '10px',
        '&:hover': {
            backgroundColor: '#303f9f',
        },
    },
    title: { marginBottom: '20px' },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    dialogContent: {
        width: '500px',
        maxWidth: '90vw',
    },
    dialogButtonSpacing: { margin: '10px 0' },
    pdfViewer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    pdfPage: {
        margin: '10px 0',
    },
});

const getFileNameWithoutExtension = (fileName) => fileName.replace(/\.[^/.]+$/, '');

export default function FilePage(props) {
    const { showNotification } = React.useContext(NotificationContext);

    const [id, setId] = useState("");
    const courseID = props.courseID;
    const moduleID = props.moduleID;

    const [files, setFiles] = useState([]);

    const setFilesForCard = () => {
        const card = cardData.find((card) => card.id === parseInt(id));

        if (card) {
            const updatedFiles = card.files.map((file) => {
                return {
                    name: file.name,
                    url: file.path,
                    description: file.description,
                };
            });
            setFiles(updatedFiles);
        } else {
            console.log("Card not found");
        }
    };

    // Call setFilesForCard whenever the id changes
    useEffect(() => {
        if (id) {
            setFilesForCard();
        }
    }, [id]);

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [newFile, setNewFile] = useState({ name: '', description: '', file: null });
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    const handleUpload = () => {
        setNewFile({ name: '', description: '', file: null });
        setOpen(true);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewFile({ name: getFileNameWithoutExtension(file.name), description: '', file });
        }
    };

    const handleSave = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePreview = (file) => {
        console.log("File is: ", file.url);
        if (file.url.endsWith('.pdf')) {
            setPdfFile(file.url);
            setPdfModalOpen(true);
        } else {
            window.open(file.url, '_blank');
        }
    };

    const handleDelete = (file) => setFiles(files.filter(f => f.name !== file.name));

    const handlePdfClose = () => {
        setPdfModalOpen(false);
        setPdfFile(null);
        setNumPages(null);
    };

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    return (
        <div className={classes.container}>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Description</strong></TableCell>
                            <TableCell><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map((file, index) => (
                            <TableRow key={index}>
                                <TableCell>{getFileNameWithoutExtension(file.name)}</TableCell>
                                <TableCell>{file.description}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handlePreview(file)}>
                                        <Visibility />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(file)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add a New File</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="File Name"
                        type="text"
                        fullWidth
                        value={newFile.name}
                        onChange={(e) => setNewFile({ ...newFile, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        value={newFile.description}
                        onChange={(e) => setNewFile({ ...newFile, description: e.target.value })}
                    />
                    <Button variant="contained" component="label" className={classes.dialogButtonSpacing}>

                        Upload File
                        <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                    {newFile.file && <Typography>{newFile.file.name}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            {/* PDF Preview Modal */}
            <Dialog open={pdfModalOpen} onClose={handlePdfClose} maxWidth="md" fullWidth>
                <DialogContent dividers>
                    <Box className={classes.pdfViewer}>
                        {pdfFile && (
                            <Document
                                file={pdfFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={options}
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        className={classes.pdfPage}
                                    />
                                ))}
                            </Document>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePdfClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
