import React, { useEffect } from "react";
import { Route, Switch, Link, useRouteMatch, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SearchBar from "material-ui-search-bar";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import { InputLabel, Dialog, TextField, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, CircularProgress } from "@material-ui/core";
// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import APIDetails from "views/APIs/APIDetails.js"
import availableProviders from "provider.js"
import Heading from "components/Heading/Heading";

var tempTags = JSON.parse(JSON.stringify(availableProviders));
tempTags['Tensorflow'] = { "thumbnail": "tensorflow.png" }
tempTags['Jupyter'] = { "thumbnail": "jupyter.jpg" }
tempTags['Pytorch'] = { "thumbnail": "Pytorch_logo.png" }
tempTags['RStudio'] = { "thumbnail": "Rlogo.png" }
tempTags['Snowflake'] = { "thumbnail": "Snowflake.svg" }
tempTags['Julia'] = { "thumbnail": "julia.png" }
// const availableTags = tempTags

const useStyles = makeStyles(styles);
// const usemodalStyles = makeStyles(modalStyle);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function QuApi() {
  const classes = useStyles();
  const history = useHistory();
  const sweetAlertClasses = usesweetAlertStyle();
  const extendedFormsClasses = useextendedFormsStyles();
  const images = require.context('assets/img', true);
  const [user, setUser] = React.useState("");
  const [allApis, setAllApis] = React.useState([]);
  const [permanentApis, setpermanentApis] = React.useState([]);
  const [userType, setUserType] = React.useState("");
  const [modal, setModal] = React.useState(false);
  // const [filter, setFilter] = React.useState(false);

  const [head, sethead] = React.useState("");
  const [version, setversion] = React.useState("1.0.0");
  const [brief, setbrief] = React.useState("");
  const [iframeLink, setiframeLink] = React.useState("");
  const [iframeHeight, setIframeHeight] = React.useState();
  const [streamlitLink, setstreamlitLink] = React.useState("");
  const [gistLink, setgistLink] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [colab, setcolab] = React.useState("");
  const [type, settype] = React.useState("QuSandbox");
  const [tag, settag] = React.useState("default");
  const [json, setjson] = React.useState("{}");
  const [permission, setpermission] = React.useState("private")
  const [alert, setAlert] = React.useState(null);

  const { path, url } = useRouteMatch();
  const [searchValue, setSearchValue] = React.useState("");

  const [loading, setLoading] = React.useState(true)
  // const [data, setData] = React.useState({
  //   factset: false,
  //   moodys: false,
  //   refinitive: false,
  //   sp: false
  // });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(function (user) {
      setUser(user);
      setUserType(user.signInUserSession.accessToken.payload["cognito:groups"][0]);
    }).catch(err => {
      console.log(err)
      if (err === "not authenticated") {
        history.push("/auth/login-page");
      }
    });
    // setAllApis(listOfApis);
  }, []);

  useEffect(() => {
    setLoading(true)
    var filters = path.split('/');
    var filter = filters[filters.length - 1];
    if (filter === 'all') {
      filter = 'default'
    }
    console.log("Before filter");
    console.log(filter)
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/apiservice?tag=" + filter, {
        method: "GET",
        mode: "cors",
        credentials: 'omit',
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        }
      }).then(function (res) {
        res.json().then(function (data) {
          setAllApis(data.Items)
          setpermanentApis(data.Items)
          setLoading(false)
        })
      }, function (err) {
        console.log(err)
      });
    }
  }, [user])

  const setNewApiList = (newList) => {
    if (newList.length === 0) {
      setAllApis(permanentApis)
    }
    else {
      setAllApis(newList)
    }
  }

  const deleteAlert = (apiData) => {
    // console.log(apiData)
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteProject(apiData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
        cancelBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this API!
      </SweetAlert>
    );
  };

  const cloneAlert = (apiData) => {
    // console.log(apiData)
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleCloneProject(apiData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
        cancelBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
        confirmBtnText="Yes, clone it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will create a new API with _copy as suffix!
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const handleDeleteProject = (apiData) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(process.env.REACT_APP_BACK_END_URL + "/apiservice", {
      method: "DELETE",
      mode: "cors",
      credentials: 'omit',
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
      body: JSON.stringify(apiData)
    }).then(function (res) {
      return res.json()
    }).then(function (data) {
      window.location.reload();
    }).catch((error) => {
      console.log(error)
    });
  }

  const handleCloneProject = (apiData) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(process.env.REACT_APP_BACK_END_URL + "/apiservice?operation=clone", {
      method: "POST",
      mode: "cors",
      credentials: 'omit',
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
      body: JSON.stringify({ "APIName": apiData.APIName, "APIVersion": apiData.APIVersion })
    }).then((res) => {
      window.location.reload();
    }).catch((error) => {
      console.log(error)
    });
  }

  // const toggleFilter = () => {
  //   if(filter){
  //     setFilter(false)
  //   }
  //   else{
  //     setFilter(true)
  //   }
  // }
  // let dataList = Object.keys(data);
  // const { factset, moodys, refinitive, sp } = data;
  // const handleChange = (event) => {
  //   setData({ ...data, [event.target.name]: event.target.checked });
  // };

  const createProject = () => {
    setModal(true)
  }
  const handleClose = () => {
    setModal(false);
  };

  const handleCreateProject = () => {
    if (head.length > 0 && userType === 'admin') {
      handleClose()
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/apiservice", {
        method: "POST",
        mode: "cors",
        credentials: 'omit',
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify({
          "APIType": type,
          "APIVersion": version,
          "APIName": head,
          "brief": brief,
          "iframeLink": iframeLink,
          "iframeHeight": iframeHeight,
          "streamlitLink": streamlitLink,
          "gistLink": gistLink,
          "description": description,
          "colab": colab,
          "tag": tag.split(','),
          "json": json,
          "APIPermission": permission
        })
      }).then(function (res) {
        return res.json()
      }).then(function (data) {
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        // setnewKeyError(error.message);
        // setBR(true);
        // setTimeout(function () {
        //   setBR(false);
        //   setnewKeyError();
        // }, 6000);
      });
    }
  }

  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e === "") {
      setAllApis(permanentApis)
      return;
    }
    var newList = [];
    for (var i = 0; i < permanentApis.length; i++) {
      if (permanentApis[i].APIName.toLowerCase().includes(e.toLowerCase())
        || (permanentApis[i].brief && permanentApis[i].brief.toLowerCase().includes(e.toLowerCase()))) {
        newList.push(permanentApis[i])
      }
    }
    setAllApis(newList)
  }

  return (
    <div>
      {alert}
      <Dialog open={modal} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Project</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            className={extendedFormsClasses.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={extendedFormsClasses.selectLabel}
            >
              Choose Type
            </InputLabel>
            <Select
              MenuProps={{
                className: extendedFormsClasses.selectMenu
              }}
              classes={{
                select: extendedFormsClasses.select
              }}
              value={type}
              onChange={event => { settype(event.target.value) }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
                value: type
              }}
            >
              {Object.keys(availableProviders).map((provider, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected
                  }}
                  value={provider}
                >
                  {provider}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            className={extendedFormsClasses.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={extendedFormsClasses.selectLabel}
            >
              Choose Permission
            </InputLabel>
            <Select
              MenuProps={{
                className: extendedFormsClasses.selectMenu
              }}
              classes={{
                select: extendedFormsClasses.select
              }}
              value={permission}
              onChange={event => { setpermission(event.target.value) }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
                value: permission
              }}
            >
              <MenuItem
                key="0"
                classes={{
                  root: extendedFormsClasses.selectMenuItem,
                  selected: extendedFormsClasses.selectMenuItemSelected
                }}
                value="public"
              >
                Public
              </MenuItem>
              <MenuItem
                key="1"
                classes={{
                  root: extendedFormsClasses.selectMenuItem,
                  selected: extendedFormsClasses.selectMenuItemSelected
                }}
                value="private"
              >
                Private
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="head"
            label="Heading"
            type="text"
            onChange={event => { sethead(event.target.value) }}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="version"
            label="Version"
            type="text"
            onChange={event => { setversion(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="tag"
            value={tag}
            label="tag(split by ,)"
            type="text"
            onChange={event => { settag(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="brief"
            label="Brief Description"
            type="text"
            onChange={event => { setbrief(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="iframeLink"
            label="Link to iFrame content"
            type="text"
            onChange={event => { setiframeLink(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="iframeHeight"
            label="iFrame Height"
            type="number"
            onChange={event => { setIframeHeight(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="streamlitLink"
            label="Link for the Run Demo Button"
            type="text"
            onChange={event => { setstreamlitLink(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="gistLink"
            label="Link to git gist for this project"
            type="text"
            onChange={event => { setgistLink(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="colab"
            label="Link for the Run on QUSandbox Button"
            type="text"
            onChange={event => { setcolab(event.target.value) }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="description"
            label="Detailed desciption for this project"
            type="text"
            onChange={event => { setdescription(event.target.value) }}
            fullWidth
          />
          <CustomInput
            labelText="Custom key json"
            id="custom_key_json"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              multiline: true,
              rows: 5,
              onChange: event => {
                setjson(event.target.value)
              },
              value: json
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="quSandBoxColor">
            Cancel
          </Button>
          <Button onClick={handleCreateProject} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Switch>
        <Route exact path={path}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8} lg={10}>
              <SearchBar
                value={searchValue}
                onChange={(newValue) => searchInputValue(newValue)}
                onRequestSearch={(newValue) => searchInputValue(newValue)}
              /><br />
            </GridItem>
            <GridItem xs={12} sm={2} md={2} lg={2} style={{ textAlign: 'center' }}>
              {userType === 'admin' &&
                <Button color="info" onClick={createProject}>
                  Create Project
                </Button>
              }
            </GridItem>
          </GridContainer>
          {loading ?
            <Heading title={<CircularProgress />} textAlign="center" />
            :
            <GridContainer>
              {allApis.map((api, index) => (
                <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                  <Link to={path + '/' + api.APIName + '/' + api.APIVersion}>
                    <Card style={{ minHeight: "225px" }}>
                      <CardHeader stats icon>
                        <CardIcon color="quSandBox">
                          {api.APIName}
                        </CardIcon>

                        <p className={classes.cardCategory}>Version: {api.APIVersion}</p>
                        <h3 className={classes.cardTitle}>
                          {
                            api.APIType in tempTags ?
                              <small>{api.APIType && <img src={images('./' + tempTags[api.APIType]['thumbnail'])} alt="" className={classes.thumbnail} />}</small>
                              :
                              <small>{api.APIType && <img src={images('./qusandbox_logo_v2.5.png')} alt="" className={classes.thumbnail} />}</small>
                          }
                          {/* <small>{api.thumbnail2 && <img src={images('./' + api.thumbnail2)} alt="" className={classes.thumbnail} />}</small>
                    <small>{api.thumbnail3 && <img src={images('./' + api.thumbnail3)} alt="" className={classes.thumbnail} />}</small> */}
                        </h3>

                      </CardHeader>
                      <CardBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                      </CardBody>
                      <CardFooter>
                        <h5>{api.brief}</h5>
                      </CardFooter>
                      {/* <CardFooter>
                    </CardFooter> */}
                      <CardFooter stats style={{ marginTop: '0px' }}>
                        {/* {userType === 'admin' &&
                        <div>
                          <FileCopyIcon style={{ color: '#435966' }} onClick={e => e.preventDefault(cloneAlert(api))} />
                          <DeleteIcon style={{ color: '#435966' }} onClick={e => e.preventDefault(deleteAlert(api))} />
                        </div>
                      } */}
                        {/* <div>
                        {api.collector && api.collector.includes(user.username) ?
                          <BookmarkIcon style={{ color: '#435966' }} onClick={e => e.preventDefault()} />
                          :
                          <BookmarkBorderIcon style={{ color: '#435966' }} onClick={e => e.preventDefault()} />
                        }
                      </div> */}
                        {userType === 'admin' &&
                          <FileCopyIcon style={{ color: '#435966' }} onClick={e => e.preventDefault(cloneAlert(api))} />
                        }
                        {userType === 'admin' &&
                          <DeleteIcon style={{ color: '#435966' }} onClick={e => e.preventDefault(deleteAlert(api))} />
                        }
                      </CardFooter>
                    </Card>
                  </Link>
                </GridItem>
              ))}
            </GridContainer>
          }
        </Route>
        <Route path={`${path}/:name/:version`}>
          <APIDetails />
        </Route>
      </Switch>
    </div >
  );
}
