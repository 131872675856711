import React from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
// import Timeline from "@material-ui/icons/Timeline";
// import Code from "@material-ui/icons/Code";
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// Amplify components
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { VerifiedUser } from "@material-ui/icons";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ForgotPasswordPage(props) {
  const history = useHistory();
  const [checked, setChecked] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState("")
  // const [modal, setModal] = React.useState(false);
  // const [confirmModal, setConfirmModal] = React.useState(false);
  // const [code, setCode] = React.useState("");
  const [validationErrors, setValidationErrors] = React.useState({});
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const hideAlert = () => {
    setAlert(null);
  };

  const resetPassword = () => {
    // console.log("in reset");
    if (validate()) {
    Auth.forgotPassword(email)
    .then(data => {
      history.push("/auth/update-password-page",{email})
      // console.log(data)
    })
    .catch(err => {
      console.log(err)
      setErrorMessage(err.message);
      basicAlert();
    });
  }
}
  const validate = () => {
    const err = {};
    const requiredFields = [
     email
    ];
    requiredFields.forEach(field => {
      if(!field) {
        err[field]="Required"
      }
    });
    if(email&&  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err[email] = 'Invalid email address'
     }
    setValidationErrors(err);
    if (Object.keys(err).length>0) {
      return false;
    } else {
      return true
    };
  }
   
  const basicAlert = async () => {
    let alertMessage;
    await setErrorMessage((errorMessage)=>{
      alertMessage=errorMessage;
      return errorMessage;
    }) 
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={alertMessage}
        onConfirm={() => hideAlert()}
        // onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {alert}
      <GridContainer justify="center">
        <GridItem sm={12} md={8} lg={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} `}
                color="quSandBox"
              >
                <h4 className={classes.cardTitle}>Reset Password</h4>
                
              </CardHeader>
              <CardBody>
              <h5>A confirmation code will be sent to your registered email address</h5>
              <CustomInput
                  labelText="Email"
                  id="registered-email"
                  onChange={(e) => setEmail(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={!!validationErrors[email] || false}
                  helperText={validationErrors[email]}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button onClick={resetPassword} color="danger" simple size="lg" block>
                  Send Code
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
