import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SlidesContentReview from "../SlidesContentReview";
import ModuleInformationContentReview from "./ModuleInformationContentReview";
import LabsContentReview from "../LabsContentReview";
import Button from "components/CustomButtons/Button.js";
import { Auth } from "aws-amplify";
import NotificationContext from "views/Components/Context.js";
import { Divider, Grid, Typography } from "@material-ui/core";

export default function ContentReview(props) {
    console.log("In content review: ", props);
    const { showNotification } = React.useContext(NotificationContext);
    const courseID = props.courseID;
    const moduleID = props.moduleID;
    const [user, setUser] = useState();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("Module Information");

    const [moduleInformation, setModuleInformation] = useState([]);
    const [slides, setSlides] = useState([]);
    const [labs, setLabs] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true); // Track loading state

    const setContentReviewFromCourse = (data) => {
        const modules = data.modules;
        const module = modules.find(module => module.module_id === moduleID);
        const resources = module.pre_processed_content;
        const slides = resources.filter(resource => resource.resource_type === "Slide_Content");
        const links = resources.filter(resource => resource.resource_type === "Link");
        const notes = resources.filter(resource => resource.resource_type === "Note");

        setSlides(slides);
        setLabs(links);
        setModuleInformation(notes);
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_FASTAPI_URL + `/get_course/${courseID}`)
            .then(res => res.json())
            .then(data => {
                setContentReviewFromCourse(data);
            })
            .catch(err => {
                console.log(err);
                showNotification("Error fetching course data", "danger");
            })
            .finally(() => {
                setModulesLoading(false); // Set loading to false after data fetch
            });
    }, [courseID]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const renderContent = () => {
        if (modulesLoading) {
            return <Typography variant="body1">Loading content...</Typography>; // Loading indicator
        }

        switch (activeTab) {
            case "Module Information":
                return <ModuleInformationContentReview courseID={props.courseID} moduleID={props.moduleID} filetype="Note" moduleInformation={moduleInformation} course_design_step={2} />;
            case "Slides":
                return <SlidesContentReview courseID={props.courseID} moduleID={props.moduleID} filetype="Slide_Content" slides={slides} />;
            case "Labs":
                return <LabsContentReview courseID={props.courseID} moduleID={props.moduleID} filetype="Link" links={labs} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <div>
                <Grid container display="flex" justify="space-between" style={{ marginTop: "20px" }}>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ marginTop: "10px", fontWeight: "bold", fontSize: "20px" }}>Content Review</Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px" }}>
                        <Button color="quSandBoxColor" style={activeTab === "Module Information" ? { textDecoration: "underline" } : {}} onClick={() => setActiveTab("Module Information")}>Module Information</Button>
                        <Button color="quSandBoxColor" style={activeTab === "Slides" ? { textDecoration: "underline" } : {}} onClick={() => setActiveTab("Slides")}>Slides</Button>
                        <Button color="quSandBoxColor" style={activeTab === "Labs" ? { textDecoration: "underline" } : {}} onClick={() => setActiveTab("Labs")}>Labs</Button>
                    </Grid>
                </Grid>
                <Divider />
            </div>

            {renderContent()}
        </div>
    );
}
