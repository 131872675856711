import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Box,
  Grid,
  Card,
  Tooltip,
  Divider,
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import { CloudDownload, DoneAll, RotateLeft } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import PDFViewer from "views/Components/PDFViewer";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const usesweetAlertStyle = makeStyles(sweetAlertStyle);

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",
    boxSizing: "border-box",
  },
  title: { marginBottom: "20px" },
  button: {
    marginBottom: "20px",
    padding: "15px 30px",
    fontSize: "1.2rem",
  },
  tableContainer: {
    width: "100%",
    maxHeight: "70vh",
    margin: "0 auto",
  },
  dialogContent: {
    width: "500px",
    maxWidth: "90vw",
  },
  dialogButtonSpacing: { margin: "10px 0" },
  urlCell: {
    wordBreak: "break-word",
    maxWidth: "200px",
    textAlign: "center",
  },
  centeredCell: {
    textAlign: "center",
    padding: "16px", // Ensures equal padding inside each cell
  },
  rightAlignedTitleCell: {
    textAlign: "right",
    padding: "16px", // Ensures equal padding inside each cell
  },
  rightAlignedCell: {
    textAlign: "right",
    padding: "16px", // Ensures equal padding inside each cell
  },
});

export default function ReviewLabProject({
  projectID,
  projectType,
  setActiveStep,
  deliverables = false,
}) {
  const classes = useStyles();
  const { showNotification, setNotification } = React.useContext(
    NotificationContext
  );
  const [links, setLinks] = useState([
    {
      name: "Understanding the 1/n Portfolio Family",
      description:
        "The 1/n portfolio family is a set of portfolios that allocate capital equally across a set of assets. This jupyter notebook explores the properties of the 1/n portfolio family and how it can be used to construct portfolios.",
      url:
        "https://colab.research.google.com/drive/12HjGn4Iu95pqF9UGEk88PmmmgAOwHfh3?usp=sharing",
    },
    {
      name: "Understanding the 1/n Portfolio Family",
      description:
        "The 1/n portfolio family is a set of portfolios that allocate capital equally across a set of assets. This marimo application explores the properties of the 1/n portfolio family and how it can be used to construct portfolios.",
      url: "https://www.cvxgrp.org/simulator/marimo/Balanced.html",
    },
  ]);

  const ReviewLabProjectSubmitHandler = () => {
    if (deliverables) {
      showNotification(
        "Your project has been marked for publishing.",
        "success"
      );
    } else {
      setActiveStep(4);
    }
  };

  const addFeedback = async () => {
    showNotification(
      "Your feedback has been saved and will be taken into consideration.",
      "success"
    );
  };

  return (
    <Grid container>
      {!deliverables && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Typography variant="h5">Review Lab</Typography>
            <Typography variant="body1">
              Review the generated code lab & application links for the project.
            </Typography>
          </Grid>
          <Divider />
        </>
      )}
      {deliverables && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Typography variant="h5">Final Deliverables</Typography>
            <Typography variant="body1">
              Review the final deliverables for the project.
            </Typography>
          </Grid>
          <Divider />
        </>
      )}

      <Grid item xs={12} sm={12}>
        {links.length > 0 && (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Lab Link</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {links.map((link, index) => (
                  <TableRow key={index}>
                    {" "}
                    {/* Using link.id instead of index */}
                    <TableCell className={classes.tableCell}>
                      <Tooltip title={link.name}>
                        <span>{link.name}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Tooltip title={link.description}>
                        <span>{link.description}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Tooltip title={link.url}>
                          <span>{link.url}</span>
                        </Tooltip>
                      </a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {deliverables ? "Ready for publishing" : "In Review"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      {!deliverables && (
        <>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" style={{ marginTop: "10px" }}>
              Feedback
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              Provide suggestions for the AI engine of QuCreate to incorporate
              the below suggestions.
            </Typography>
            <Typography
              variant="body1"
              style={{ marginBottom: "10px", fontWeight: "bold" }}
            >
              Note: The requested changes may not be guaranteed. You should
              review any changes prior to finalizing your design draft.
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
                        <Typography variant="body1" style={{ marginTop: "10px", marginBottom: "10px" }}>Regenerate the Lab links by providing your own suggestions.</Typography>
                    </Grid> */}
          {/* TextField for user input */}
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Card>
              <TextField
                multiline
                fullWidth
                rows={6}
                // Uncomment the following line to enable feedback state
                // onChange={(e) => setFeedback(e.target.value)}
              />
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            {/* Next Button */}
            <Button
              color="quCreateColor"
              onClick={ReviewLabProjectSubmitHandler}
              style={{
                float: "right",
              }}
              endIcon={<NavigateNextIcon />}
            >
              Generate Deliverables
            </Button>
            {/* Regenerate Links Button */}
            <Button
              color="success"
              style={{
                float: "right",
              }}
              startIcon={<SaveIcon />}
              // Uncomment the following line to enable the click handler
              onClick={addFeedback}
            >
              Save Feedback
            </Button>
          </Grid>
        </>
      )}
      {deliverables && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {/* Next Button */}
          <Button
            color="quCreateColor"
            onClick={ReviewLabProjectSubmitHandler}
            style={{
              float: "right",
            }}
            startIcon={<DoneAll />}
          >
            Publish
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
