import React, { useEffect, useState } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Heading from "components/Heading/Heading.js";
import {
  Dialog,
  CircularProgress,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Tabs,
  Tab,
  TableContainer,
  Box,
} from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import NotificationContext from "views/Components/Context.js";
import {
  CloudQueue,
  Create,
  Done,
  DoneAll,
  HourglassEmpty,
  RateReview,
} from "@material-ui/icons";

// import { useModuleID } from "./Data/customhook";
import { lectureStages, moduleStages } from "./Data/moduleStages";

import {
  fetchAllLecturesAPI,
  fetchAllWritingsAPI,
  addArtifactToCourse,
} from "views/APIs/APIUtility";
import { fetchAllLabsAPI } from "views/APIs/APIUtility";

const useStyles = makeStyles(styles);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);
const usedashboardStyle = makeStyles(dashboardStyle);

export default function ModulesDesign(props) {
  // const { setModuleID } = useModuleID();

  const dashboardClasses = usedashboardStyle();

  const [modulesLoading, setModulesLoading] = useState(true);

  const classes = useStyles();
  const [moduleModal, setModuleModal] = useState(false);
  const [additionalArtifacts, setAdditionalArtifacts] = useState([]);

  // const {projectID , projectVersion} = props..par;
  const [name, setName] = useState("");
  const [brief, setBrief] = useState("");
  const [project, setProject] = useState({});
  const { showNotification } = React.useContext(NotificationContext);
  const { path, url } = useRouteMatch();
  const { courseID } = props.match.params;
  const [course, setCourse] = useState({});

  useEffect(() => {
    fetch(process.env.REACT_APP_FASTAPI_URL + `/get_course/${courseID}`)
      .then((res) => res.json())
      .then((data) => {
        setCourse(data);
        const additional_artifacts = [];
        if (data.additional_artifacts) {
          data.additional_artifacts.forEach((artifact) => {
            if ("lecture_name" in artifact) {
              additional_artifacts.push({
                artifact_id: artifact._id,
                artifact_name: artifact.lecture_name,
                artifact_description: artifact.lecture_description,
                artifact_image: artifact.lecture_image,
                artifact_type: "Lecture",
              });
            } else if ("writing_name" in artifact) {
              additional_artifacts.push({
                artifact_id: artifact._id,
                artifact_name: artifact.writing_name,
                artifact_description: artifact.writing_description,
                artifact_image: artifact.writing_image,
                artifact_type: "Writing",
              });
            } else if ("lab_name" in artifact) {
              additional_artifacts.push({
                artifact_id: artifact._id,
                artifact_name: artifact.lab_name,
                artifact_description: artifact.lab_description,
                artifact_image: artifact.lab_image,
                artifact_type: "Lab",
              });
            } else {
              console.log("Unknown artifact type");
            }
          });

          setAdditionalArtifacts(additional_artifacts);
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("Error fetching course data", "danger");
      })
      .finally(() => {
        setModulesLoading(false);
      });
  }, [courseID]);

  const handleClose = () => {
    setModuleModal(false);
    setName("");
    setBrief("");
  };

  const statusIcons = [
    <Create />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <RateReview />,
    <CloudQueue />,
    <DoneAll />,
  ];

  const getStatusIcon = (status) => {
    const index = moduleStages.indexOf(status);
    return statusIcons[index];
  };

  const [submitModuleLoading, setSubmitModuleLoading] = useState(false);

  const addModule = () => {
    setSubmitModuleLoading(true);
    const formData = new FormData();

    formData.append("course_id", courseID);
    formData.append("module_name", name);
    formData.append("module_description", brief);

    console.log("form data", formData);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    fetch(process.env.REACT_APP_FASTAPI_URL + "/add_module", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("returned data", data);
        try {
          setCourse((prevCourse) => {
            return {
              ...prevCourse,
              modules: [
                ...prevCourse.modules,
                data.modules[data.modules.length - 1],
              ],
            };
          });
          showNotification("Module added successfully", "success");
          handleClose();
        } catch (err) {
          showNotification("Error adding module", "danger");
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("Error adding module", "danger");
      })
      .finally(() => {
        setSubmitModuleLoading(false);
      });
  };

  const [importModal, setImportModal] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [artifacts, setArtifacts] = useState([]);

  // Get a list of unique artifact types
  const artifactTypes = [
    "All",
    ...new Set(artifacts.map((artifact) => artifact.artifact_type)),
  ];
  const [selectedTab, setSelectedTab] = useState("All");

  // Filter artifacts based on selected tab
  const filteredArtifacts =
    selectedTab === "All"
      ? artifacts
      : artifacts.filter((artifact) => artifact.artifact_type === selectedTab);

  const importArtifact = async () => {
    const formData = new FormData();
    formData.append("course_id", courseID);
    formData.append("artifact_id", selectedArtifact);
    const artifact_type = filteredArtifacts.find(
      (artifact) => artifact.artifact_id === selectedArtifact
    ).artifact_type;
    formData.append("artifact_type", artifact_type);
    const artifact = await addArtifactToCourse(formData);
    window.location.reload();
  };

  const identifiers = {
    research_report: "Research Report",
    white_paper: "White Paper",
    project_plan: "Project Plan",
    blog: "Blog",
    news_letter: "Newsletter",
    case_study: "Case Study",
    key_insights: "Key Insights",
    handout: "Handout",
    lecture: "Lecture",
    lab: "Lab",
  };

  const getArtifacts = async () => {
    const writings = await fetchAllWritingsAPI();
    const lectures = await fetchAllLecturesAPI();
    const labs = await fetchAllLabsAPI();
    const artifacts = [];
    writings.forEach((writing) => {
      artifacts.push({
        artifact_id: writing._id,
        artifact_name: writing.writing_name,
        artifact_type: identifiers[writing.identifier] || "Writing",
        artifact_description: writing.writing_description,
      });
    });
    lectures.forEach((lecture) => {
      artifacts.push({
        artifact_id: lecture._id,
        artifact_name: lecture.lecture_name,
        artifact_type: "Lecture",
        artifact_description: lecture.lecture_description,
      });
    });
    labs.forEach((lab) => {
      artifacts.push({
        artifact_id: lab._id,
        artifact_name: lab.lab_name,
        artifact_type: "Lab",
        artifact_description: lab.lab_description,
      });
    });
    setArtifacts(artifacts);
  };

  useEffect(() => {
    getArtifacts();
  }, []);

  if (modulesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Loading Modules...
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      {/* Dialog for importing artifact */}
      <Dialog
        open={importModal}
        onClose={() => setImportModal(false)}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0px" }}>
          <div style={{ fontSize: "30px" }}>Import Artifact</div>
          <Divider />
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            You can import an artifact from the list below to add to the course.
          </Typography>
        </DialogTitle>
        {importLoading ? (
          <>
            <DialogContent
              style={{
                display: "flex",
              }}
            >
              <CircularProgress size={20} /> &nbsp;
              <Typography variant="body1">Importing artifact...</Typography>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              {/* Tabs for artifact types */}
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
              >
                {artifactTypes.map((type) => (
                  <Tab key={type} label={type} value={type} />
                ))}
              </Tabs>

              {/* Table for artifacts */}
              <Table
                size="small"
                style={{
                  marginTop: "10px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Artifact Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Artifact Description</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Select</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredArtifacts.map((artifact, index) => (
                    <TableRow key={index}>
                      <TableCell>{artifact.artifact_name}</TableCell>
                      <TableCell>{artifact.artifact_description}</TableCell>
                      <TableCell>
                        <input
                          type="radio"
                          name="artifact"
                          value={artifact.artifact_id}
                          onChange={(event) =>
                            setSelectedArtifact(event.target.value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setImportModal(false)}
                color="quSandBoxColor"
                disabled={importLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={importArtifact}
                color="quSandBoxColor"
                disabled={importLoading || !selectedArtifact}
              >
                Import
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Dialog for adding new module */}
      <Dialog
        open={moduleModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0px" }}>
          <div style={{ fontSize: "30px" }}>Add a New Module</div>
        </DialogTitle>
        {submitModuleLoading ? (
          <>
            <DialogContent
              style={{
                display: "flex",
              }}
            >
              <CircularProgress size={20} /> &nbsp;
              <Typography variant="body1">Adding module...</Typography>
            </DialogContent>
          </>
        ) : (
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              onChange={(event) => {
                setName(event.target.value);
              }}
              value={name}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="brief"
              label="Brief Description"
              type="text"
              onChange={(event) => {
                setBrief(event.target.value);
              }}
              value={brief}
              fullWidth
              multiline={true}
              required
              rows={3}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={handleClose}
            color="quSandBoxColor"
            disabled={submitModuleLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={addModule}
            color="quSandBoxColor"
            disabled={submitModuleLoading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {alert}
      <Switch>
        <Route exact path={path}>
          <div>
            <Heading
              title={course.course_name}
              textAlign="center"
              category={course.course_description}
            />
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} style={{ textAlign: "center" }}>
              <Button
                color="info"
                onClick={() => setImportModal(true)}
                size="lg"
                className={classes.buttons}
              >
                <Icon>import_export</Icon> &nbsp; Import Artifact
              </Button>
              <Button
                color="info"
                onClick={() => setModuleModal(true)}
                size="lg"
                className={classes.buttons}
              >
                <Icon>add</Icon> &nbsp; Add Module
              </Button>
              <Button
                color="info"
                size="lg"
                className={classes.buttons}
                disabled={
                  course.modules.some(
                    (module) => module.status !== "Published"
                  ) || !(course.status === "Ready for Publishing")
                }
                helpertext="All modules must be published before the project can be published."
              >
                Publish Course
              </Button>
              <a
                href="https://quskillbridge.qusandbox.com/course-content/672529cbe9c03821a5698c27"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  color="info"
                  size="lg"
                  className={classes.buttons}
                  disabled={course.status !== "Published"}
                  helpertext="All modules must be published before the project can be published."
                >
                  Preview Course
                </Button>
              </a>
            </GridItem>
          </GridContainer>

          <GridContainer xs={{ border: 5 }}>
            {course.modules.length === 0 &&
              additionalArtifacts.length === 0 ? (
              <h3>No modules found. Add a module to start editing.</h3>
            ) : null}
            {course.modules.map((module, index) => (
              <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                <Link
                  to={{
                    pathname: `${url}/module/${module.module_id}`,
                    state: {
                      project: project,
                    },
                  }}
                // onClick={() => setModuleID(module.module_id)} // Set the id on click
                >
                  <Card style={{ minHeight: "245px", maxHeight: "245px" }}>
                    <CardHeader stats icon>
                      <Tooltip
                        title={module.module_name}
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                      >
                        <div>
                          <CardIcon color="quSandBox">
                            {module.module_name.substring(0, 30) +
                              (module.module_name.length > 30 ? "..." : "")}
                          </CardIcon>
                        </div>
                      </Tooltip>
                      <h3 className={classes.cardTitle}>
                        <small>
                          <img
                            src={course.course_image}
                            alt="Course Image"
                            className={classes.thumbnail}
                          />
                        </small>
                      </h3>
                    </CardHeader>
                    <CardBody
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    />
                    <CardFooter>
                      <Tooltip
                        title={module.module_description}
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                      >
                        <h5
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          {module.module_description}
                        </h5>
                      </Tooltip>
                    </CardFooter>
                    <CardFooter stats style={{}}>
                      <div>
                        {getStatusIcon(module.status)} {module.status}
                      </div>
                    </CardFooter>
                  </Card>
                </Link>
              </GridItem>
            ))}
            {additionalArtifacts.map((artifact, index) => (
              <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                <Link
                  to={{
                    pathname:
                      artifact.artifact_type === "Lecture"
                        ? `/admin/lecturedesign/lecture/${artifact.artifact_id}`
                        : artifact.artifact_type === "Lab"
                          ? `/admin/labdesign/lab/${artifact.artifact_id}`
                          : `/admin/writings/${artifact.artifact_id}`,
                    state: {
                      project: project,
                    },
                  }}
                >
                  <Card style={{ minHeight: "245px", maxHeight: "245px" }}>
                    <CardHeader stats icon>
                      <Tooltip
                        title={artifact.artifact_name}
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                      >
                        <div>
                          <CardIcon color="quSandBox">
                            {artifact.artifact_name.substring(0, 30) +
                              (artifact.artifact_name.length > 30
                                ? "..."
                                : "")}
                          </CardIcon>
                        </div>
                      </Tooltip>
                      <h3 className={classes.cardTitle}>
                        <small>
                          <img
                            src={course.course_image}
                            alt="Course Image"
                            className={classes.thumbnail}
                          />
                        </small>
                      </h3>
                    </CardHeader>
                    <CardBody
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    />
                    <CardFooter>
                      <Tooltip
                        title={artifact.artifact_description}
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                      >
                        <h5
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          {artifact.artifact_description}
                        </h5>
                      </Tooltip>
                    </CardFooter>
                    <CardFooter stats style={{}}>
                      <div>
                        <HourglassEmpty /> Pending
                      </div>
                    </CardFooter>
                  </Card>
                </Link>
              </GridItem>
            ))}
          </GridContainer>
        </Route>
      </Switch>
    </div>
  );
}
