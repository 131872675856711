import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  CircularProgress,
  makeStyles,
  Divider,
  Grid,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import {
  Description,
  Edit,
  Note,
  ViewAgenda,
  DoneAll,
} from "@material-ui/icons";
import NotificationContext from "views/Components/Context.js";
import Resources from "../Resources";
import LabUseCase from "./LabUseCase";
import ReviewLabProject from "../ReviewLabProject";
import { fetchLabAPI } from "views/APIs/APIUtility";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  stepIconRoot: {
    "&$completed": {
      color: theme.palette.success.main,
    },
    "&$active": {
      color: theme.palette.primary.main,
    },
    "&$error": {
      color: theme.palette.error.main,
    },
  },
  stepperRoot: {
    "& .MuiStepIcon-root": {
      color: "#435966", // Gray color for all steps (non-completed)
    },
    "& .MuiStepLabel-root.Mui-completed .MuiStepIcon-root": {
      color: "#435966", // Color for completed step icons (greenish color)
    },
    "& .MuiStepLabel-root.Mui-active .MuiStepIcon-root": {
      color: "#435966", // Active step icon color (greenish color)
    },
    "& .MuiStepLabel-root": {
      color: "#D3D3D3", // Gray color for all step labels (non-completed)
    },
    "& .MuiStepLabel-root.Mui-active": {
      color: "#435966", // Active step label color (greenish color)
    },
    "& .MuiStepLabel-root.Mui-completed": {
      color: "#435966", // Completed step label color (greenish color)
    },
  },
  stepLabel: {
    color: theme.palette.text.primary,
  },
  progressBar: {
    margin: theme.spacing(2, 0),
  },
  active: {},
  completed: {},
  error: {},
  header: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
}));

const lab_steps = [
  { label: "Upload Resources", icon: <Description />, lockedMessage: "" },
  {
    label: "Business Use Case",
    icon: <ViewAgenda />,
    lockedMessage: "Please submit the resources to move on to the next step.",
  },
  {
    label: "Technical Specification",
    icon: <Edit />,
    lockedMessage:
      "Please review the business use case to move on to the next step.",
  },
  {
    label: "Review Project",
    icon: <Note />,
    lockedMessage:
      "Please review the technical specifications to move on to the next step.",
  },
  {
    label: "Deliverables",
    icon: <DoneAll />,
    lockedMessage: "Please review the project to move on to the next step.",
  },
];

function StepIcon(props) {
  const classes = useStyles();
  const { active, completed, icon } = props;

  return (
    <div
      className={`${classes.stepIconRoot} ${active ? classes.active : ""} ${completed ? classes.completed : ""
        }`}
    >
      {icon}
    </div>
  );
}

export default function LabsBoard(props) {
  const classes = useStyles();

  const stepMapping = {
    "In Design Phase": 0,
    "Business Use Case Review": 1,
    "Technical Specifications Review": 2,
    "Project Review": 3,
    "Deliverables Review": 4,
  };
  const [activeStep, setActiveStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([0]);
  const [boardLoading, setBoardLoading] = useState(false); // Initially in loading state
  const { showNotification } = React.useContext(NotificationContext);
  const [instructions, setInstructions] = useState("");

  const [loadingBusinessUseCase, setLoadingBusinessUseCase] = useState(false);
  const [loadingTechnicalUseCase, setLoadingTechnicalUseCase] = useState(false);
  const [labName, setLabName] = useState("");

  const { labID } = props.match.params;

  useEffect(() => {
    // Fetch lab details
    async function fetchLabDetails() {
      try {
        setBoardLoading(true);
        const data = await fetchLabAPI(labID);
        const step = data.status;
        setActiveStep(stepMapping[step]);
        setLabName(data.lab_name);
        setBoardLoading(false);
      } catch (error) {
        console.error("Error in fetchLabDetails:", error);
        showNotification("Error fetching lab details", "danger");
      }
      finally {
        setBoardLoading(false);
      }
    }
    fetchLabDetails();
  }, [labID]);

  const handleStepClick = (index) => {
    if (visitedSteps.includes(index)) {
      setActiveStep(index);
    } else {
      const message = lab_steps[index].lockedMessage || "This step is locked.";
      showNotification(message, "danger");
    }
  };

  useEffect(() => {
    if (!visitedSteps.includes(activeStep)) {
      // set visited steps to all steps before the active step and the active step
      let steps = [];
      for (let i = 0; i <= activeStep; i++) {
        steps.push(i);
      }
      setVisitedSteps(steps);
    }
  }, [activeStep, visitedSteps]);

  const renderContent = () => {
    switch (lab_steps[activeStep].label) {
      case "Upload Resources":
        return (
          <Resources
            projectID={labID}
            setActiveStep={setActiveStep}
            projectType="lab_designer"
            setLoadingBusinessUseCase={setLoadingBusinessUseCase}
          // setBoardLoading={setBoardLoading}
          />
        );
      case "Business Use Case":
        return (
          <LabUseCase
            projectID={labID}
            projectType="lab_designer"
            setActiveStep={setActiveStep}
            useCase="business"
            setLoadingTechnicalUseCase={setLoadingTechnicalUseCase}
          />
        );
      case "Technical Specification":
        return (
          <LabUseCase
            projectID={labID}
            projectType="lab_designer"
            setActiveStep={setActiveStep}
            useCase="technical"
          />
        );
      case "Review Project":
        return (
          <ReviewLabProject
            projectID={labID}
            projectType="lab_designer"
            setActiveStep={setActiveStep}
          />
        );
      case "Deliverables":
        return (
          <ReviewLabProject
            projectID={labID}
            projectType="lab_designer"
            setActiveStep={setActiveStep}
            deliverables={true}
          />
        );
      default:
        return null;
    }
  };

  if (boardLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Loading Lab Board...
        </Typography>
      </Box>
    );
  }

  if (loadingBusinessUseCase) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Generating Business Use Case...
        </Typography>
      </Box>
    );
  }

  if (loadingTechnicalUseCase) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Generating Technical Specifications...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* <Box sx={{ display: "flex", width: "100%", padding: 0, justifyContent: "flex-start" }}>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {lab_steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                StepIconComponent={(props) => <StepIcon {...props} icon={step.icon} />}
                                onClick={() => handleStepClick(index)}
                                classes={{ label: classes.stepLabel }}
                            >
                                {step.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box> */}

      {/* AppBar with Navigation Buttons and Title */}
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {/* Title */}
            <Typography
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bold",
                flexShrink: 0,
                maxWidth: "45%", // Ensures it does not exceed the container width
                wordBreak: "break-word", // Allows long words to break and wrap to the next line if necessary
                overflow: "hidden", // Hides any overflow if text is too long
                whiteSpace: "normal", // Ensures the text wraps
              }}
            >
              {labName}
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              size="small"
              className={classes.stepperRoot}
              // connector={<QontoConnector />}
              style={{ flexGrow: 1 }} // Stepper will take up the remaining space
            >
              {lab_steps.map((step, index) => (
                <Step
                  key={step.label}
                  disabled={index > activeStep}
                  onClick={() => handleStepClick(index)}
                >
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Toolbar>
      </AppBar>

      <Box mt={3}>{renderContent()}</Box>
    </Box>
  );
}
