import { Card, Grid, Icon, Typography, TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import React, { useState, useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI, fetchLectureAPI } from "views/APIs/APIUtility";

export default function LectureOutlineReview(props) {
    console.log("props: ModuleInformationContentReview ", props.moduleInformation);
    const { showNotification } = React.useContext(NotificationContext);
    const [moduleInformation, setModuleInformation] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const setActiveStep = props.setActiveStep;


    useEffect(() => {
        const setSlidesandFetchData = async () => {
            try {
                const data = await fetchLectureAPI(props.projectID);
                const lecture_outline = data.outline;
                setModuleInformation(lecture_outline);
            } catch (error) {
                console.error("Error fetching lecture data", error);
            } finally {
                setIsLoading(false);
            }
        };

        setSlidesandFetchData();
    }, [props.projectID]); // Add dependencies as needed


    const fetchFile = async (fileUrl) => {
        if (!fileUrl) {
            showNotification("Invalid file URL", "danger");
            return;
        }

        const formData = new FormData();
        formData.append("url", fileUrl);

        fetch(process.env.REACT_APP_FASTAPI_URL + "/fetch_note", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Data: ", data);
                setModuleInformation(data.content);
            })
            .catch((error) => {
                console.error("Error: ", error);
                showNotification("Error fetching file", "danger");
            }
            )
    };



    const handleModuleInformation = (content) => setModuleInformation(content);

    const handleSaveChanges = () => {
        let resource_description = moduleInformation.resource_description;
        resource_description = resource_description + "###NOTE###" + moduleInformation;

        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("resource_type", "Note");
        formData.append("resource_description", resource_description);
        formData.append("resource_link", moduleInformation.resource_link);
        formData.append("resource_id", moduleInformation.resource_id);
        formData.append("resource_name", moduleInformation.resource_name);
        formData.append("course_design_step", props.course_design_step);

        try {
            replaceResourceAPI(formData);
            showNotification("Changes saved successfully", "success");
        }
        catch (error) {
            console.error("Error in handleUpdateNote:", error);
            showNotification("Error saving changes", "danger");
        }


    }

    const handleGenerateSlides = () => {
        setActiveStep(2);
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" style={{ marginTop: "20px" }}>Outline Review</Typography>
                    <Typography variant="body1">Review the outline for the slides</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div data-color-mode="light" style={{
                        marginTop: "10px",
                    }}>
                        <MDEditor
                            value={moduleInformation}
                            onChange={handleModuleInformation}
                            height={500}
                        />

                    </div>
                </Grid>



            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" style={{ marginTop: "20px" }}>Feedback</Typography>
                    <Typography variant="body1">Regenerate the lecture outline by providing your own suggestions.</Typography>
                </Grid>
                {/* TextField for user input */}
                <Grid item xs={12} sm={12}>
                    <Card>

                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                        // onChange={(e) => setFeedback(e.target.value)} // Update feedback state when user types
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={4} style={{ marginTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {/* Exit Review button on the left */}
                        <Grid item xs={12} sm={3} style={{ textAlign: "left" }}>
                            {/* <Button color="danger">
                                <Icon>close</Icon> &nbsp; Exit Review
                            </Button> */}
                            <Button color="success" onClick={handleSaveChanges} style={{ marginRight: "10px" }}>
                                <Icon>autorenew</Icon> &nbsp; Regenerate Outline
                            </Button>
                        </Grid>

                        {/* 3 buttons aligned to the right */}
                        <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>

                            <Button color="success" onClick={handleSaveChanges} style={{ marginRight: "10px" }}>
                                <Icon>save</Icon> &nbsp; Save Changes
                            </Button>
                            <Button color="info"
                                onClick={handleGenerateSlides}
                            >
                                <Icon>check</Icon> &nbsp; Generate Slides
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
