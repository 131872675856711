import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
    Grid,
    Box,
    TextField,
    Typography,
    Divider,
    Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Add, Check } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import ImageIcon from '@material-ui/icons/Image';
import NotesIcon from '@material-ui/icons/Notes';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { Auth } from "aws-amplify";
import ResourceTiles from "components/Tiles/ResourceTiles.js";
import ModuleLinks from "./ModuleLinks";
import Notes from "./Notes";
import NotificationContext from "views/Components/Context.js";
import ResourceModal from "./ResourceModal";
import Button from "components/CustomButtons/Button.js"; // Custom button component

import ImageGallery from "./ImageGallery";
import FileGallery from "./FileGallery";
import {
    fetchCourseAPI,
    fetchLectureAPI,
    fetchLabAPI,
    fetchContentAPI,
    replaceResourceAPI,
    replaceLectureResourceAPI,
    replaceLabResourceAPI,
    submitModuleForContentGeneration,
    deleteLectureResourceAPI,
    deleteResourceAPI,
    deleteLabResourceAPI,
} from "views/APIs/APIUtility";
import FileUploadDialog from './FileUploadDialog'; // Import the dialog component
import TaskDefinitionModal from "views/Components/TaskDefinitionModal";
import SlideDeckModalBox from "../Components/SlideDeckModalBox";
import LabSpecificationsDialog from "./LabSpecificationDialog";
import LabRequirementForm from "./Lab/LabRequirementForm";

const useStyles = makeStyles((theme) => ({
    ...styles,
    dropzone: {
        width: '100%',
        height: '80%',
        border: '2px dashed #3f51b5',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        width: '90%',
        maxWidth: '800px',
        height: '70%',
        padding: theme.spacing(3),
        borderRadius: '12px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: theme.shadows[10],
        display: 'flex',
        flexDirection: 'column',
    },
    cardOption: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: theme.shadows[3],
        borderRadius: '8px',
        transition: 'transform 0.2s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    submitButton: {
        marginTop: '16px'
    },
    resourceTileBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(2), // Space between the tiles
        justifyContent: 'space-between', // To spread the tiles evenly
        marginBottom: theme.spacing(3), // Additional space between rows
    },

}));


export default function Resources({ projectID, moduleID, setActiveStep, projectType, setBoardLoading, setLoadingBusinessUseCase }) {
    const history = useHistory();
    const path = useRouteMatch().path; // Modify with the correct path
    const classes = useStyles();

    const [status, setStatus] = useState("Not Submitted");
    const [user, setUser] = useState();
    const [activeTile, setActiveTile] = useState(null);
    const [project, setProject] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [isLinkUpload, setIsLinkUpload] = useState(false);
    const [currentLink, setCurrentLink] = useState({ name: "", url: "" });
    const [nameError, setNameError] = useState(false);
    const { showNotification } = React.useContext(NotificationContext);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [taskDefinition, setTaskDefinition] = useState("");
    const [replaceResourceModal, setReplaceResourceModal] = useState(false);
    const [replaceResourceIndex, setReplaceResourceIndex] = useState(null);
    const [newResource, setNewResource] = useState({ name: '', description: '', file: null });
    const [replace, setReplace] = useState(false);
    const [replaceType, setReplaceType] = useState(null);

    const [resources, setResources] = useState([]);

    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [links, setLinks] = useState([]);
    const [notes, setNotes] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true);

    {/* Lecture Designer Constants*/ }
    const [slideDeck, setSlideDeck] = useState(null);
    const [slideDeckModal, setSlideDeckModal] = useState(false);

    {/* Lecture Designer Constants Functions*/ }
    const handleSlideDeckModalClose = () => {
        setSlideDeckModal(false);
    };



    const handleSlideDeckModalSubmit = (generationOption) => {

        handleSlideDeckModalClose();
        if (slideDeck) {
            setActiveStep(3);
        }
        else {
            setActiveStep(1);
        }
    };



    const setResourcesFromProject = async (data) => {
        try {

            let resources = [];
            if (projectType === "lecture_designer") {
                resources = data.raw_resources || [];
            }
            else if (projectType === "lab_designer") {
                resources = data.raw_resources || [];
            }
            else {
                {/*Project Type is Course Designer*/ }
                const module = data.modules?.find((m) => m.module_id === moduleID);
                if (!module) throw new Error(`Module with ID ${moduleID} not found`);

                resources = module.raw_resources || [];
                if (module.instructions) setTaskDefinition(module.instructions);
            }

            // Return early if no resources are found
            if (!resources.length) {
                console.warn("No resources found in the project data");
                // showNotification("No resources found in the project data", "warning");
                return; // Exit the function
            }

            // Helper function to map resources by type
            const mapResources = (type) =>
                resources
                    .filter((res) => res.resource_type === type)
                    .map((res) => ({
                        id: res.resource_id,
                        name: res.resource_name,
                        description: res.resource_description,
                        url: res.resource_link,
                        type: res.resource_type,
                    }));

            // Separate resources into categories
            setFiles(mapResources("File"));
            setImages(mapResources("Image"));
            setLinks(mapResources("Link"));


            const updatedNotes = await Promise.all(
                resources
                    .filter((res) => res.resource_type === "Note")
                    .map(async (note) => {
                        try {
                            const formData = new FormData();
                            formData.append("url", note.resource_link);
                            const data = await fetchContentAPI(formData);

                            // Map resource_name and resource_description to name and description
                            return {
                                id: note.resource_id,
                                name: note.resource_name, // Map resource_name to name
                                description: note.resource_description, // Map resource_description to description
                                link: note.resource_link,
                                type: note.resource_type,
                                content: data.content, // Fetched content
                            };
                        } catch (error) {
                            console.error(`Failed to fetch content for note ID ${note.resource_id}:`, error);

                            // Return with mapped fields and error message
                            return {
                                id: note.resource_id,
                                name: note.resource_name,
                                description: note.resource_description,
                                link: note.resource_link,
                                type: note.resource_type,
                                content: "Error loading content", // Default error content
                            };
                        }
                    })
            );

            setNotes(updatedNotes);

        } catch (error) {
            console.error("Error in setResourcesFromProject:", error.message);
            showNotification("Failed to process resources from project data", "danger");
        }
    };


    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const data = await fetchCourseAPI(projectID);
                setResourcesFromProject(data);
            } catch (error) {
                showNotification("Error fetching course data", "danger");
            } finally {
                setModulesLoading(false);
            }
        };

        const fetchLectureData = async () => {
            try {
                const data = await fetchLectureAPI(projectID);
                setResourcesFromProject(data);
            } catch (error) {
                showNotification("Error fetching lecture data", "danger");
            } finally {
                // setBoardLoading(false);
                setModulesLoading(false);
            }
        };

        const fetchLabData = async () => {
            try {
                const data = await fetchLabAPI(projectID);
                setResourcesFromProject(data);
            } catch (error) {
                showNotification("Error fetching lecture data", "danger");
            } finally {
                setModulesLoading(false);
            }
        };

        if (projectType === "lecture_designer") {
            fetchLectureData();
        }
        else if (projectType === "lab_designer") {
            fetchLabData();
        }
        else {
            fetchCourseData();
        }
        // setBoardLoading(false);

    }, [projectID]);


    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const handleNavigation = (type) => {
        setActiveTile(type);
    };

    const submitHandler = async () => {
        if (!taskDefinition.trim()) {
            showNotification("Task definition cannot be empty", "danger");
            return;
        }
        const formData = new FormData();
        formData.append("instructions", taskDefinition);
        formData.append("course_id", projectID);
        formData.append("module_id", moduleID);

        try {
            const data = await submitModuleForContentGeneration(formData);
            showNotification("Your job is submitted & review will be unlocked as soon as your request is processed.", "quSandBoxColor");
            window.location.reload();
        }
        catch (error) {
            showNotification("Error submitting module", "danger");
        }
        finally {
        }
    };

    const lectureSubmitHandler = async () => {
        setSlideDeckModal(true);
    };

    const handleTemplateHandler = () => {
        setOpenTemplateModal(true);
    };

    const handleCloseTemplateModal = () => {
        setOpenTemplateModal(false);
    };


    const handleSaveLink = () => {
        if (!currentLink.name.trim()) {
            setNameError(true);
            return;
        }
        setCurrentLink({ name: "", url: "" });
        setOpenModal(false);
        setIsLinkUpload(false);
        setNameError(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentLink({ name: "", url: "" });
        setNameError(false);
    };


    const handleDeleteResource = async (resource) => {
        const resource_type = resource.type;

        const formData = new FormData();

        try {
            formData.append("resource_id", resource.id);
            // Add project-specific data
            if (projectType === "lecture_designer") {
                formData.append("lecture_id", projectID);
                formData.append("lecture_design_step", 0);
                await deleteLectureResourceAPI(formData);
            }
            else if (projectType === "lab_designer") {
                formData.append("lab_id", projectID);
                formData.append("lab_design_step", 0);
                await deleteLabResourceAPI(formData);
            }
            else if (projectType === "course_designer") {
                formData.append("course_id", projectID);
                formData.append("module_id", moduleID);
                formData.append("course_design_step", 0);
                await deleteResourceAPI(formData);
            }

            // Notify user of success
            showNotification("Resource deleted successfully", "success");

            // Update state based on the resource type
            if (resource_type === "File") {
                setFiles((prevFiles) => prevFiles.filter((f) => f.name !== resource.name));
            } else if (resource_type === "Image") {
                setImages((prevImages) => prevImages.filter((f) => f.name !== resource.name));
            } else if (resource_type === "Link") {
                setLinks((prevLinks) => prevLinks.filter((f) => f.name !== resource.name));
            } else if (resource_type === "Note") {
                setNotes((prevNotes) => prevNotes.filter((f) => f.name !== resource.name));
            }
        } catch (error) {
            console.error("Failed to delete resource:", error);
            showNotification("Failed to delete resource", "danger");
        } finally {
            // Optionally handle any cleanup or post-action logic here
        }
    };

    const handleUpdateResource = async (resource, index) => {
        const resource_type = resource.type;

        const formData = new FormData();

        try {
            formData.append("resource_id", resource.id);
            formData.append("resource_name", resource.name);
            formData.append("resource_type", resource.type);
            if (resource_type === "Note") {
                formData.append("resource_description", resource.description + "###NOTE###" + resource.url);
            }
            if (resource_type === "Link") {
                formData.append("resource_description", resource.description + "###LINK###" + resource.url);
            }

            // Add project-specific data
            if (projectType === "lecture_designer") {
                formData.append("lecture_id", projectID);
                formData.append("lecture_design_step", 0);
                await replaceLectureResourceAPI(formData);
            }
            else if (projectType === "lab_designer") {
                formData.append("lab_id", projectID);
                formData.append("lab_design_step", 0);
                await replaceLabResourceAPI(formData);
            }
            else if (projectType === "course_designer") {
                formData.append("course_id", projectID);
                formData.append("module_id", moduleID);
                formData.append("course_design_step", 0);
                await replaceResourceAPI(formData);
            }

            // Notify user of success
            showNotification("Resource Updated successfully", "success");

            // Update state based on the resource type and index
            if (resource_type === "File") {
                setFiles((prevFiles) =>
                    prevFiles.map((file, idx) => (idx === index ? { ...file, ...resource } : file))
                );
            } else if (resource_type === "Image") {
                setImages((prevImages) =>
                    prevImages.map((image, idx) => (idx === index ? { ...image, ...resource } : image))
                );
            } else if (resource_type === "Link") {
                setLinks((prevLinks) =>
                    prevLinks.map((link, idx) => (idx === index ? { ...link, ...resource } : link))
                );
            } else if (resource_type === "Note") {
                setNotes((prevNotes) =>
                    prevNotes.map((note, idx) => (idx === index ? { ...note, ...resource } : note))
                );
            }
        } catch (error) {
            console.error("Failed to Update resource:", error);
            showNotification("Failed to Update resource", "danger");
        } finally {
        }
    };



    const handleReplace = async (resource, index) => {
        setReplaceResourceIndex(index);
        setNewResource({ name: resource.name, description: resource.description, file: null });
        setReplace(true);
        setReplaceType(resource.type);
        setReplaceResourceModal(true);
    };

    const handleReplaceSave = () => {
        setReplaceResourceModal(false);
    };

    const handleReplaceResource = async () => {
        try {
            setReplace(false); // Close any UI related to replacing

            // Determine the correct resource list
            const resourceList = replaceType === "File" ? files : images;

            if (!newResource) {
                throw new Error("No new resource provided.");
            }

            // Initialize FormData for the API request
            const formData = new FormData();
            const resource = resourceList[replaceResourceIndex];

            if (!resource) {
                throw new Error("Selected resource not found.");
            }

            // Add required fields to FormData
            formData.append("resource_id", resource.id);
            formData.append("resource_name", newResource.name || resource.name);
            formData.append("resource_type", resource.type);
            formData.append("resource_description", newResource.description || resource.description);

            // Append the new file if it exists
            if (newResource.file) {
                formData.append("resource_file", newResource.file);
            }

            // Add additional fields based on project type
            if (projectType === "lecture_designer") {
                formData.append("lecture_id", projectID);
                formData.append("lecture_design_step", 0); // Adjust this value as needed
                await replaceLectureResourceAPI(formData);
            }
            else if (projectType === "lab_designer") {
                formData.append("lab_id", projectID);
                formData.append("lab_design_step", 0); // Adjust this value as needed
                await replaceLabResourceAPI(formData);
            }
            else if (projectType === "course_designer") {
                if (!moduleID) {
                    throw new Error("Module ID is required for course designer.");
                }
                formData.append("course_id", projectID);
                formData.append("module_id", moduleID);
                formData.append("course_design_step", 0); // Adjust this value as needed
                await replaceResourceAPI(formData);
            } else {
                throw new Error("Invalid project type.");
            }

            // Update the local state instead of reloading the page
            const updatedResources = resourceList.map((item, index) =>
                index === replaceResourceIndex ? { ...item, ...newResource } : item
            );


            if (replaceType === "File") {
                setFiles(updatedResources);
            } else if (replaceType === "Image") {
                setImages(updatedResources);
            }

            showNotification("Resource replaced successfully", "success");
            window.location.reload()
        } catch (error) {
            console.error("Error in replacing resource:", error.message || error);
            showNotification(`Failed to replace resource: ${error.message || "Unknown error"}`, "danger");
        } finally {
            handleReplaceSave(); // Ensure cleanup or modal close happens
        }
    };


    const renderContent = () => {
        switch (activeTile) {
            case "Files":
                return <FileGallery files={files} handleDeleteResource={handleDeleteResource} handleReplace={handleReplace} />;
            case "Links":
                return <ModuleLinks links={links} setLinks={setLinks} handleDeleteResource={handleDeleteResource} handleUpdateResource={handleUpdateResource} />;
            case "Notes":
                return <Notes courseID={projectID} moduleID={moduleID} notes={notes} setNotes={setNotes} handleDeleteResource={handleDeleteResource} handleUpdateResource={handleUpdateResource} />;
            case "Images":
                return <ImageGallery images={images} handleDeleteResource={handleDeleteResource} handleReplace={handleReplace} />;
            default:
                return null;
        }
    };

    const [labCheckBox, setLabCheckBox] = useState(false);

    const handleCheckboxChange = (event) => {
        setLabCheckBox(event.target.checked);
    }

    const handleModuleBack = () => {
        window.history.back();
    }

    const [labSpecificationGenerated, setLabSpecificationGenerated] = useState(false);
    const filesDescription = {
        "lab_designer": "Upload the files or datasets or documents",
        "lecture_designer": "Upload the documents ",
        "course_designer": "Upload the documents"
    }
    const imagesDescription = {
        "lab_designer": "Upload any images/screenshots to be used",
        "lecture_designer": "Upload any images to be used",
        "course_designer": "Upload any images to be used"
    }
    const linksDescription = {
        "lab_designer": "Add links to external resources, github repositories, etc.",
        "lecture_designer": "Add links to external resources, webpages, etc.",
        "course_designer": "Add links to external resources, webpages, etc."
    }
    const notesDescription = {
        "lab_designer": "Add notes for the lab",
        "lecture_designer": "Add transcript for the lecture",
        "course_designer": "Add notes for the module"
    }

    return (
        <Box mt={2}>

            <Grid container>
                <Grid item xs={12}>
                    <ResourceTiles
                        setOpenModal={setOpenModal}
                        active={activeTile}
                        color="warning"
                        alignCenter
                        tabs={[
                            {
                                heading: "Files",
                                icon: FileCopyIcon,
                                description: filesDescription[projectType],
                                onClick: () => handleNavigation('Files'),
                            },
                            {
                                heading: "Images",
                                icon: ImageIcon,
                                description: imagesDescription[projectType],
                                onClick: () => handleNavigation('Images'),
                            },
                            {
                                heading: "Links",
                                icon: LinkIcon,
                                description: linksDescription[projectType],
                                onClick: () => handleNavigation('Links'),
                            },
                            projectType === "lecture_designer"
                                ? {
                                    heading: "Transcript",
                                    icon: NotesIcon,
                                    description: "Add transcript for lecture",
                                    onClick: () => handleNavigation('Notes'),
                                }
                                : {
                                    heading: "Notes",
                                    icon: NotesIcon,
                                    description: notesDescription[projectType],
                                    onClick: () => handleNavigation('Notes'),
                                },
                        ]}
                    />
                </Grid>
            </Grid>

            {renderContent()}

            <Box marginTop={2}>
                <Divider light />
            </Box>


            {/* Course Designer */}
            {projectType === "course_designer" && (
                <Grid container spacing={2} alignItems="flex-start">
                    {/* Task Definition Section */}
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                        {/* Task Definition Label */}
                        <Button
                            size="small"
                            color="transparent"
                            className={classes.submitButton}
                            disabled
                            style={{
                                fontWeight: "bold",
                                paddingLeft: "0px",
                                textTransform: "none",
                            }}
                        >
                            Task Definition:
                        </Button>
                        {/* Load Template Button */}
                        <Button
                            size="small"
                            color="quSandBoxColor"
                            onClick={handleTemplateHandler}
                            className={classes.submitButton}
                            startIcon={<FileCopyIcon />}
                            style={{
                                marginLeft: "8px",
                                textTransform: "none",
                            }}
                        >
                            Load Template
                        </Button>
                    </Grid>

                    {/* Task Definition Input */}
                    <Grid item xs={12}>
                        <TextField
                            value={taskDefinition}
                            onChange={(e) => setTaskDefinition(e.target.value)}
                            fullWidth
                            multiline
                            rows={8}
                            variant="outlined"
                            placeholder="Write in your task description or load one of the templates provided."
                            InputProps={{
                                style: { fontSize: "0.9rem", lineHeight: "1.5" },
                            }}
                            InputLabelProps={{
                                style: { fontSize: "0.9rem" },
                            }}
                        />
                    </Grid>

                    {/* Submit Module Button */}
                    <Grid item xs={12}>
                        <Button
                            color="quSandBoxColor"
                            onClick={submitHandler}
                            className={classes.submitButton}
                            style={{
                                float: "right",
                                fontWeight: "bold",
                            }}
                            startIcon={<Check />}
                        >
                            Submit Module
                        </Button>
                        {/* <Link
                            to={"/courses/" + projectID} // Specify the target URL
                            style={{ textDecoration: 'none' }} // Optional: remove underline from the link
                        > */}
                        <Button
                            color="quSandBoxColor"
                            onClick={handleModuleBack}
                            className={classes.submitButton}
                            style={{
                                float: "left",
                                fontWeight: "bold",
                            }}
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                        {/* </Link> */}
                    </Grid>



                </Grid>
            )}

            {/* Project Designer */}
            {projectType === "lecture_designer" && (
                <>

                    {/* Next Button */}
                    <Button
                        color="quCreateColor"
                        onClick={lectureSubmitHandler}
                        className={classes.submitButton}
                        style={{
                            float: "right",
                            fontWeight: "bold",
                            textTransform: "none",
                        }}
                        endIcon={<NavigateNextIcon />}
                    >
                        NEXT
                    </Button>
                    <Button
                        color="quCreateColor"
                        onClick={handleModuleBack}
                        className={classes.submitButton}
                        style={{
                            float: "left",
                            fontWeight: "bold",
                        }}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                    {/* Form for labs creation */}
                    {/* <Grid item sm={12} xs={12} style={{
                        display: "flex"
                    }}>
                        <Checkbox
                            checked={labCheckBox}
                            onChange={handleCheckboxChange}
                            color="quSandBoxColor"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="body1" color="textSecondary" style={{
                            marginTop: "10px",
                            marginLeft: "2px"

                        }}>
                            Create Labs for this Module.
                        </Typography>
                        <LabSpecificationsDialog open={labCheckBox} onClose={() => setLabCheckBox(false)} setLabSpecificationGenerated={setLabSpecificationGenerated} />
                    </Grid> */}
                </>)}


            {/* Lab Designer */}
            {projectType === "lab_designer" && (
                <>
                    <LabRequirementForm projectID={projectID} projectType={projectType} files={files} images={images} links={links} setActiveStep={setActiveStep} setLoadingBusinessUseCase={setLoadingBusinessUseCase} />
                </>)}

            {/* Slide Deck Modal */}
            <SlideDeckModalBox
                open={slideDeckModal}
                onClose={handleSlideDeckModalClose}
                files={files}
                slideDeck={slideDeck}
                setSlideDeck={setSlideDeck}
                submitHandler={handleSlideDeckModalSubmit}
            />
            {/* Pass props to FileUploadDialog */}
            <FileUploadDialog
                open={replaceResourceModal}
                onClose={() => setReplaceResourceModal(false)}
                newFile={newResource}
                setNewFile={setNewResource}
                onSave={replace ? handleReplaceResource : handleReplaceSave}
                replace={replace}
            />

            <ResourceModal projectID={projectID} moduleID={moduleID} open={openModal} onClose={handleCloseModal} projectType={projectType} />
            <TaskDefinitionModal open={openTemplateModal} onClose={handleCloseTemplateModal} setTaskDefinition={setTaskDefinition} />
        </Box >
    );
}

