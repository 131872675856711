import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";

// material-ui icons
import MenuIcon from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import { Grid, Typography } from "@material-ui/core";
import { Create, RateReview } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  // Sample data for unread and read notifications
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState([

    "On Machine Learning Module submitted for Content Generation",
    "Natural Language Processing Module submitted for Deliverables Generation",
  ]);
  const [readNotifications, setReadNotifications] = useState([
    "Deliverables generated for AI Investments Module",
    "Module added in AI and Big Data Applications in Investments",
    "AI and Big Data Applications in Investments Course Added"
  ]);

  // Handle notification click (open the dropdown without marking as read yet)
  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing of the popover (mark notifications as read)
  const handleNotificationClose = () => {
    // Move unread notifications to read notifications when the popover is closed
    if (unreadNotifications.length > 0) {
      setReadNotifications([...readNotifications, ...unreadNotifications]);
      setUnreadNotifications([]); // Clear unread notifications
    }
    setAnchorEl(null); // Close the popover
  };

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>

        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button className={classes.title} color="transparent">
            <h3><b>{brandText}</b></h3>
          </Button>
        </div>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon />
          </Button>
        </Hidden>

        {/* Notification Icon with Red Dot */}
        {/* <IconButton
          color="inherit"
          className={classes.notificationButton}
          onClick={handleNotificationClick}
        >
          {unreadNotifications.length > 0 ? (
            <div style={{ position: "relative" }}>
              <NotificationsIcon />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: "red",
                }}
              />
            </div>
          ) : (
            <NotificationsNoneIcon />
          )}
        </IconButton> */}

        {/* Notification Popover */}
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleNotificationClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: classes.popoverPaper,  // Optional: Customize popover style
          }}
          disableScrollLock  // Optional: Prevents scroll issues on mobile
        >
          {/* Unread notifications */}
          {unreadNotifications.length > 0 ? (
            unreadNotifications.map((notification, index) => (
              <MenuItem
                key={index}
                onClick={handleNotificationClose}
                style={{
                  backgroundColor: "#ffefef", // Light red shade for unread notifications
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1">{notification}</Typography>
                  </Grid>
                  <Grid item xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <Typography variant="caption">
                      {
                        // randomly select an icon
                        Math.random() > 0.5 ? (
                          <Create style={{
                            fontSize: 12
                          }} />
                        ) : (
                          <RateReview style={{
                            fontSize: 12
                          }} />
                        )
                      } &nbsp;
                      {
                        // randomly select course or module related notification
                        Math.random() > 0.5 ? "Course" : "Module"
                      }</Typography>
                    <Typography variant="caption">{
                      // randomly select a date
                      Math.random() > 0.5 ? "2 days ago" : "1 week ago"
                    }
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={handleNotificationClose}>
              No new notifications
            </MenuItem>
          )}

          {/* Read notifications */}
          {readNotifications.length > 0 && (
            readNotifications.map((notification, index) => (
              <MenuItem key={index} onClick={handleNotificationClose}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1">{notification}</Typography>
                  </Grid>
                  <Grid item xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    {/* show random dates */}
                    <Typography variant="caption">
                      {
                        // randomly select an icon
                        Math.random() > 0.5 ? (
                          <Create style={{
                            fontSize: 12
                          }} />
                        ) : (
                          <RateReview style={{
                            fontSize: 12
                          }} />
                        )
                      } &nbsp;
                      {
                        // randomly select course or module related notification
                        Math.random() > 0.5 ? "Course" : "Module"
                      }</Typography>
                    <Typography variant="caption">{
                      // randomly select a date
                      Math.random() > 0.5 ? "2 days ago" : "1 week ago"
                    }
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))
          )}
        </Popover>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
