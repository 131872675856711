import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Grid, Box, Typography } from "@material-ui/core";
import { useRouteMatch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DeliverableTiles from "components/Tiles/DeliverableTiles";
import LinkIcon from '@material-ui/icons/Link';
import ImageIcon from '@material-ui/icons/Image';
import NotesIcon from '@material-ui/icons/Notes';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from "components/CustomButtons/Button.js";

import SlideshowIcon from '@material-ui/icons/Slideshow';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import LaptopIcon from '@material-ui/icons/Laptop';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Slides from "views/Dashboard/Slides";
import Labs from "./Labs";
import VideoPlayer from "./Videos";
import Assessment from "./Assessment"
import NotificationContext from "views/Components/Context.js";
import { DoneAll } from "@material-ui/icons";
import { Auth } from "aws-amplify";


export default function Deliverables(props) {
    const history = useHistory();
    const courseID = props.courseID;
    const moduleID = props.moduleID;
    const { showNotification } = React.useContext(NotificationContext);

    const [activeTile, setActiveTile] = useState(null);
    const handleNavigation = (type) => {
        setActiveTile(type);
    };

    const [user, setUser] = useState();

    const [slides, setSlides] = useState([]);
    const [labLinks, setLabLinks] = useState([]);
    const [videos, setVideos] = useState([]);
    const [assessment, setAssessment] = useState([]);
    const [notes, setNotes] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true);

    const setDeliverableFromCourse = (data) => {
        const modules = data.modules;
        const module = modules.find(module => module.module_id === moduleID);
        const resources = module.pre_processed_deliverables;
        const slides = resources.filter(resource => resource.resource_type === "Slide_Generated");
        const labLinks = resources.filter(resource => resource.resource_type === "Link");
        const videos = resources.filter(resource => resource.resource_type === "Video");
        const assessment = resources.filter(resource => resource.resource_type === "Assessment");
        const notes = resources.filter(resource => resource.resource_type === "Note");

        setSlides(slides);
        setLabLinks(labLinks);
        setVideos(videos);
        setAssessment(assessment);
        setNotes(notes);

        // console.log("slides: ", slides);
        // console.log("labLinks: ", labLinks);
        // console.log("videos: ", videos);
        // console.log("assessment: ", assessment);
        // console.log("Notes: ", notes);

        // next step: pass the files to the appropriate component
        // check if delete resource works. it is yet to be implemented.
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_FASTAPI_URL + `/get_course/${courseID}`)
            .then(res => res.json())
            .then(data => {
                setDeliverableFromCourse(data);
            })
            .catch(err => {
                console.log(err);
                showNotification("Error fetching course data", "danger");
            })
            .finally(() => {
                setModulesLoading(false);
            })
    }, [courseID]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const renderContent = () => {
        switch (activeTile) {
            case "Slides":
                return <Slides courseID={props.courseID} moduleID={props.moduleID} filetype="Slide_Generated" slides={slides} />;
            case "Labs":
                return <Labs courseID={props.courseID} moduleID={props.moduleID} filetype="Link" links={labLinks} />;
            case "Video":
                return <VideoPlayer courseID={props.courseID} moduleID={props.moduleID} filetype="Video" videos={videos} />;
            case "Assessment":
                return <Assessment courseID={props.courseID} moduleID={props.moduleID} filetype="Assessment" assessment={assessment} />;
            default:
                return null;
        }
    };

    return (
        <Box mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DeliverableTiles
                        active={activeTile}
                        color="warning"
                        alignCenter
                        tabs={[
                            { heading: "Slides", icon: SlideshowIcon, description: "Contains all slides", onClick: () => handleNavigation('Slides') },
                            { heading: "Videos", icon: VideoLibraryIcon, description: "Contains all videos", onClick: () => handleNavigation('Video') },
                            { heading: "Labs", icon: LaptopIcon, description: "Contains all labs", onClick: () => handleNavigation('Labs') },
                            { heading: "Assessment", icon: AssessmentIcon, description: "Contains all assessments", onClick: () => handleNavigation('Assessment') }
                        ]}
                    />
                </Grid>
            </Grid>
            {renderContent()}
            <Button color="quSandBoxColor" style={{
                float: "right",
                marginTop: "20px"
            }}
                onClick={
                    () => { showNotification("The module has been marked for publishing.", "quSandBoxColor") }
                }
            >
                <DoneAll /> &nbsp;
                Publish Module
            </Button>
        </ Box>
    );
}
