import { addHours, startOfHour, subHours, eachHourOfInterval, differenceInHours, format, roundToNearestMinutes, addMinutes, subMinutes, differenceInMinutes, startOfDay, startOfTomorrow, subDays, getHours, addDays, differenceInDays } from 'date-fns'
import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Auth } from "aws-amplify";

import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, 
    // BarChart, Bar,
} from 'recharts';

import ReactTable from "components/ReactTable/ReactTable.js";
// import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';

const useFormStyles = makeStyles(regularFormsStyle);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);

// var Chartist = require("chartist");
// require('chartist-plugin-legend');

export default function LogChart() {
    const COLOR = ["#55acee", "#dd4b39", "#0976b4", "#cc2127", "#e52d27", "#35465c", "#1769ff", "#ea4c89", "#ff4500", "#3b5998"]
    const extendedFormsClasses = useextendedFormsStyles();
    const formClasses = useFormStyles();

    const [alert, setAlert] = React.useState();
    const [logs, setlogs] = React.useState([]);
    const [samples, setsamples] = React.useState([]);
    const [columns, setcolumns] = React.useState([]);
    const [chart, setchart] = React.useState();
    const [subChart, setsubChart] = React.useState();
    const [operations, setoperations] = React.useState([]);
    const [resources, setresources] = React.useState([]);
    const [operation, setoperation] = React.useState('All');
    const [resource, setresource] = React.useState('All');
    const [unit, setunit] = React.useState(60);
    const [length, setlength] = React.useState(23);
    const [timeSelect, settimeSelect] = React.useState("1d");
    const [startHour, setstartHour] = React.useState();
    const [endHour, setendtHour] = React.useState();
    const timeMap = {
        "1h": [5, 12],
        "3h": [15, 12],
        "12h": [60, 12],
        "1d": [60, 23],
        "1w": [720, 14],
        "1m": [1440, 30]
    }

    const handleResourceChange = event => {
        setresource(event.target.value);
    };

    const handleOperationChange = event => {
        setoperation(event.target.value);
    };

    const periodChange = (i) => {
        settimeSelect(i)
        setunit(timeMap[i][0])
        setlength(timeMap[i][1])
        setresource('All')
        setoperation('All')
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(function (user) {
            let token = user.signInUserSession.accessToken.jwtToken;
            if (!startHour) {
                return
            }
            fetch(process.env.REACT_APP_BACK_END_URL + "/logservice?time=" + startHour.toISOString(), {
                method: "GET",
                mode: "cors",
                credentials: 'omit',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            }).then(function (res) {
                res.json().then(function (data) {
                    setlogs(data.Items)
                    setsamples(data.Items)
                    if (data.Items.length > 0) {
                        var temp = []
                        Object.keys(data.Items[0]).map((key, i) => temp.push({ "Header": key, "accessor": key }))
                        setcolumns(temp)
                    }
                })
            }, function (err) {
                console.log(err)
            });
        })
    }, [startHour])

    useEffect(() => {
        if (unit == 60) {
            var end = addHours(startOfHour(new Date()), 1)
            var start = subHours(end, length)
        } else if (unit == 15 || unit == 5) {
            var end = addMinutes(roundToNearestMinutes(new Date()), 1)
            var start = subMinutes(end, unit * length)
        } else if (unit == 720) {
            var noon = parseInt(getHours(new Date()) / 12)
            var end = addHours(startOfDay(new Date()), 12 * noon)
            var start = subHours(end, unit * length / 60)
        } else if (unit == 1440) {
            var end = startOfTomorrow(new Date())
            var start = subDays(end, length)
        }
        setstartHour(start)
        setendtHour(end)
    }, [timeSelect])

    const sixtyMinHelper = (x) => {
        return { "time": format(x, 'HH'), value: 0 }
    }

    const minHelper = (x) => {
        return { "time": format(x, 'HH:mm'), value: 0 }
    }

    const hourHelper = (x) => {
        return { "time": format(x, 'do H'), value: 0 }
    }

    const dateHelper = (x) => {
        return { "time": format(x, 'do'), value: 0 }
    }

    useEffect(() => {
        if (!logs || logs.length == 0) {
            setAlert('No Logs Availabe')
            return
        } else {
            setAlert()
        }
        var series = [];
        var legends = [];
        var resourcesMap = {}
        for (var i in logs) {
            let log = logs[i]
            if (!(log['log_resource'] in resourcesMap)) {
                resourcesMap[log['log_resource']] = []
            }
            resourcesMap[log['log_resource']].push(log)
            var row = legends.indexOf(log['log_operation'])
            if (row == -1) {
                legends.push(log['log_operation'])
                if (unit == 60) {
                    var period = eachHourOfInterval({ start: startHour, end: endHour })
                    period = period.map(x => sixtyMinHelper(x))
                } else if (unit == 15 || unit == 5) {
                    var period = []
                    for (var i = 0; i < length; i++) {
                        period.push(addMinutes(startHour, unit * i))
                    }
                    period = period.map(x => minHelper(x))
                } else if (unit == 720) {
                    var period = []
                    for (var i = 0; i < length; i++) {
                        period.push(addHours(startHour, unit * i / 60))
                    }
                    period = period.map(x => hourHelper(x))
                } else if (unit == 1440) {
                    var period = []
                    for (var i = 0; i < length; i++) {
                        period.push(addDays(startHour, i))
                    }
                    period = period.map(x => dateHelper(x))
                }
                series.push(period)
                row = legends.length - 1;
            }

            var log_time = new Date(log['log_time'] + ' UTC')
            if (unit == 60) {
                var passHours = differenceInHours(endHour, log_time)
                var col = length - passHours - 1
            } else if (unit == 15 || unit == 5) {
                var passMin = differenceInMinutes(endHour, log_time)
                var col = length - parseInt(passMin / unit) - 1
            } else if (unit == 720) {
                var passHours = differenceInHours(endHour, log_time)
                var col = length - parseInt(passHours / 12) - 1
            } else if (unit == 1440) {
                var passDays = differenceInDays(endHour, log_time)
                var col = length - passDays - 1
            }
            if (col >= 0 && col < length) {
                series[row][col]['value'] = series[row][col]['value'] + 1
            }
        }
        setresources(resourcesMap)
        setoperations(legends)
        setchart(series)
    }, [logs])

    useEffect(() => {
        if (!resource || resource == 'All') {
            return
        }
        var series = [];
        for (var i in operations) {
            if (unit == 60) {
                var period = eachHourOfInterval({ start: startHour, end: endHour })
                period = period.map(x => sixtyMinHelper(x))
            } else if (unit == 15 || unit == 5) {
                var period = []
                for (var i = 0; i < length; i++) {
                    period.push(addMinutes(startHour, unit * i))
                }
                period = period.map(x => minHelper(x))
            } else if (unit == 720) {
                var period = []
                for (var i = 0; i < length; i++) {
                    period.push(addHours(startHour, unit * i / 60))
                }
                period = period.map(x => hourHelper(x))
            } else if (unit == 1440) {
                var period = []
                for (var i = 0; i < length; i++) {
                    period.push(addDays(startHour, i))
                }
                period = period.map(x => dateHelper(x))
            }
            series.push(period)
        }
        for (var i in resources[resource]) {
            let log = resources[resource][i]
            var row = operations.indexOf(log['log_operation'])
            var log_time = new Date(log['log_time'] + ' UTC')
            if (unit == 60) {
                var passHours = differenceInHours(endHour, log_time)
                var col = length - passHours - 1
            } else if (unit == 15 || unit == 5) {
                var passMin = differenceInMinutes(endHour, log_time)
                var col = length - parseInt(passMin / unit) - 1
            } else if (unit == 720) {
                var passHours = differenceInHours(endHour, log_time)
                var col = length - parseInt(passHours / 12) - 1
            } else if (unit == 1440) {
                var passDays = differenceInDays(endHour, log_time)
                var col = length - passDays - 1
            }
            if (col >= 0 && col < length) {
                series[row][col]['value'] = series[row][col]['value'] + 1
            }
        }
        setsubChart(series)
    }, [resource])

    useEffect(() => {
        if (operation == 'All' && resource == 'All') {
            setsamples(logs)
        } else if (resource != 'All' && operation == 'All') {
            setsamples(resources[resource])
        } else if (resource == 'All' && operation != 'All') {
            var filtered = logs.filter(function (item) {
                return item.log_operation == operation;
            });
            setsamples(filtered)
        } else {
            var filtered = resources[resource].filter(function (item) {
                return item.log_operation == operation;
            });
            setsamples(filtered)
        }
    }, [operation, resource])

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={2}>
                <div><FormControl
                    fullWidth
                    className={extendedFormsClasses.selectFormControl}
                >
                    <InputLabel
                        htmlFor="operation"
                        className={extendedFormsClasses.selectLabel}
                    >
                        Choose Operation
                        </InputLabel>
                    <Select
                        MenuProps={{
                            className: extendedFormsClasses.selectMenu
                        }}
                        classes={{
                            select: extendedFormsClasses.select
                        }}
                        onChange={handleOperationChange}
                        value={operation}
                        inputProps={{
                            name: "operation",
                            id: "operation",
                            value: operation
                        }}
                    >
                        <MenuItem
                            classes={{
                                root: extendedFormsClasses.selectMenuItem
                            }}
                            value='All'
                        >
                            All
                        </MenuItem>
                        {operations && operations.map((op, index) => (
                            <MenuItem
                                key={index}
                                classes={{
                                    root: extendedFormsClasses.selectMenuItem,
                                    selected: extendedFormsClasses.selectMenuItemSelected
                                }}
                                value={op}
                            >
                                {op}
                            </MenuItem>
                        ))
                        }
                    </Select>
                </FormControl></div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={2}>
                <div><FormControl
                    fullWidth
                    className={extendedFormsClasses.selectFormControl}
                >
                    <InputLabel
                        htmlFor="resource"
                        className={extendedFormsClasses.selectLabel}
                    >
                        Choose Resource
                        </InputLabel>
                    <Select
                        MenuProps={{
                            className: extendedFormsClasses.selectMenu
                        }}
                        classes={{
                            select: extendedFormsClasses.select
                        }}
                        onChange={handleResourceChange}
                        value={resource}
                        inputProps={{
                            name: "resource",
                            id: "resource",
                            value: resource
                        }}
                    >
                        <MenuItem
                            classes={{
                                root: extendedFormsClasses.selectMenuItem
                            }}
                            value='All'
                        >
                            All
                        </MenuItem>
                        {resources && Object.keys(resources).map((res, i) => (
                            <MenuItem
                                key={i}
                                classes={{
                                    root: extendedFormsClasses.selectMenuItem,
                                    selected: extendedFormsClasses.selectMenuItemSelected
                                }}
                                value={res}
                            >
                                {res}
                            </MenuItem>
                        ))
                        }
                    </Select>
                </FormControl></div>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} lg={8}>
                <div
                    className={
                        formClasses.checkboxAndRadio +
                        " " +
                        formClasses.checkboxAndRadioHorizontal
                    }
                    style={{
                        float: 'right'
                    }}
                >
                    {Object.keys(timeMap).map((temp, i) => (
                        <FormControlLabel
                            key={i}
                            control={
                                <Radio
                                    checked={timeSelect == temp}
                                    onChange={() => periodChange(temp)}
                                    value="a"
                                    name="radio button demo"
                                    aria-label="A"
                                    icon={
                                        <FiberManualRecord
                                            className={formClasses.radioUnchecked}
                                        />
                                    }
                                    checkedIcon={
                                        <FiberManualRecord
                                            className={formClasses.radioChecked}
                                        />
                                    }
                                    classes={{
                                        checked: formClasses.radio,
                                        root: formClasses.radioRoot
                                    }}
                                />
                            }
                            classes={{
                                label: formClasses.label,
                                root: formClasses.labelRoot
                            }}
                            label={temp}
                        />
                    ))}
                </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {/* <ResponsiveContainer width="95%" height={300} >
                    <BarChart>
                        <XAxis dataKey="time" type="category" allowDuplicatedCategory={false} />
                        <YAxis dataKey="value" />
                        <CartesianGrid />
                        <Tooltip />
                        <Legend />
                        {
                            (!operation || operation == 'All') && (!resource || resource == 'All') && chart && chart.map((s, i) => (
                                <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                            ))
                        }
                        {
                            (operation && operation != 'All') && (!resource || resource == 'All') && chart && chart.map((s, i) => (
                                operations[i] == operation ?
                                    <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                                    : null
                            ))
                        }
                        {
                            (!operation || operation == 'All') && (resource && resource != 'All') && subChart && subChart.map((s, i) => (
                                <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                            ))
                        }
                        {
                            (operation && operation != 'All') && (resource && resource != 'All') && subChart && subChart.map((s, i) => (
                                operations[i] == operation ?
                                    <Bar dataKey="value" data={s} name={operations[i]} fill={COLOR[i]} key={operations[i]} />
                                    : null
                            ))
                        }
                    </BarChart>
                </ResponsiveContainer> */}
                <div>{alert}</div>
                {!alert && <ResponsiveContainer width="95%" height={300} >
                    <LineChart>
                        <XAxis dataKey="time" type="category" allowDuplicatedCategory={false} label={{ value: 'Time', position: 'insideBottomRight', offset: 0 }} />
                        <YAxis dataKey="value" allowDecimals={false} label={{ value: 'Count', position: 'insideTopLeft', offset: 0 }} />
                        <CartesianGrid />
                        <Tooltip />
                        <Legend />
                        {
                            (!operation || operation == 'All') && (!resource || resource == 'All') && chart && chart.map((s, i) => (
                                <Line dataKey="value" data={s} name={operations[i]} stroke={COLOR[i]} key={operations[i]} />
                            ))
                        }
                        {
                            (operation && operation != 'All') && (!resource || resource == 'All') && chart && chart.map((s, i) => (
                                operations[i] == operation ?
                                    <Line dataKey="value" data={s} name={operations[i]} stroke={COLOR[i]} key={operations[i]} />
                                    : null
                            ))
                        }
                        {
                            (!operation || operation == 'All') && (resource && resource != 'All') && subChart && subChart.map((s, i) => (
                                <Line dataKey="value" data={s} name={operations[i]} stroke={COLOR[i]} key={operations[i]} />
                            ))
                        }
                        {
                            (operation && operation != 'All') && (resource && resource != 'All') && subChart && subChart.map((s, i) => (
                                operations[i] == operation ?
                                    <Line dataKey="value" data={s} name={operations[i]} stroke={COLOR[i]} key={operations[i]} />
                                    : null
                            ))
                        }
                    </LineChart>
                </ResponsiveContainer>
                }
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {logs.length > 0 && <ReactTable columns={columns} data={samples}></ReactTable>}
            </GridItem>
        </GridContainer>
    )
}