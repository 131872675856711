import { Card, Grid, Icon, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import React, { useState, useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI } from "views/APIs/APIUtility";

export default function ModuleInformationContentReview(props) {
    console.log("props: ModuleInformationContentReview ", props.moduleInformation);
    const { showNotification } = React.useContext(NotificationContext);
    const [moduleInformation, setModuleInformation] = useState();
    if (props.moduleInformation.length > 0) {
        const moduleInformationLink = props.moduleInformation[0].resource_link || "";
    }
    else {
        const moduleInformationLink = "";
    }

    const fetchFile = async (fileUrl) => {
        if (!fileUrl) {
            showNotification("Invalid file URL", "danger");
            return;
        }

        const formData = new FormData();
        formData.append("url", fileUrl);

        fetch(process.env.REACT_APP_FASTAPI_URL + "/fetch_note", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Data: ", data);
                setModuleInformation(data.content);
            })
            .catch((error) => {
                console.error("Error: ", error);
                showNotification("Error fetching file", "danger");
            }
            )
    };

    const setMIForCard = () => {
        const card = props.moduleInformation[0];
        if (card && card.resource_link) {
            // fetchFile("https://qucoursify.s3.amazonaws.com/qu-course-design/673277707a469de58e0efa96/673277717a469de58e0efa9a/raw_resources/67337197c061106b9ae5f07a.md");
            fetchFile(card.resource_link);
        } else {
            console.warn("Card or resource link not found");
        }
    };

    useEffect(() => {
        if (props.moduleInformation.length > 0) {
            setMIForCard();
        }
    }, [props.moduleInformation]);

    const handleModuleInformation = (content) => setModuleInformation(content);

    const handleSaveChanges = () => {
        let resource_description = props.moduleInformation[0].resource_description;
        resource_description = resource_description + "###NOTE###" + moduleInformation;

        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);
        formData.append("resource_type", "Note");
        formData.append("resource_description", resource_description);
        formData.append("resource_link", props.moduleInformation[0].resource_link);
        formData.append("resource_id", props.moduleInformation[0].resource_id);
        formData.append("resource_name", props.moduleInformation[0].resource_name);
        formData.append("course_design_step", props.course_design_step);

        try {
            replaceResourceAPI(formData);
            showNotification("Changes saved successfully", "success");
        }
        catch (error) {
            console.error("Error in handleUpdateNote:", error);
            showNotification("Error saving changes", "danger");
        }


    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" style={{ marginTop: "20px" }}>Module Information</Typography>
                    <Typography variant="body1">This is the brief description of the module.</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div data-color-mode="light" style={{
                        marginTop: "10px",
                    }}>
                        <MDEditor value={moduleInformation} height='400' onChange={handleModuleInformation} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                        <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
                            <Button color="danger">
                                <Icon>close</Icon> &nbsp; Exit Review
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ textAlign: "center" }} />
                        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
                            <Button color="success" onClick={handleSaveChanges}>
                                <Icon>save</Icon> &nbsp; Save Changes
                            </Button>
                            &nbsp;
                            <Button color="info" onClick={() => showNotification("Marked as reviewed. You will be notified when the content is ready for the structure review.", "quSandBoxColor")}>
                                <Icon>check</Icon> &nbsp; Mark as Reviewed
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
