import { Box, Divider, Grid, Icon, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import ReviewSlide from "./ReviewSlide";
import NotificationContext from "views/Components/Context.js";
import { replaceResourceAPI } from "views/APIs/APIUtility";

export default function SlidesContentReview(props) {

    console.log("In SlidesContentReview:", props);
    const { showNotification } = React.useContext(NotificationContext);
    const [slideHeaders, setSlideHeaders] = useState([]);
    const [slideContents, setSlideContents] = useState([]);
    const [speakerNotes, setSpeakerNotes] = useState([]);
    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [activeSlide, setActiveSlide] = useState(0);

    const fetchSlidesContent = async (fileUrl) => {
        const formData = new FormData();
        formData.append("url", fileUrl);

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/fetch_quizdata`, {
                method: "POST",
                body: formData,
            });
            const data = await response.json();
            console.log("Fetched Quiz Data: ", data);
            return data;
        } catch (err) {
            console.error("Error fetching file:", err);
        }
    };

    const convertDataToJSON = (data) => {
        const jsonString = JSON.stringify(data);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const file = new File([blob], 'data.json', { type: 'application/json' });
        return file;
    };


    const writeFile = () => {
        const data = [];
        for (let i = 0; i < slideHeaders.length; i++) {
            data.push({
                slide_header: slideHeaders[i],
                slide_content: slideContents[i],
                speaker_notes: speakerNotes[i],
                notes: notes[i]
            });
        }
        // write data to a json file and return the file
        const file = convertDataToJSON(data);
        return file;
    }

    const handleSaveChanges = () => {
        console.log("Save Changes");
        const file = writeFile();
        const formData = new FormData();
        formData.append("course_id", props.courseID);
        formData.append("module_id", props.moduleID);
        // add resource_id, resource_name, resource_type, resource_link, resource_file, coruse_design_step
        formData.append("resource_type", "Slide_Content");
        formData.append("resource_description", props.slides[0].resource_description);
        formData.append("resource_link", props.slides[0].resource_link);
        formData.append("resource_id", props.slides[0].resource_id);
        formData.append("resource_name", props.slides[0].resource_name);
        formData.append("resource_file", file);
        // very important: if not specified it will replace the file in the raw_resources_directory
        formData.append("course_design_step", 2)

        try {
            replaceResourceAPI(formData);
            showNotification("Changes saved successfully", "success");
        }
        catch (error) {
            console.error("Error in handleUpdateNote:", error);
            showNotification("Error saving changes", "danger");
        }
    };



    useEffect(() => {
        const setSlidesandFetchData = async () => {
            const card = props.slides[0];
            if (card) {
                const slidesDataLink = card.resource_link;
                const data = await fetchSlidesContent(slidesDataLink);
                const localHeaders = [];
                const localContent = [];
                const localNotes = [];
                data.forEach(slide => {
                    localHeaders.push(slide.slide_header);
                    localContent.push(slide.slide_content);
                    localNotes.push(slide.speaker_notes);
                });
                setSlideHeaders(localHeaders);
                setSlideContents(localContent);
                setSpeakerNotes(localNotes);
                setIsLoading(false);
                // setQuizObject(data);
            } else {
                console.warn("No assessment data found.");
            }
        };

        setSlidesandFetchData();
    }, [props.slides]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" style={{
                        marginTop: "20px"
                    }}>Slides Content Review</Typography>
                    <Typography variant="body1">
                        Review the content for the slides including the speaker notes and the slide content.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <ReviewSlide
                        slideHeader={slideHeaders[activeSlide]}
                        slideContent={slideContents[activeSlide]}
                        speakerNotes={speakerNotes[activeSlide]}
                        notes={notes[activeSlide]}
                        setSlideHeader={(value) => {
                            setSlideHeaders(
                                slideHeaders.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }
                        }
                        setSlideContent={(value) => {
                            setSlideContents(
                                slideContents.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }}
                        setSpeakerNotes={(value) => {
                            setSpeakerNotes(
                                speakerNotes.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }}
                        setNotes={(value) => {
                            setNotes(
                                notes.map((item, index) => {
                                    if (index === activeSlide) {
                                        return value;
                                    }
                                    return item;
                                })
                            )
                        }
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={12} justify="center">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Button style={{
                                float: "right"
                            }} color="quSandBoxColor"
                                disabled={activeSlide === 0}
                                onClick={() => setActiveSlide(activeSlide - 1)}

                            >
                                <Icon>arrow_back</Icon>
                                &nbsp;
                                Previous Slide</Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1" style={{
                                textAlign: "center",
                                marginTop: "10px"
                            }}>Slide {activeSlide + 1} of {slideHeaders.length}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button style={{
                                float: "left"
                            }} color="quSandBoxColor"
                                disabled={activeSlide === slideHeaders.length - 1}
                                onClick={() => setActiveSlide(activeSlide + 1)}

                            >
                                Next Slide&nbsp;
                                <Icon>arrow_forward</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} style={{
                            textAlign: "left"
                        }}>
                            <Button color="danger">
                                <Icon>close</Icon> &nbsp;
                                Exit Review</Button>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{
                            textAlign: "center"
                        }}>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{
                            textAlign: "right"
                        }}>
                            <Button color="success"
                                onClick={handleSaveChanges}
                            >
                                <Icon>save</Icon> &nbsp;
                                Save Changes</Button>
                            &nbsp;
                            <Button color="info"
                                onClick={
                                    () => {
                                        showNotification("Marked as reviewed. You will be notified when the content is ready for the structure review.", "quSandBoxColor");
                                    }
                                }
                            >
                                <Icon>check</Icon>
                                &nbsp;
                                Mark as Reviewed</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}