import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Divider,
  LinearProgress,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Resources from "../Resources";
import Deliverables from "../Deliverables";
import ContentReview from "./ContentReview";
import StructureReview from "./StructureReview";
import { useRouteMatch } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import NotificationContext from "views/Components/Context.js";
import { moduleStages, displayModuleStages } from "../Data/moduleStages";
import { fetchCourseAPI } from "views/APIs/APIUtility";
import StepConnector from "@material-ui/core/StepConnector";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ...styles,
  header: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  navButton: {
    color: theme.palette.grey[800],
    margin: theme.spacing(0, 10), // Adjust margins
    textTransform: "none",
    padding: theme.spacing(20, 20), // Adjust button padding
  },
  active: {
    fontWeight: "bold",
    textDecoration: "underline",
  },
  submitButton: {
    marginTop: "16px",
  },
  title: {
    flexGrow: 1,
    textAlign: "left", // Center align the title
    color: theme.palette.text.primary, // Set text color to primary text color
    fontWeight: 600, // Increase font weight for better visibility
  },
  moduleStatus: {
    marginTop: 10,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  stepperRoot: {
    "& .MuiStepIcon-root": {
      color: "#435966", // Gray color for all steps (non-completed)
    },
    "& .MuiStepLabel-root.Mui-completed .MuiStepIcon-root": {
      color: "#435966", // Color for completed step icons (greenish color)
    },
    "& .MuiStepLabel-root.Mui-active .MuiStepIcon-root": {
      color: "#435966", // Active step icon color (greenish color)
    },
    "& .MuiStepLabel-root": {
      color: "#D3D3D3", // Gray color for all step labels (non-completed)
    },
    "& .MuiStepLabel-root.Mui-active": {
      color: "#435966", // Active step label color (greenish color)
    },
    "& .MuiStepLabel-root.Mui-completed": {
      color: "#435966", // Completed step label color (greenish color)
    },
  },
}));

const QontoConnector = withStyles({
  active: {
    color: quCreateColor,
  },
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: quCreateColor,
    borderTopWidth: 3,
    borderRadius: 3,
    height: 5,
  },
  stepLabel: {
    color: quCreateColor,
  },
})(StepConnector);

export default function ModulesBoard(props) {
  const classes = useStyles();
  const { showNotification } = React.useContext(NotificationContext);
  const steps = getSteps();
  const displaySteps = getDisplaySteps();
  const { path, url } = useRouteMatch();

  // Extract courseID and moduleID from URL params
  const { courseID, moduleID } = props.match.params;
  const [activeStep, setActiveStep] = React.useState(0);
  const [visitedSteps, setVisitedSteps] = useState([0]);

  const [courseStatus, setCourseStatus] = useState(""); // Store course status
  const [moduleStatus, setModuleStatus] = useState(""); // Store module status
  const [activeTab, setActiveTab] = useState("Resources");
  const [loading, setLoading] = useState(true);

  const [course, setCourse] = useState({});
  const [module, setModule] = useState({});

  const module_status_enabled_tab_map = {
    "Raw Resources": 0,
    "In Design Phase": 0,
    "In Content Generation Queue": 0,
    "Content Review": 1,
    "Pre Processed Content": 1,
    "Post Processed Content": 1,
    "In Structure Generation Queue": 1,
    "Structure Review": 2,
    "Pre Processed Structure": 2,
    "Post Processed Structure": 2,
    "In Deliverables Generation Queue": 2,
    "Deliverables Review": 3,
    "Pre Processed Deliverables": 3,
    "Post Processed Deliverables": 3,
    "In Publishing Queue": 3,
    Published: 3,
  };

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        // Fetch course details including modules
        const data = await fetchCourseAPI(courseID);

        // Update course status
        if (data.status) {
          setCourseStatus(data.status);
        }

        // Find and update the module status
        const module = data.modules?.find((mod) => mod.module_id === moduleID);
        if (module?.status) {
          setModuleStatus(module.status);
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchCourseDetails();
  }, [courseID, moduleID]); // Dependencies ensure re-fetching on changes

  useEffect(() => {
    fetch(process.env.REACT_APP_FASTAPI_URL + `/get_course/${courseID}`)
      .then((res) => res.json())
      .then((data) => {
        setCourse(data);
        for (let i = 0; i < data.modules.length; i++) {
          if (data.modules[i].module_id === moduleID) {
            // setModule("Module", data.modules[i]);
            setModule(data.modules[i]);
            setActiveTab(module_status_enabled_tab_map[data.modules[i].status]);
            break;
          }
        }
      })
      .catch((err) => {
        showNotification("Error fetching course data", "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [courseID]);

  const course_steps = [
    {
      lockedMessage: "",
    },
    {
      lockedMessage:
        "Please submit the resources and the task definition to unlock this step.",
    },
    {
      lockedMessage: "Please finish the content review to unlock this step.",
    },
    {
      lockedMessage: "Please finish the structure review to unlock this step.",
    },
  ];

  // Determine the active step based on module status
  useEffect(() => {
    const stepIndex = mapStatusToStep(moduleStatus.trim());
    setActiveStep(stepIndex); // Set the active step from the module status
  }, [moduleStatus]);

  // Map module status to corresponding step index
  function mapStatusToStep(moduleStatus) {
    return moduleStages.findIndex((stage) => stage === moduleStatus.trim());
  }

  function getDisplaySteps() {
    return displayModuleStages;
  }

  function getSteps() {
    return moduleStages;
  }

  const handleStepChange = (index) => {
    if (visitedSteps.includes(index)) {
      setActiveStep(index);
      setActiveTab(index);
    } else {
      const message =
        course_steps[index].lockedMessage || "This step is locked.";
      showNotification(message, "danger");
    }
  };

  useEffect(() => {
    if (!visitedSteps.includes(activeStep)) {
      // set visited steps to all steps before the active step and the active step
      let steps = [];
      for (let i = 0; i <= activeStep; i++) {
        steps.push(i);
      }
      setVisitedSteps(steps);
    }
  }, [activeStep, visitedSteps]);

  // Renders the content based on the selected tab
  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <Resources
            projectID={courseID}
            moduleID={moduleID}
            projectType="course_designer"
          />
        );
      case 1:
        return <ContentReview courseID={courseID} moduleID={moduleID} />;
      case 2:
        return <StructureReview courseID={courseID} moduleID={moduleID} />;
      case 3:
        return <Deliverables courseID={courseID} moduleID={moduleID} />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Loading Module...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* AppBar with Navigation Buttons and Title */}
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {/* Title */}
            <Typography
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bold",
                flexShrink: 0,
                maxWidth: "45%", // Ensures it does not exceed the container width
                wordBreak: "break-word", // Allows long words to break and wrap to the next line if necessary
                overflow: "hidden", // Hides any overflow if text is too long
                whiteSpace: "normal", // Ensures the text wraps
              }}
            >
              {module.module_name}
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              size="small"
              className={classes.stepperRoot}
              // connector={<QontoConnector />}
              style={{ flexGrow: 1 }} // Stepper will take up the remaining space
            >
              {displaySteps.map((label, index) => (
                <Step
                  key={label}
                  disabled={index > activeStep}
                  onClick={() => handleStepChange(index)}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Box>{renderContent()}</Box>
    </Box>
  );
}
