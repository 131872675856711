import React, { useEffect, useState } from "react";
import {
    Grid,
    Dialog,
    DialogContent,
    Card,
    CardMedia,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Box,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Delete, Visibility, GridOn, ViewList, RestorePage } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative', // For positioning the delete icon
        width: '100%',
        height: '250px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    image: {
        width: '100%',
        maxHeight: '80vh',
        borderRadius: '8px',
        objectFit: 'contain',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogImage: {
        width: '100%',
        maxHeight: '80vh',
        objectFit: 'contain',
        borderRadius: '8px',
    },
    iconButton: {
        marginTop: theme.spacing(2),
    },
    activeIcon: {
        color: theme.palette.primary.main,
        fontSize: '2rem',
    },
    inactiveIcon: {
        color: theme.palette.text.secondary,
        fontSize: '2rem',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    deleteIcon: {
        position: 'absolute', // Position the delete icon at the bottom right
        bottom: 8,
        right: 8,
        // color: theme.palette.error.main,
    },
    headerCell: {
        padding: '26px', // You can adjust this for additional spacing if needed
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
}));


export default function ImageGallery({ images, handleDeleteResource, handleReplace }) {
    const classes = useStyles();
    const [currentImage, setCurrentImage] = useState(null);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [gridView, setGridView] = useState(true);

    const handlePreview = (image) => {
        setCurrentImage(image.url);
        setImageModalOpen(true);
    };

    const handlePreviewClose = () => {
        setImageModalOpen(false);
        setCurrentImage(null);
    };

    return (
        <div className={classes.container}>
            {images.length === 0 && (
                <Typography variant="body1">
                    No images found. You can upload images using the + button above.
                </Typography>
            )}
            <Grid item xs={12} className={classes.titleContainer}>
                <IconButton
                    onClick={() => setGridView(true)}
                    className={gridView ? classes.activeIcon : classes.inactiveIcon}
                >
                    <GridOn />
                </IconButton>
                <IconButton
                    onClick={() => setGridView(false)}
                    className={!gridView ? classes.activeIcon : classes.inactiveIcon}
                >
                    <ViewList />
                </IconButton>
            </Grid>

            {/* Conditionally render Grid View or List View */}
            {
                gridView ? (
                    <Grid container spacing={2}>
                        {images.map((image, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Card className={classes.card} onClick={() => handlePreview(image)}>
                                    <CardMedia
                                        component="img"
                                        image={image.url}
                                        alt={image.name}
                                        className={classes.media}
                                    />
                                    {/* Delete Icon */}
                                    <IconButton
                                        onClick={() => handleDeleteResource(image)}
                                        className={classes.deleteIcon}
                                    >
                                        <Delete />
                                    </IconButton>
                                    {/* Todo: Add it later for grid view*/}
                                    {/* <IconButton onClick={() => handleReplace(image, index)}>
                                        <RestorePage />
                                    </IconButton> */}
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell><strong>Description</strong></TableCell>
                                    <TableCell className={classes.headerCell}><strong>Actions</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {images.map((image, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{image.name}</TableCell>
                                        <TableCell>{image.description}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handlePreview(image)}>
                                                <Visibility />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteResource(image)}>
                                                <Delete />
                                            </IconButton>
                                            <IconButton onClick={() => handleReplace(image, index)}>
                                                <RestorePage />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }

            {/* Dialog for Image Preview */}
            <Dialog open={imageModalOpen} onClose={handlePreviewClose} maxWidth="md" fullWidth>
                <IconButton onClick={handlePreviewClose} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {currentImage && (
                        <img
                            src={currentImage}
                            alt="Preview"
                            className={classes.dialogImage}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
