import React, { useState } from "react";
import { Box, Card, Divider, Grid, Icon, Input, Typography } from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor"


export default function ReviewSlide(props) {
    return (
        <Card style={{
            padding: "20px"
        }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <label>Slide Header</label>
                    <Input variant="outlined" fullWidth
                        onChange={(e) => props.setSlideHeader(e.target.value)} value={props.slideHeader}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <label>Slide Content</label>
                    <div data-color-mode="light">
                        <MDEditor
                            value={props.slideContent}
                            onChange={(e) => props.setSlideContent(e)}
                        />
                    </div>

                    {/* <ReactMarkdown>{props.slideContent}</ReactMarkdown> */}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <label>Speaker Notes</label>
                    <Input variant="outlined" placeholder="Speaker Notes" fullWidth multiline rows={6}
                        onChange={(e) => props.setSpeakerNotes(e.target.value)} value={props.speakerNotes}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <label>Add feedback</label>
                    <Input variant="outlined" placeholder="Notes" fullWidth
                        onChange={(e) => props.setNotes(e.target.value)} value={props.notes}
                    />
                </Grid>
            </Grid>
        </Card>
    );
}