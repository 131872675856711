import { Divider, Box, FormControl, Grid, InputLabel, MenuItem, Select, CircularProgress, Typography } from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import { CloudDownload, RotateLeft } from "@material-ui/icons";
import SaveIcon from '@material-ui/icons/Save';
import PDFViewer from "views/Components/PDFViewer";
import { makeStyles } from "@material-ui/core/styles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Heading from "components/Heading/Heading";
import { saveLatestVersionAPI, fetchWritingAPI, convertToPDFAPI } from "views/APIs/APIUtility";
import { format, formatISO } from "date-fns";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const identifiers = {
  research_report: "Research Report",
  white_paper: "White Paper",
  project_plan: "Project Plan",
  blog: "Blog",
  news_letter: "Newsletter",
  case_study: "Case Study",
  key_insights: "Key Insights",
  handout: "Handout",
};
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function WritingPublisher() {
  const sweetAlertClasses = usesweetAlertStyle();
  const location = useLocation();
  let identifier = "research_report";
  if (location.state && location.state.identifier) {
    identifier = location.state.identifier;
  }
  const identifierPrint = identifiers[identifier];
  const writingId = useParams()["writingID"];
  const [alert, setAlert] = React.useState(null);
  const [writing, setWriting] = useState({});
  const [loading, setLoading] = useState(true);
  const { showNotification, setNotification } = React.useContext(
    NotificationContext
  );
  const [pdfLoading, setPdfLoading] = useState(true);

  const [outline, setOutline] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [template, setTemplate] = useState("academic");
  const [version, setVersion] = useState(1);
  const [latestVersion, setLatestVersion] = useState(1);
  const [versionOptions, setVersionOptions] = useState([{
    "writing_outline": "",
    "version": 1,
    "timestamp": formatISO(new Date()),
  }]);

  useEffect(() => {
    const fetchWritingData = async () => {
      try {
        const data = await fetchWritingAPI(writingId);
        // Check if history is available
        if (!data.history || data.history.length === 0) {
        } else {
          console.log("In else");
          const latestVersion = data.history[data.history.length - 1];  // Get the last version
          console.log("Latest version is:", latestVersion);
          setLatestVersion(latestVersion.version);  // Set the latest version
          setVersion(latestVersion.version);  // Set the latest version
          setVersionOptions(data.history);  // Set all versions
        }
        console.log("Data is:", data);
        setWriting(data);
        setOutline(data.writing_outline);
        await getPdf(data.writing_outline);
      } catch (error) {
        showNotification(error, "danger");
      } finally {
        setLoading(false);
        setPdfLoading(false);
      }
    };

    fetchWritingData();
  }, [writingId]);

  const handleSaveChanges = async () => {
    try {
      const formData = new FormData();
      formData.append("writing_id", writingId);
      formData.append("writing_outline", outline);
      const data = await saveLatestVersionAPI(formData);
      console.log(data);
      hideAlert();
      showNotification("Changes saved successfully", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
    }
  }

  const saveChangesAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleSaveChanges()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, Save Changes!"
        cancelBtnText="Cancel"
        showCancel
      >
        The changes will be saved as the latest version. Are you sure you want to save these changes?
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const getPdf = async (markdown = outline, download = false) => {

    if (download) {
      showNotification("Generating PDF...", "quSandBoxColor");
    }
    const formData = new FormData();
    // append writing_id, markdown, template
    formData.append("writing_id", writingId);
    formData.append("markdown", markdown);
    formData.append("template_name", template);

    try {
      const data = await convertToPDFAPI(formData);
      console.log(data);
      const url = data;
      // change the name of the file to writing_name
      setPdfUrl(url);
      console.log(url);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", writing.writing_name + ".pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        showNotification("PDF generated successfully", "success");
      }
    }
    catch (error) {
      showNotification(error, "danger");
    }
    finally {
    }
  };

  const templateOptions = [
    "academic",
    "business",
    "technical",
    "whitepaper",
    "presentation",
  ]

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress color="quCreateColor" />
        <Typography variant="h6" style={{ marginLeft: 16 }}>
          Loading Writing...
        </Typography>
      </Box>
    );
  }

  const handleChangeVersion = (event) => {
    setLoading(true);
    const selectedVersion = event.target.value;
    setVersion(selectedVersion);

    // Optionally find and use the selected version's details
    const selectedOption = versionOptions.find(option => option.version === selectedVersion);
    if (selectedOption) {
      console.log("Selected Version Details:", selectedOption);

      // Example: Update outline or fetch corresponding data
      setOutline(selectedOption.writing_outline);
      getPdf(selectedOption.writing_outline);
    }
    setLoading(false);
  };

  const handleRefreshContent = async (outline, download) => {
    setPdfLoading(true);
    await getPdf(outline, download);
    setPdfLoading(false);
  };

  const handleWritingBack = () => {
    window.history.back();
  }


  return (
    <div>
      {alert}
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
        }}
      >
        <Typography variant="h5">{identifierPrint}</Typography>
      </div>

      <Divider />
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
        }}
      >
        <div>
          <Typography variant="h6">{writing.writing_name}</Typography>
          <Typography variant="body1">{writing.writing_description}</Typography>
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "20px", // Add spacing between dropdowns
          flexWrap: "wrap", // Wrap items for smaller screens
          marginLeft: "auto",
        }}>
          {/* Dropdown to select the version type */}
          <FormControl style={{ minWidth: "180px" }} variant="filled"> {/* Ensure consistent width */}
            <InputLabel>Select Version</InputLabel>
            <Select
              value={version}
              renderValue={(selected) => {
                // Display only the version number when the dropdown is not open
                const selectedOption = versionOptions.find(option => option.version === selected);
                return selectedOption ? `Version ${selectedOption.version}` : "";
              }}
              onChange={handleChangeVersion}
              fullWidth
              disableUnderline
            >
              {versionOptions.map((option, index) => (
                <MenuItem key={index} value={option.version}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1"> {"Version: " + option.version}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" style={{ fontStyle: "italic" }}>
                        {"Last Updated: " + format(new Date(option.timestamp), "dd MMM yyyy, hh:mm a")}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Dropdown to select the template type */}
          <FormControl style={{ minWidth: "180px" }} variant="filled"> {/* Ensure consistent width */}
            <InputLabel>Select Template</InputLabel>
            <Select
              value={template}
              onChange={(e) => { setTemplate(e.target.value); getPdf(outline, false); }}
              fullWidth
              disableUnderline
            >
              {templateOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>


      </div>
      <Divider />
      <Grid container spacing={2} style={{
        position: 'relative', marginTop: "10px",
        marginBottom: "10px",
      }}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div
            data-color-mode="light"
            style={{
              marginTop: "20px",
            }}
          >
            {version === latestVersion ? (
              <MDEditor
                preview="edit"
                value={outline}
                height={600}
                onChange={(value) => setOutline(value)}
              />
            ) : (
              <MDEditor
                preview="preview"
                value={outline}
                height={600}
                readOnly={true}
              />
            )}
          </div>
        </Grid>

        {/* Floating Button */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Button
            onClick={() => handleRefreshContent(outline, false)} // Replace with your update function
            color="quSandBoxColor"
            variant="contained"
            disabled={version !== latestVersion}
            style={{
              borderRadius: "50%",
              padding: "12px"
            }}
          >
            <RotateLeft />
          </Button>
        </div>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          {pdfLoading ? (
            // Show a loader if pdfUrl is not available
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </div>
          ) : (
            // Render the PDFViewer once pdfUrl is available
            pdfUrl && <PDFViewer pdfUrl={pdfUrl} scrollView={true} height={580} width={""} />
          )}
        </Grid>
      </Grid>

      <div
        style={{
          marginTop: "10px",
        }}
      >

        <Button
          onClick={() => getPdf(outline, true)}
          color="quSandBoxColor"
          style={{
            float: "right",
          }}
        >
          <CloudDownload /> &nbsp;Download
        </Button>

        <Button
          onClick={() => { saveChangesAlert() }}
          color="quCreateColor"
          disabled={version !== latestVersion}
          style={{
            float: "right",
          }}
        >
          <SaveIcon /> &nbsp;Save Changes
        </Button>

        <Button
          color="quCreateColor"
          onClick={handleWritingBack}
          style={{
            float: "left",
            fontWeight: "bold",
          }}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </div>
    </div>
  );
}
