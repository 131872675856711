import React, { useEffect } from "react";
import {
    Route,
    Switch,
    Link,
    useRouteMatch,
} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { createLabAPI, fetchAllLabsAPI, deleteLabAPI } from "views/APIs/APIUtility";
import SearchBar from "material-ui-search-bar";
import DeleteIcon from "@material-ui/icons/Delete";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import {
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Tooltip,
    Typography,
    Box,
} from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationContext from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Heading from "components/Heading/Heading";
import { CloudUpload, Create, DoneAll } from "@material-ui/icons";
import CreateCourseCard from "views/Components/CreateCourseCard";


const useStyles = makeStyles(styles);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);
const usedashboardStyle = makeStyles(dashboardStyle);


export default function LabsDesign() {
    const images = require.context("assets/img", true);

    // state variables
    const [alert, setAlert] = React.useState(null);
    const { path, url } = useRouteMatch();

    const [modal, setModal] = React.useState(false);
    const [name, setName] = React.useState('');
    const [brief, setBrief] = React.useState('');
    const [iconImage, setIconImage] = React.useState();
    const [searchValue, setSearchValue] = React.useState("");
    const [allLabs, setAllLabs] = React.useState([]);
    const [initialLabs, setInitialLabs] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [currentDialog, setCurrentDialog] = React.useState("Create Lab");
    const [template, setTemplate] = React.useState("# Instructions\n- Number of modules: 5\n- Course requirements: Create an engaging and comprehensive lab outline that contains 5 modules.\n- Module structure:\n  - Description: Provide a brief summary of each module that encapsulates the main topics and key takeaways.\n  - Learning objectives: Include a list of specific, actionable objectives for each module. Clearly outline what learners will gain or accomplish by completing the module.\n- Objective focus: Ensure that learning objectives are concise, measurable, and relevant to the overall lab content.");
    const [courseOutline, setCourseOutline] = React.useState("# Module 1: **On Machine Lear");
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    // const dialogStages = ["Create Course", "Upload", "Select Template", "Update Outline", "Submit", "Creating Course"];
    const dialogStages = ["Create Lab"];
    const { showNotification } = React.useContext(NotificationContext);
    const [courseCreationLoader, setCourseCreationLoader] = React.useState(false);

    // classes and styles
    const dashboardClasses = usedashboardStyle();
    const classes = useStyles();
    const sweetAlertClasses = usesweetAlertStyle();

    const handleIconUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setIconImage(file);
        }
    };


    const handleDeleteLab = async (labData) => {
        const formData = new FormData();
        formData.append("lab_id", labData._id);

        try {
            const data = await deleteLabAPI(formData);
            setAllLabs(allLabs.filter((lab) => lab._id !== labData._id));
        }
        catch (error) {
            showNotification(error, "danger");
        }
        finally {
            hideAlert();
        }
    }

    const handleCloneCourse = (labData) => {
        const formData = new FormData();
        formData.append("lab_id", labData._id);

        fetch(process.env.REACT_APP_FASTAPI_URL + "/clone_lab",
            {
                method: "POST",
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setAllLabs([...allLabs, data]);
            })
            .catch((error) => {
                showNotification(error, "danger");
            })
            .finally(() => {
            });
        hideAlert();
    }

    const deleteAlert = (labData) => {
        console.log("Lab Data is: ", labData);
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleDeleteLab(labData)}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.success
                }
                cancelBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.danger
                }
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                This action will permanantly delete the lab and will not be recoverable.
            </SweetAlert>
        );
    };

    const cloneAlert = (labData) => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleCloneCourse(labData)}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.success
                }
                cancelBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.danger
                }
                confirmBtnText="Yes, clone it!"
                cancelBtnText="Cancel"
                showCancel
            >
                This action will create a clone of the current lab.
            </SweetAlert>
        );
    };


    useEffect(() => {
        const fetchData = async () => {
            // Fetch all labs
            try {
                const data = await fetchAllLabsAPI();
                setAllLabs(data);
                setInitialLabs(data);
            } catch (error) {
                showNotification(error, "danger");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const [courseOutlineLoader, setCourseOutlineLoader] = React.useState(false);

    const generateCourseOutline = () => {
        setCourseOutlineLoader(true);
        const formData = new FormData();
        uploadedFiles.forEach((file) => {
            formData.append("files", file);
        });
        formData.append("instructions", template);


        fetch(process.env.REACT_APP_FASTAPI_URL + "/generate_course_outline",
            {
                method: "POST",
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setCourseOutline(data);
                setCourseOutlineLoader(false);
            })
            .catch((error) => {
                showNotification(error, "danger");
                setCourseOutlineLoader(false);
            });
    }


    async function fetchImageAsFile(imageUrl) {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], "qucreate_logo.png", { type: blob.type });
        return file;
    }

    const handleCreateLab = async () => {
        setCourseCreationLoader(true);
        const formData = new FormData();
        formData.append("lab_name", name);
        formData.append("lab_description", brief);
        formData.append("lab_outline", courseOutline);
        uploadedFiles.forEach((file) => {
            formData.append("files", file);
        });
        formData.append("lab_image", iconImage || await fetchImageAsFile(images("./qucreate_logo.png")));

        try {
            const data = await createLabAPI(formData);
            setAllLabs([...allLabs, data]);
            showNotification("Lab created successfully", "success");
            setCourseCreationLoader(false);
        }
        catch (error) {
            showNotification(error, "danger");
            setCourseCreationLoader(false);
        }
        finally {
            handleClose();
        }
    }

    const hideAlert = () => {
        setAlert(null);
    };


    const clearCreateModal = () => {
        setName('');
        setBrief('');
        setIconImage(null);
        setUploadedFiles([]);
    };

    const handleClose = () => {
        setCurrentDialog("Create Lab");
        setModal(false);
        clearCreateModal();
    };

    const searchInputValue = (e) => {
        setSearchValue(e);
        if (e.length === 0) {
            setAllLabs(initialLabs); // Restore from initial courses
            return;
        }
        const newList = initialLabs.filter(
            (lab) =>
                (lab.course_name && lab.course_name.toLowerCase().includes(e.toLowerCase())) ||
                (lab.course_description &&
                    lab.course_description.toLowerCase().includes(e.toLowerCase()))
        );
        setAllLabs(newList);
    };

    const handleFileUpload = (files) => {
        setUploadedFiles(files);
    };


    const renderDialogContent = () => {
        switch (currentDialog) {
            case "Create Lab":
                return (
                    <div>
                        <Typography variant="h5" style={{
                            marginBottom: "10px"
                        }}>Enter the Lab details:</Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Lab Name"
                            type="text"
                            fullWidth
                            required
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="brief"
                            label="Brief Description"
                            type="text"
                            fullWidth
                            multiline
                            required
                            rows={3}
                            value={brief}
                            onChange={(event) => setBrief(event.target.value)}
                        />
                        {/* Icon Image Upload */}
                        <Button
                            variant="contained"
                            component="label"
                            color="info"
                            style={{ marginTop: '20px' }}
                        >
                            <CloudUpload /> &nbsp; Upload Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                required
                                onChange={handleIconUpload}
                            />
                        </Button>
                        {iconImage && (
                            <div style={{ marginTop: '10px' }}>
                                <strong>Uploaded Image:</strong> {iconImage.name}
                            </div>
                        )}
                        <Typography variant="caption" style={{ display: "block", marginTop: "5px" }}>
                            A 16:9 aspect ratio image is recommended
                        </Typography>
                    </div>
                );

            default:
                return null;
        }
    };


    const getStatusIcon = (status) => {
        switch (status) {
            case "In Design Phase":
                return (<Create style={{ color: "#435966" }} />)
            case "Ready for Publishing":
                return (<CloudUpload style={{ color: "#435966" }} />)
            case "Published":
                return (<DoneAll style={{ color: "#435966" }} />);
            default:
                return (<Create style={{ color: "#435966" }} />);

        }
    }

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <CircularProgress color="quCreateColor" />
                <Typography variant="h6" style={{ marginLeft: 16 }}>
                    Loading Labs...
                </Typography>
            </Box>
        );
    }

    return (
        <div>
            {alert}

            {/* Dialog Box for Adding new lab */}

            <Dialog open={modal} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                <DialogTitle id="form-dialog-title">
                    <div style={{ fontSize: '30px' }}>Create New Lab</div>
                </DialogTitle>
                <DialogContent>
                    {renderDialogContent()}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="quCreateColor"
                        disabled={courseOutlineLoader || courseCreationLoader}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateLab}
                        disabled={courseOutlineLoader || courseCreationLoader}
                        color="quCreateColor"
                    >
                        {/* {currentDialog === "Submit" ? "Submit" : "Next"} */}
                        {currentDialog === "Create Lab" ? "Submit" : "Next"}
                    </Button>

                </DialogActions>
            </Dialog>


            {/* Actual Page starts here */}
            <Switch>
                <Route exact path={path}>
                    <GridContainer>
                        <GridItem xs={12} sm={8} md={8} lg={10}>
                            <SearchBar
                                value={searchValue}
                                onChange={(newValue) => searchInputValue(newValue)}
                                onRequestSearch={(newValue) => searchInputValue(newValue)}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={4}
                            md={4}
                            lg={2}
                            style={{ textAlign: "center" }}
                        >
                            <Button color="quCreateColor" fullWidth onClick={() => setModal(true)}>
                                Create Lab
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        {allLabs.length === 0 &&
                            <GridItem xs={12} sm={12} md={12} lg={12} style={{
                                marginTop: "20px",
                            }}>
                                <Typography variant="h5">No labs found. Create a new lab to get started.</Typography>
                            </GridItem>
                        }

                        {allLabs.map((lab, index) => (
                            <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                                <Link
                                    to={{
                                        pathname: `${url}/lab/${lab._id}`,
                                        // pathname: path + "/lab/" + lab._id,
                                    }}
                                >
                                    <Card style={{ minHeight: "245px", maxHeight: "245px", marginTop: "40px" }}>
                                        <CardHeader stats icon>
                                            <CardIcon color="quCreateColor">
                                                {lab.lab_name}
                                            </CardIcon>
                                            <h3 className={classes.cardTitle} style={{
                                                zIndex: 100,
                                            }}>
                                                <small>
                                                    <img
                                                        // src={images("./qusandbox_logo_v2.5.png")}
                                                        src={lab.lab_image}
                                                        alt=""
                                                        className={classes.thumbnail}

                                                    />
                                                </small>
                                            </h3>
                                        </CardHeader>
                                        <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }} />
                                        <CardFooter>
                                            <Tooltip
                                                id="tooltip-top"
                                                title={lab.lab_description}
                                                placement="bottom"
                                                classes={{ tooltip: dashboardClasses.tooltip }}
                                            >
                                                <h5
                                                    style={{
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                        width: "100%",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {lab.lab_description
                                                        .split(" ")
                                                        .slice(0, 10)
                                                        .join(" ") + (lab.lab_description.split(" ").length > 10 ? "..." : "")}
                                                </h5>
                                            </Tooltip>
                                        </CardFooter>
                                        <CardFooter stats style={{ marginTop: "0px", paddingBottom: "10px" }}>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                            }}>
                                                {/* <FileCopyIcon
                                                        style={{ color: "#435966" }}
                                                        onClick={(e) => e.preventDefault(cloneAlert(lab))}
                                                    /> */}
                                                <p>
                                                    {getStatusIcon(lab.status)} &nbsp;
                                                    {lab.status}
                                                </p>
                                                <DeleteIcon
                                                    style={{
                                                        color: "#435966",
                                                    }}
                                                    onClick={(e) => e.preventDefault(deleteAlert(lab))}
                                                />
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                        ))}
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CreateCourseCard onClick={() => setModal(true)} />
                        </GridItem>
                    </GridContainer>
                </Route>
            </Switch>

        </div >
    );
}