import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { Divider, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import ModuleInformationContentReview from "./ModuleInformationContentReview";
import SlidesStructureReview from "../SlidesStructureReview";
import LabstructureReview from "./LabsStructureReview";
import { Auth } from "aws-amplify";
import NotificationContext from "views/Components/Context.js";

export default function StructureReview(props) {
    const { showNotification } = React.useContext(NotificationContext);
    const courseID = props.courseID;
    const moduleID = props.moduleID;
    const [user, setUser] = useState();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("Module Information");

    const [moduleInformation, setModuleInformation] = useState([]);
    const [slides, setSlides] = useState([]);
    const [labs, setLabs] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true);

    const setContentReviewFromCourse = (data) => {
        const modules = data.modules;
        const module = modules.find(module => module.module_id === moduleID);
        const resources = module.pre_processed_structure;
        const slides = resources.filter(resource => resource.resource_type === "Slide_Generated");
        const links = resources.filter(resource => resource.resource_type === "Link");
        const notes = resources.filter(resource => resource.resource_type === "Note");

        setSlides(slides);
        setLabs(links);
        setModuleInformation(notes);
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_FASTAPI_URL + `/get_course/${courseID}`)
            .then(res => res.json())
            .then(data => {
                setContentReviewFromCourse(data);
            })
            .catch(err => {
                console.log(err);
                showNotification("Error fetching course data", "danger");
            })
            .finally(() => {
                setModulesLoading(false);
            })
    }, [courseID]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch((err) => {
                if (err === "not authenticated") {
                    history.push("/auth/login-page");
                }
            });
    }, [history]);

    const renderContent = () => {
        switch (activeTab) {
            case "Module Information":
                return <ModuleInformationContentReview courseID={props.courseID} moduleID={props.moduleID} filetype="Note" moduleInformation={moduleInformation} course_design_step={5} />;
            case "Slides":
                return <SlidesStructureReview courseID={props.courseID} moduleID={props.moduleID} filetype="slides" slides={slides} />;
            case "Labs":
                return <LabstructureReview courseID={props.courseID} moduleID={props.moduleID} filetype="Link" links={labs} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Grid container display="flex" justify="space-between" style={{
                marginTop: "20px"
            }}>
                <Grid item xs={4}>
                    <Typography variant="body1" style={{
                        marginTop: "10px",
                        fontWeight: "bold",
                        fontSize: "20px"
                    }}>Structure Review</Typography>
                </Grid>
                <Grid item xs={8} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10px"
                }}>

                    <Button color="quSandBoxColor" style={activeTab === "Module Information" ? {
                        textDecoration: "underline"
                    } : {}} onClick={() => setActiveTab("Module Information")}>Module Information</Button>
                    <Button color="quSandBoxColor" style={activeTab === "Slides" ? {
                        textDecoration: "underline"
                    } : {}} onClick={() => setActiveTab("Slides")}>Slides</Button>
                    <Button color="quSandBoxColor" style={activeTab === "Labs" ? {
                        textDecoration: "underline"
                    } : {}} onClick={() => setActiveTab("Labs")}>Labs</Button>
                </Grid>
            </Grid>
            <Divider />

            {renderContent()}
        </div>

    );
}