import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js"; // Custom button component
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) => ({
    ...styles,
    radioChecked: {
        color: quCreateColor, // Override the color of the selected radio button (example: Tomato)
        '&.Mui-checked': {
            color: quCreateColor, // Override the selected color
        },
    },
    modalBox: {
        backgroundColor: theme.palette.background.paper,
        width: '90%',
        maxWidth: '800px',
        height: '70%',
        padding: theme.spacing(3),
        borderRadius: '12px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: theme.shadows[10],
        display: 'flex',
        flexDirection: 'column',
    },
    submitButton: {
        marginTop: '16px'
    },
    modalRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Ensures the modal takes full screen
        padding: theme.spacing(2),
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        width: '80%',
        maxWidth: '800px', // Max width of modal
    },
    dialogTitle: {
        fontWeight: '600',
        fontSize: '1.25rem',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    cancelButton: {
        marginTop: theme.spacing(2),
        textTransform: 'none',
    },
    table: {
        border: "1px solid #e0e0e0",
    }
}));

const SlideDeckModalBox = ({
    open,
    onClose,
    files,
    slideDeck,
    setSlideDeck,
    submitHandler,
}) => {
    const [step, setStep] = useState(1); // Tracks the current step
    const [generationOption, setGenerationOption] = useState(""); // Tracks generation option

    const classes = useStyles(); // Using the styles defined above

    // Reset the step to 1 when the modal opens
    useEffect(() => {
        if (open) {
            setStep(1); // Reset to step 1 when modal is opened
        }
    }, [open]);

    const handleSlideDeckChange = (file) => {
        setSlideDeck(file);
    };

    const handleNextStep = () => {
        if (slideDeck) {
            setStep(3); // Go directly to the message step (step 3)
        } else {
            setStep(2); // If no slide deck is selected, go to step 2
        }
    }

    return (
        <Modal open={open} onClose={onClose} className={classes.modalRoot}>
            <Box className={classes.modalContent}>
                {step === 1 && (
                    <>
                        <Typography variant='h5'>Generate Slide Deck</Typography>
                        <Divider />
                        <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "20px" }} >
                            Please select a slide deck to serve as the source file, or leave the fields blank to create a new slide deck from scratch.
                        </Typography>
                        {/* Table displaying files */}
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="center">Main Slide Deck</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{file.name}</TableCell>
                                        <TableCell align="center">
                                            <Radio
                                                checked={slideDeck === file}
                                                onChange={() => handleSlideDeckChange(file)}
                                                value={file.name}
                                                className={classes.radioChecked}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Box textAlign="center" width="100%" mt={3}>
                            <Button
                                onClick={handleNextStep}
                                color="quCreateColor"
                                className={classes.submitButton}
                                variant="contained"
                            >
                                Next
                            </Button>
                        </Box>
                    </>
                )}

                {step === 2 && (
                    <>
                        <Typography variant='h5'>Generate Slide Deck</Typography>
                        <Divider />
                        <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "20px" }} >
                            You have not selected any slide deck as the main slide deck. We will create a new slide deck based on the submitted materials.
                        </Typography>
                        <Box textAlign="center" width="100%" mt={3}>
                            <Button
                                onClick={() => setStep(1)} // Go back to file selection step
                                color="secondary"
                                className={classes.submitButton}
                                variant="outlined"
                            >
                                Back
                            </Button>
                            <Button
                                onClick={() => submitHandler(generationOption)} // Proceed with confirmation
                                color="quCreateColor"
                                className={classes.submitButton}
                                variant="contained"
                            >
                                Confirm
                            </Button>
                        </Box>
                    </>
                )}

                {step === 3 && (
                    <>
                        <Typography variant='h5'>Generate Slide Deck</Typography>
                        <Divider />
                        <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            We will be using the selected slide deck to generate speaker notes. <br />
                            The selected slide deck is: {slideDeck ? slideDeck.name : "Main slide deck"}
                        </Typography>
                        <Box textAlign="center" width="100%" mt={3}>
                            <Button
                                onClick={() => setStep(1)} // Go back to file selection step
                                color="secondary"
                                className={classes.submitButton}
                                variant="outlined"
                            >
                                Back
                            </Button>
                            <Button
                                onClick={() => submitHandler(generationOption)} // Proceed with confirmation
                                color="quCreateColor"
                                className={classes.submitButton}
                                variant="contained"
                            >
                                Confirm
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default SlideDeckModalBox;
