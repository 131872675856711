import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import { Icon } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
// import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const useStyles = makeStyles({
    ...styles,
    cardContainer: {
        minHeight: "245px",
        maxHeight: "245px",
        marginTop: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        transition: "all 0.3s ease",
    },
    button: {
        minHeight: "100%",
        minWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "18px",
    },
    cardBodyCentered: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
    iconWrapper: {
        width: "120px", // Circle diameter
        height: "120px", // Circle diameter
        borderRadius: "50%", // Circular shape
        backgroundColor: quCreateColor, // Adjust circle background color
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    // Make the icon occupy the full available space
    fullAreaIconFirstAndFourth: {
        fontSize: "100px",  // Adjust size as needed
        color: "#FFFFFF",   // White color

    },
    fullAreaIconSecondAndThird: {
        fontSize: "180px",  // Adjust size as needed
        color: quCreateColor,   // White color

    },
});

export default function CreateCourseCard({ onClick }) {
    const classes = useStyles();
    {/* First Card*/ }
    // return (

    //     < Card style={{ minHeight: "245px", maxHeight: "245px", marginTop: "40px" }
    //     } onClick={onClick} color="quCreateColor" >
    //         <CardHeader stats icon>
    //             {/* <CardIcon color="quSandBox">
    //                 Create a New Course
    //             </CardIcon> */}

    //         </CardHeader>
    //         <CardBody className={classes.cardBodyCentered}>
    //             <AddIcon className={classes.fullAreaIconFirstAndFourth} />
    //         </CardBody>

    //     </ Card >
    // );

    {/* Second Card*/ }
    // return (
    //     <Card style={{ minHeight: "245px", maxHeight: "245px", marginTop: "40px" }} onClick={onClick} color="#FFFFFF">
    //         <CardHeader stats icon>
    //             {/* <CardIcon color="quSandBox">
    //                 Create a New Course
    //             </CardIcon> */}

    //         </CardHeader>
    //         <CardBody className={classes.cardBodyCentered}>
    //             <AddIcon className={classes.fullAreaIconSecondAndThird} />
    //         </CardBody>

    //     </ Card>
    // );
    {/* Third Card*/ }
    // return (
    //     <Card style={{
    //         minHeight: "245px", maxHeight: "245px", marginTop: "40px", backgroundColor: "transparent",
    //         boxShadow: "none"
    //     }} onClick={onClick}>
    //         <CardHeader stats icon>
    //             {/* <CardIcon color="quSandBox">
    //                 Create a New Course
    //             </CardIcon> */}

    //         </CardHeader>
    //         <CardBody className={classes.cardBodyCentered}>
    //             <AddIcon className={classes.fullAreaIconSecondAndThird} />
    //         </CardBody>

    //     </ Card >
    // );
    {/* Fourth Card*/ }
    return (
        <Card style={{
            minHeight: "245px", maxHeight: "245px", marginTop: "40px", backgroundColor: "transparent",
            boxShadow: "none"
        }} onClick={onClick}>
            <CardHeader stats icon>
                {/* <CardIcon color="quSandBox">
                        Create a New Course
                    </CardIcon> */}

            </CardHeader>
            <CardBody className={classes.cardBodyCentered}>
                <div className={classes.iconWrapper}>
                    <AddIcon className={classes.fullAreaIconFirstAndFourth} />
                </div>
            </CardBody>

        </ Card >
    );

}
