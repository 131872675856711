import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar,
    Box,
    Grid,
} from '@material-ui/core';
import { Delete, Edit, FileCopy, Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px',
        boxSizing: 'border-box',
    },
    title: { marginBottom: '20px' },
    button: {
        marginBottom: '20px',
        padding: '15px 30px',
        fontSize: '1.2rem',
    },
    tableContainer: {
        width: '100%',
        maxHeight: '70vh',
        margin: '0 auto',
    },
    dialogContent: {
        width: '500px',
        maxWidth: '90vw',
    },
    dialogButtonSpacing: { margin: '10px 0' },
    urlCell: {
        wordBreak: 'break-word',
        maxWidth: '200px',
        textAlign: 'center',
    },
    centeredCell: {
        textAlign: 'center',
        padding: '16px', // Ensures equal padding inside each cell
    },
    rightAlignedTitleCell: {
        textAlign: 'right',
        padding: '16px', // Ensures equal padding inside each cell
    },
    rightAlignedCell: {
        textAlign: 'right',
        padding: '16px', // Ensures equal padding inside each cell
    },
});

export default function Labs(props) {
    const courseID = props.courseID;
    const moduleID = props.moduleID;
    const classes = useStyles();
    const [links, setLinks] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentLink, setCurrentLink] = useState({ name: "", url: "" });
    const [successMessage, setSuccessMessage] = useState('');
    const [nameError, setNameError] = useState(false);

    const setLinksForCard = () => {
        const card = props.links;

        if (card) {
            console.log("Card is: ", card)
            const updatedLinks = card.map((link) => {
                return {
                    name: link.resource_name,
                    url: link.resource_link,
                    description: link.resource_description,
                };
            });
            setLinks(updatedLinks);
        } else {
            console.log("Card not found");
        }
    };

    // Call setFilesForCard whenever the id changes
    useEffect(() => {
        setLinksForCard();
    }, []);

    const handleOpenModal = (link = { name: "", url: "" }, editMode = false) => {
        setCurrentLink(link);
        setIsEditMode(editMode);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setSuccessMessage('');
        setNameError(false);
    };

    const handleSaveLink = () => {
        if (!currentLink.name.trim()) {
            setNameError(true);
            return;
        }
        if (!isValidUrl(currentLink.url)) {
            alert("Please enter a valid URL.");
            return;
        }

        if (isEditMode) {
            setLinks(links.map(link => link.name === currentLink.name ? currentLink : link));
            setSuccessMessage('Link Updated Successfully');
        } else {
            setLinks([...links, currentLink]);
            setSuccessMessage('Link Added Successfully');
        }

        setOpen(false);
    };

    const handleDelete = (linkToDelete) => {
        setLinks(links.filter(link => link.name !== linkToDelete.name));
        setSuccessMessage('Link Deleted Successfully');
    };

    const isValidUrl = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z0-9+&@#\\/%?=~_|!:,.;]*)*$', 'i');
        return !!pattern.test(url);
    };

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        alert("Link copied to clipboard!");
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" style={{
                        marginTop: "20px"
                    }}>Final Labs</Typography>
                    <Typography variant="body1">
                        Here are the final codelabs generated for the module.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell><strong>Description</strong></TableCell>
                                    <TableCell className={classes.rightAlignedCell}><strong>Colab Link</strong></TableCell>
                                    {/* <TableCell><strong>Actions</strong></TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {links.map((link, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {link.name}
                                        </TableCell>
                                        <TableCell>
                                            {link.description}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell}>
                                            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                                        </TableCell>
                                        {/* <TableCell>
                                    <IconButton onClick={() => handleCopy(link.url)} color="primary">
                                        <FileCopy />
                                    </IconButton>
                                    <IconButton onClick={() => handleOpenModal(link, true)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(link)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={open} onClose={handleCloseModal} maxWidth="md">
                        <DialogTitle>{isEditMode ? 'Edit Link' : 'Add a New Link'}</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Box display="flex" flexDirection="column" gap={2}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    value={currentLink.name}
                                    onChange={(e) => {
                                        setCurrentLink({ ...currentLink, name: e.target.value });
                                        setNameError(!e.target.value.trim());
                                    }}
                                    error={nameError}
                                    helperText={nameError ? "Name is required" : ""}
                                />
                                <TextField
                                    margin="dense"
                                    label="URL"
                                    type="text"
                                    fullWidth
                                    value={currentLink.url}
                                    onChange={(e) => setCurrentLink({ ...currentLink, url: e.target.value })}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleSaveLink} color="primary">
                                {isEditMode ? 'Update' : 'Save'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </div>
    );
}
