import React, { useState, useEffect } from 'react';
import { Icon } from "@material-ui/core";
import NotificationContext from "views/Components/Context.js";
import ArtifactStructureReview from "./ArtifactStructureReview";
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar,
    Box,
    Grid,
} from '@material-ui/core';


import LinkTable from './LinkTable'; // Import the new table component

export default function LabsContentReview(props) {
    console.log("Props: ", props);
    const [labs, setlabs] = React.useState([]);
    const { showNotification } = React.useContext(NotificationContext);

    const setLinksForCard = () => {
        const card = props.links;

        if (card) {
            console.log("Card is: ", card)
            const updatedLinks = card.map((link) => {
                return {
                    name: link.resource_name,
                    url: link.resource_link,
                    description: link.resource_description,
                };
            });
            setlabs(updatedLinks);
        } else {
            console.log("Card not found");
        }
    };

    // Call setFilesForCard whenever the id changes
    useEffect(() => {
        setLinksForCard();
    }, []);
    const headerTitle = "Labs Content Review"
    const headerDescription = "Review the the codelab by downloading it and checking the content. You can replace the codelab by uploading another one in its place."

    return (
        <Grid container>
            {/* Render LinkTable with dynamic header and showNotification function */}
            <Grid item xs={12} sm={12}>
                <LinkTable
                    labs={labs}
                    title={headerTitle}
                    description={headerDescription}
                    showNotification={showNotification}
                />
            </Grid>
        </Grid>
    );
}