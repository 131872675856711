import React, { useState } from "react";
import NotificationContext from "views/Components/Context.js";
import { generateWritingOutlineAPI } from "views/APIs/APIUtility";
import Button from "components/CustomButtons/Button.js";
import {
    Grid,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,
    Divider,
    Stepper,
    Step,
    StepLabel
} from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone';
import { CloudUpload, Create, DoneAll } from "@material-ui/icons";
import MDEditor from '@uiw/react-md-editor';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { quCreateColor } from 'assets/jss/material-dashboard-pro-react';
import StepConnector from '@material-ui/core/StepConnector';

const useStyles = makeStyles({
    ...styles,
    dropzone: {
        minHeight: '100px',
        backgroundColor: '#f0f0f0',
        borderColor: quCreateColor,
        border: '2px dashed',
        borderRadius: '5px',
        padding: '20px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    previewText: {
        color: '#007BFF', // Change the text color
    },
    dropzoneText: {
        color: '#888888', // Grey color for instructions
    }
});

const QontoConnector = withStyles({
    active: {
        color: quCreateColor,
    },
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: quCreateColor,
        borderTopWidth: 3,
        borderRadius: 1,
    },
    stepLabel: {
        color: quCreateColor
    },
})(StepConnector);


export default function CreateWritingModal({ identifier, identifierPrint, newWritingDetails, setNewWritingDetails, writingId, setWritingId,
    createWritingModal, handleCreateWritingModalClose, handleCreateWriting, handleIconUpload, iconImage, writingCreationLoader, currentDialog, setCurrentDialog }) {
    const { showNotification } = React.useContext(NotificationContext);
    const classes = useStyles();

    const [outlineLoader, setOutlineLoader] = React.useState(false);
    const dialogStages = ["Create Writing", "Instructions & Files", "Update Outline", "Submit"];
    const stepperDisplayStages = ["Basic Details", "Instructions & Files", "Writing Outline"];
    const [activeStep, setActiveStep] = useState(0);



    const generateWritingOutlineFunction = async () => {
        setOutlineLoader(true);
        const formData = new FormData();
        newWritingDetails.uploadedFiles.forEach((file) => {
            formData.append("files", file);
        });
        formData.append("instructions", newWritingDetails.template);
        formData.append("identifier", identifier);

        try {
            const data = await generateWritingOutlineAPI(formData);
            const writing_id = data["writing_id"];
            const outline = data["writing"];
            setWritingId(writing_id);
            setNewWritingDetails({ ...newWritingDetails, outline: outline });

        }
        catch (error) {
            showNotification(error, "danger");
        }
        finally {
            setOutlineLoader(false);
        }
    }
    // handle next actions on dialogs
    const handleStep1 = () => {
        if (newWritingDetails.name === "" || newWritingDetails.brief === "") {
            showNotification("Please make sure all fields, including the " + { identifier } + " name and description, are properly filled out.", "danger");
            return;
        }
        setActiveStep(1);
        setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) + 1]);
    }

    const handleStep2 = () => {
        if (newWritingDetails.template === "") {
            showNotification("Please fill in the instructions for generating the " + { identifier } + " outline.", "danger");
            return;
        }
        generateWritingOutlineFunction();
        setActiveStep(2);
        setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) + 1]);
    }

    const handleStep3 = () => {
        if (newWritingDetails.outline === "") {
            showNotification("You need to have a " + identifierPrint, "danger");
            return;
        }
        setActiveStep(3);
        setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) + 1]);
        handleCreateWriting();
    }

    const handleStep4 = () => {
        handleCreateWriting();
    }

    const handleFileUpload = (files) => {
        setNewWritingDetails({ ...newWritingDetails, uploadedFiles: files });
    };


    const renderDialogContent = () => {
        switch (currentDialog) {
            case "Create Writing":
                return (
                    <div>
                        <Typography variant="h5" style={{
                            marginBottom: "10px"
                        }}>Enter the {identifierPrint} Details:</Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={identifierPrint + " Name"}
                            type="text"
                            fullWidth
                            required
                            value={newWritingDetails.name}
                            onChange={(event) => setNewWritingDetails({ ...newWritingDetails, name: event.target.value })}
                        />
                        <TextField
                            margin="dense"
                            id="brief"
                            label="Brief Description"
                            type="text"
                            fullWidth
                            multiline
                            required
                            rows={3}
                            value={newWritingDetails.brief}
                            onChange={(event) => setNewWritingDetails({ ...newWritingDetails, brief: event.target.value })}
                        />
                        {/* Icon Image Upload */}
                        <Button
                            variant="contained"
                            component="label"
                            color="info"
                            style={{ marginTop: '20px' }}
                        >
                            <CloudUpload /> &nbsp; Upload Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                required
                                onChange={handleIconUpload}
                            />
                        </Button>
                        {iconImage && (
                            <div style={{ marginTop: '10px' }}>
                                <strong>Uploaded Image:</strong> {iconImage.name}
                            </div>
                        )}
                        <Typography variant="caption" style={{ display: "block", marginTop: "5px" }}>
                            A 16:9 aspect ratio image is recommended
                        </Typography>
                    </div>
                );

            case "Instructions & Files":
                return (
                    <Grid container spacing={3} alignItems="flex-start" >
                        {/* Title for the Template Editing */}
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ marginBottom: "10px" }}>
                                Edit the following instructions to create the {identifierPrint} or add your own:
                            </Typography>
                        </Grid>

                        {/* MDEditor for Template Editing */}
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <div data-color-mode="light">
                                <MDEditor
                                    value={newWritingDetails.template
                                        .replace("{{courseName}}", newWritingDetails.name)
                                        .replace("{{courseDescription}}", newWritingDetails.brief)}
                                    onChange={(event) => setNewWritingDetails({ ...newWritingDetails, template: event })}
                                    height={250}
                                />
                            </div>
                        </Grid>

                        {/* Title for File Upload */}
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <Typography variant="h5" style={{ marginBottom: "10px", marginTop: "10px" }}>
                                Upload supporting files to generate the {identifierPrint} outline:
                            </Typography>
                        </Grid>

                        {/* DropzoneArea for File Upload */}
                        <Grid item xs={12}  >
                            <DropzoneArea
                                initialFiles={newWritingDetails.uploadedFiles}
                                onChange={handleFileUpload}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                useChipsForPreview
                                maxFileSize={50000000}
                                acceptedFiles={[
                                    "application/pdf",
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    "text/html",
                                    "text/x-c++src",
                                    "application/json",
                                    "text/markdown"
                                ]}
                                previewText="Uploaded files"
                                dropzoneText="Drag and drop files or click here."
                                filesLimit={100}
                                dropzoneClass={classes.dropzone}
                            />
                        </Grid>
                    </Grid>
                );
            case "Update Outline":
                return (
                    <div>
                        <Typography variant="h5" style={{ marginBottom: "10px" }}>{identifierPrint} outline:</Typography>
                        {outlineLoader ? (
                            <div style={{ display: "flex" }}>
                                <CircularProgress size={20} /> &nbsp;
                                <Typography variant="body1">Generating {identifierPrint}...</Typography>
                            </div>
                        ) : (
                            <div data-color-mode="light">
                                <MDEditor
                                    value={newWritingDetails.outline}
                                    onChange={(event) => setNewWritingDetails({ ...newWritingDetails, outline: event })}
                                    height={400}
                                />
                            </div>
                        )}
                    </div>
                );
            case "Submit":
                return (
                    <div style={{
                        display: "flex"
                    }}>
                        <CircularProgress size={20} /> &nbsp;
                        <Typography variant="body1">Creating {identifierPrint} for you...</Typography>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {alert}
            {/* Dialog Box for Adding new writing */}
            <Dialog open={createWritingModal} onClose={handleCreateWritingModalClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                {/* Stepper Component */}
                <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                    {stepperDisplayStages.map((label, index) => (
                        <Step key={index}>
                            <StepLabel >{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Divider />
                <DialogTitle id="form-dialog-title">
                    <div style={{ fontSize: '30px' }}>Create New {identifierPrint}</div>
                </DialogTitle>
                <DialogContent>
                    {renderDialogContent()}
                </DialogContent>
                <DialogActions>
                    {
                        currentDialog !== "Create Writing" && (
                            <Button
                                onClick={() => {
                                    setActiveStep(activeStep - 1);
                                    setCurrentDialog(dialogStages[dialogStages.indexOf(currentDialog) - 1]);
                                }}
                                color="quSandBoxColor"
                                disabled={outlineLoader || writingCreationLoader}
                            >
                                Back
                            </Button>
                        )
                    }

                    <Button onClick={handleCreateWritingModalClose} color="quSandBoxColor" disabled={outlineLoader || writingCreationLoader}>Cancel</Button>
                    <Button
                        onClick={() => {
                            switch (currentDialog) {
                                case "Create Writing":
                                    return handleStep1();
                                case "Instructions & Files":
                                    return handleStep2();
                                case "Update Outline":
                                    return handleStep3();
                                case "Submit":
                                    return handleStep4();
                                default:
                                    return null;
                            }
                        }
                        }
                        disabled={outlineLoader || writingCreationLoader}
                        color="quSandBoxColor"
                    >
                        {currentDialog === "Update Outline" ? "Submit" : "Next"}
                    </Button>

                </DialogActions>
            </Dialog>
        </div >
    );
}