export const fetchContentAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/fetch_note`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (err) {
        throw new Error(`Error fetching file: ${err.message}`);
    }
};

export const replaceResourceAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/replace_resources_in_module`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Replace: ${error.message}`);
    }
};

export const replaceLectureResourceAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/replace_resources_in_lecture`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Replace: ${error.message}`);
    }
};

export const deleteResourceAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/delete_resources_from_module`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Delete: ${error.message}`);
    }
};

export const deleteLectureResourceAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/delete_resources_from_lecture`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Delete: ${error.message}`);
    }
};

export const addResourceToModuleAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/add_resources_to_module`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in adding resource to module: ${error.message}`);
    }
};

export const addToWaitlistAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/add_user_to_project_waitlist`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Add to Waitlist: ${error.message}`);
    }
};

export const createCourseAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/create_course`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Course: ${error.message}`);
    }
};

export const fetchCourseAPI = async (courseID) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/get_course/${courseID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Course Details: ${error.message}`);
    }
};

export const createLectureAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/create_lecture`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Lecture: ${error.message}`);
    }
};



export const createLabAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/create_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Lab: ${error.message}`);
    }
};

export const fetchLectureAPI = async (lectureID) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/get_lecture/${lectureID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Lecture: ${error.message}`);
    }
};

export const fetchAllLecturesAPI = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/lectures`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Lectures: ${error.message}`);
    }
};


export const fetchAllLabsAPI = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/labs`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Lectures: ${error.message}`);
    }
};

export const deleteLectureAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/delete_lecture`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Lecture: ${error.message}`);
    }
};



export const deleteLabAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/delete_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Lab: ${error.message}`);
    }
};


export const addResourceToLectureAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/add_resources_to_lecture`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in adding resource to Lecture: ${error.message}`);
    }
};

export const fetchPDFAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/fetch_pdf`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response;
    } catch (err) {
        throw new Error(`Error fetching file: ${err.message}`);
    }
};

export const submitModuleForContentGeneration = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/submit_module_for_content_generation`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error submitting module": ${error.message}`);
    }
};

export const generateCourseOutlineAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/generate_course_outline`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating course outline": ${error.message}`);
    }
};

export const createWritingAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/create_writing`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error creating writing ${error.message}`);
    }
}

export const saveLatestVersionAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/save_writing`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving to latest version": ${error.message}`);
    }
};

export const fetchAllWritingsAPI = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/writings`, {
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Writings: ${error.message}`);
    }
};

export const fetchWritingAPI = async (writingId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/get_writing/${writingId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Writing: ${error.message}`);
    }
};

export const convertToPDFAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/convert_to_pdf`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Converting to PDF": ${error.message}`);
    }
};

export const deleteWritingAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/delete_writing`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error deleting writing ${error.message}`);
    }
}

export const addArtifactToCourse = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/add_artifact_to_course`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error adding external entity to course: ${error.message}`);
    }
}

{/* Labs Designer APIs*/ }
export const fetchLabAPI = async (labID) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/get_lab/${labID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Lab: ${error.message}`);
    }
};


export const addResourceToLabAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/add_resources_to_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in adding resource to Lab: ${error.message}`);
    }
};

export const replaceLabResourceAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/replace_resources_in_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Replacing Resource in Labs: ${error.message}`);
    }
};

export const deleteLabResourceAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/delete_resources_from_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Resource from Labs: ${error.message}`);
    }
};


export const saveBusinessUseCaseAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/save_business_use_case`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving business use case": ${error.message}`);
    }
}


export const saveTechnicalSpecificationsAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/save_technical_specifications`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error saving business use case": ${error.message}`);
    }
}


export const regenerateFeedbackAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/regenerate_with_feedback`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        throw new Error(`Error Regenerating Feedback: ${error.message}`);
    }
}


export const convertToPDFForLabAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/convert_to_pdf_for_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        throw new Error(`Error Converting to PDF for Lab: ${error.message}`);
    }
}
export const generateBusinessUseCaseAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/generate_business_use_case_for_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Replacing Resource in Labs: ${error.message}`);
    }
};


export const generateTechnicalSpecificationsAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/generate_technical_specifications_for_lab`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Replacing Resource in Labs: ${error.message}`);
    }
}

export const saveLabInstructionsAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/save_lab_instructions`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error Replacing Resource in Labs: ${error.message}`);
    }
};

export const generateWritingOutlineAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/writing_outline`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating outline: ${error.message}`);
    }
}


export const createPodcastAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/create_podcast`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Creating Podcast: ${error.message}`);
    }
};

export const fetchAllPodcastsAPI = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/podcasts`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching All Podcasts: ${error.message}`);
    }
};

export const generatePodcastOutlineAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/generate_podcast_outline`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating podcast outline": ${error.message}`);
    }
};

export const fetchPodcastAPI = async (podcastID) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/get_podcast/${podcastID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Fetching Podcast Details: ${error.message}`);
    }
};

export const deletePodcastAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/delete_podcast`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error in Deleting Podcast: ${error.message}`);
    }
};

export const convertToAudioAPI = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/generate_audio_for_podcast`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error generating podcast audio": ${error.message}`);
    }
};
