// React and Hooks
import React, { useState } from "react";

// Material-UI Components
import { Divider, Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { Create, Share, Publish, List, School } from "@material-ui/icons";

// React Router
import { Link } from "react-router-dom";

// Custom Components and Styles
import CreationOptionCard from "../Components/CreationOptionCard";
import { quSandBoxColor } from "assets/jss/material-dashboard-pro-react";

const LandingPage = () => {
  const [activeTab, setActiveTab] = useState(0); // Track active tab
  const groups = ["Create", "Publish", "Share", "Research", "All"]; // Tab labels including "All"

  const cardData = [
    {
      logo: require("assets/img/create_cards/course.png"),
      title: "Course",
      description:
        "Create and design engaging educational courses to share knowledge.",
      identifier: "courses",
      inProgress: false,
      icons: true,
      route: "/admin/coursedesign",
      group: "Create",
    },
    {
      logo: require("assets/img/create_cards/labs.png"),
      title: "Labs",
      description:
        "Create interactive labs to provide hands-on experience to students.",
      identifier: "labs",
      inProgress: false,
      route: "/admin/labdesign",
      icons: true,
      group: "Create",
    },
    {
      logo: require("assets/img/create_cards/summarize.png"),
      title: "Summarize",
      description:
        "Create concise summaries to share key information and insights.",
      identifier: "summarize",
      inProgress: true,
      icons: true,
      group: "Research",
    },
    {
      logo: require("assets/img/create_cards/lecture.png"),
      title: "Lecture",
      description: "Create engaging lectures to share knowledge and insights.",
      identifier: "lecture",
      inProgress: false,
      icons: true,
      route: "/admin/lecturedesign",
      group: "Create",
    },
    {
      logo: require("assets/img/create_cards/benchmark.png"),
      title: "Benchmark",
      description:
        "Create benchmark reports to compare performance and identify trends.",
      identifier: "benchmark",
      inProgress: true,
      icons: true,
      group: "Research",
    },
    {
      logo: require("assets/img/create_cards/ask.png"),
      title: "Ask",
      description:
        "Create surveys and questionnaires to gather feedback and insights.",
      identifier: "ask",
      inProgress: true,
      icons: true,
      group: "Research",
    },
    {
      logo: require("assets/img/create_cards/research.png"),
      title: "Research",
      description:
        "Create research to share findings and insights with the world.",
      identifier: "research",
      inProgress: true,
      icons: true,
      group: "Research",
    },
    {
      logo: require("assets/img/create_cards/report.png"),
      title: "Research Report",
      description:
        "Create in-depth research reports to present data-driven insights.",
      identifier: "research_report",
      inProgress: false,
      route: "/admin/writings",
      group: "Publish",
    },
    {
      logo: require("assets/img/create_cards/whitepaper.png"),
      title: "White Paper",
      description:
        "Create detailed white papers to showcase expertise on critical topics.",
      identifier: "white_paper",
      inProgress: false,
      icons: true,
      route: "/admin/writings",
      group: "Publish",
    },
    {
      logo: require("assets/img/create_cards/blog.png"),
      title: "Blog",
      description:
        "Create impactful blogs to share thoughts and expertise with the world.",
      identifier: "blog",
      inProgress: false,
      route: "/admin/writings",
      group: "Publish",
    },
    {
      logo: require("assets/img/create_cards/newsletter.png"),
      title: "Newsletter",
      description:
        "Create engaging newsletters to share updates and insights with your audience.",
      identifier: "news_letter",
      inProgress: false,
      route: "/admin/writings",
      group: "Publish",
    },
    {
      logo: require("assets/img/create_cards/insights.png"),
      title: "Key Insights",
      description:
        "Create key insights to share valuable information and knowledge.",
      identifier: "key_insights",
      inProgress: false,
      route: "/admin/writings",
      group: "Share",
    },
    {
      logo: require("assets/img/create_cards/case-study.png"),
      title: "Case Study",
      description:
        "Create detailed case studies to showcase expertise and success stories.",
      identifier: "case_study",
      inProgress: false,
      route: "/admin/writings",
      group: "Publish",
    },
    {
      logo: require("assets/img/create_cards/handout.png"),
      title: "Handout",
      description:
        "Create informative handouts to share knowledge and insights.",
      identifier: "handout",
      inProgress: false,
      route: "/admin/writings",
      group: "Share",
    },
    {
      logo: require("assets/img/create_cards/project-plan.png"),
      title: "Project Plan",
      description:
        "Create comprehensive project plans to streamline workflows and objectives.",
      identifier: "project_plan",
      inProgress: false,
      route: "/admin/writings",
      group: "Create",
    },
    {
      logo: require("assets/img/create_cards/video.png"),
      title: "Video",
      description:
        "Create captivating videos to tell your story or share information.",
      identifier: "create_a_video",
      inProgress: true,
      icons: true,
      group: "Create",
    },
    {
      logo: require("assets/img/create_cards/e-book.png"),
      title: "E-Book",
      description:
        "Create compelling e-books to captivate readers and share ideas.",
      identifier: "e_book",
      inProgress: true,
      group: "Publish",
    },
    {
      logo: require("assets/img/create_cards/podcast.png"),
      title: "Take 5 Podcast",
      description:
        "Create insightful podcasts to engage your audience through audio storytelling.",
      identifier: "create_a_podcast",
      inProgress: false,
      route: "/admin/podcastdesign",
      group: "Create",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update active tab
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Tabs for Group Selection */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Create fontSize="small" /> Create
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Publish fontSize="small" /> Publish
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Share fontSize="small" /> Share
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <School fontSize="small" /> Research
            </span>
          }
        />
        <Tab
          label={
            <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <List fontSize="small" /> All
            </span>
          }
        />
      </Tabs>

      <Divider style={{ margin: "20px 0" }} />

      {/* Cards for the Active Tab */}
      {activeTab === 4 ? (
        // "All" Tab: Original grouped view
        groups.slice(0, 4).map((group) => (
          <div key={group}>
            <Typography
              variant="h5"
              style={{ color: quSandBoxColor, marginBottom: "10px" }}
            >
              {group}
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              {cardData
                .filter((card) => card.group === group)
                .map((card) => (
                  <Grid key={card.identifier} item xs={12} sm={6} md={4} lg={4}>
                    {!card.inProgress ? (
                      <Link
                        to={{
                          pathname: card.route,
                          state: { identifier: card.identifier },
                        }}
                      >
                        <CreationOptionCard
                          logo={card.logo}
                          title={card.title}
                          description={card.description}
                          identifier={card.identifier}
                          inProgress={card.inProgress}
                        />
                      </Link>
                    ) : (
                      <CreationOptionCard
                        logo={card.logo}
                        title={card.title}
                        description={card.description}
                        identifier={card.identifier}
                        inProgress={card.inProgress}
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
          </div>
        ))
      ) : (
        // Filtered view for individual tabs (Create, Publish, Share)
        <Grid container spacing={2}>
          {cardData
            .filter((card) => card.group === groups[activeTab])
            .map((card) => (
              <Grid key={card.identifier} item xs={12} sm={6} md={4} lg={4}>
                {!card.inProgress ? (
                  <Link
                    to={{
                      pathname: card.route,
                      state: { identifier: card.identifier },
                    }}
                  >
                    <CreationOptionCard
                      logo={card.logo}
                      title={card.title}
                      description={card.description}
                      identifier={card.identifier}
                      inProgress={card.inProgress}
                    />
                  </Link>
                ) : (
                  <CreationOptionCard
                    logo={card.logo}
                    title={card.title}
                    description={card.description}
                    identifier={card.identifier}
                    inProgress={card.inProgress}
                  />
                )}
              </Grid>
            ))}
        </Grid>
      )}
    </div>
  );
};

export default LandingPage;
