import Footer from "components/Footer/Footer";
import React from "react";

const TermsAndConditionsPage = () => {
  const globalStyle = {
    // set the font to open sans sans serif
    fontFamily: "Open Sans, sans-serif",
    // increase the font size of all by 1.2
  };

  const headerStyle = {
    backgroundColor: "#435966",
    color: "white",
    padding: "30px",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "bold",
  };

  const sectionStyle = {
    color: "#37423b",
    textAlign: "left",
    marginLeft: "15vw",
    marginRight: "15vw",
    fontSize: "1.1rem",
    fontFamily: "Arial, sans-serif",
    marginBottom: "50px",
  };

  const dividerStyle = {
    border: "0",
    borderTop: "2px solid black",
    marginTop: "-10px",
    marginBottom: "30px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const linkStyle = {
    color: "#435966",
    textDecoration: "underline",
    fontWeight: "bold",
  };

  const topicStyle = {
    color: "#435966",
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  return (
    <div style={globalStyle}>
      <div style={headerStyle}>Terms and Conditions</div>
      <div style={sectionStyle}>
        <section>
          <h2 style={topicStyle}>1. Scope and Applicability</h2>
          <hr style={dividerStyle} />
          <p>
            1. These terms govern the use of{" "}
            <a href="https://qucreate.qusandbox.com" style={linkStyle}>
              QuCreate
            </a>
            ,{" "}
            <a href="https://quskillbridge.qusandbox.com" style={linkStyle}>
              QuSkillbridge
            </a>
            , and{" "}
            <a href="https://modelrisk.qusandbox.com" style={linkStyle}>
              QuStudio
            </a>{" "}
            (collectively referred to as "Platforms"), products of
            QuantUniversity LLC ("Company").
          </p>
          <p>
            2. By accessing or using any of these Platforms, users agree to
            comply with these terms and conditions.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>2. Acceptable Use Policy</h2>
          <hr style={dividerStyle} />
          <p>
            1. Users agree to use the Platforms responsibly and legally,
            ensuring all uploaded content complies with applicable laws,
            including intellectual property and copyright regulations.
          </p>
          <p>2. Prohibited actions include, but are not limited to:</p>
          <ul>
            <li>
              Uploading, sharing, or using illegal, infringing, or unlicensed
              content;
            </li>
            <li>
              Generating or distributing deceptive, harmful, or prohibited
              content;
            </li>
            <li>
              Violating the Company’s Acceptable Use Policy, which users must
              acknowledge before using any Platform.
            </li>
          </ul>
        </section>

        <section>
          <h2 style={topicStyle}>3. User Responsibility</h2>
          <hr style={dividerStyle} />
          <p>
            1. Users represent and warrant that they own or have obtained all
            necessary rights, licenses, and permissions for any content uploaded
            or processed using the Platforms.
          </p>
          <p>
            2. Users indemnify QuantUniversity LLC, its affiliates, and products
            (
            <a href="https://qucreate.qusandbox.com" style={linkStyle}>
              QuCreate
            </a>
            ,{" "}
            <a href="https://quskillbridge.qusandbox.com" style={linkStyle}>
              QuSkillbridge
            </a>
            ,{" "}
            <a href="https://modelrisk.qusandbox.com" style={linkStyle}>
              QuStudio
            </a>
            ) from any claims, damages, or losses arising from their content or
            its use on the Platforms.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>4. Copyright and Intellectual Property</h2>
          <hr style={dividerStyle} />
          <p>
            1. Users are solely responsible for ensuring uploaded content
            complies with applicable copyright and intellectual property laws.
          </p>
          <p>
            2. QuantUniversity LLC does not monitor or validate the legality of
            user-uploaded content but reserves the right to remove or restrict
            access to flagged content.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>5. Platform Liability</h2>
          <hr style={dividerStyle} />
          <p>
            1. QuantUniversity LLC provides the Platforms as tools for content
            creation, professional development, and learning and holds no
            liability for the misuse of its Platforms or any content generated
            or processed through them.
          </p>
          <p>
            2. Users accept that QuantUniversity LLC is not responsible for
            verifying the accuracy, legality, or compliance of content.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>6. Compliance with Applicable Laws</h2>
          <hr style={dividerStyle} />
          <p>
            1. Users agree to comply with all applicable U.S. federal and state
            laws and regulations while using the Platforms.
          </p>
          <p>
            2. QuantUniversity LLC operates under the jurisdiction of U.S. laws
            and enforces compliance accordingly.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>7. Risk Mitigation and Proactive Measures</h2>
          <hr style={dividerStyle} />
          <p>
            1. Users acknowledge that the Platforms incorporate measures aligned
            with risk mitigation guidelines, including transparency, security,
            and data governance protocols.
          </p>
          <p>
            2. QuantUniversity LLC reserves the right to limit functionality or
            access to mitigate risks or ensure compliance with legal and
            regulatory requirements.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>8. Transparency and Notifications</h2>
          <hr style={dividerStyle} />
          <p>
            1. Users will be notified of updates to these terms to maintain
            compliance with regulatory standards and user transparency.
          </p>
          <p>
            2. QuantUniversity LLC commits to adhering to industry best
            practices in AI safety, transparency, and governance.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>9. Governing Law and Dispute Resolution</h2>
          <hr style={dividerStyle} />
          <p>
            1. These terms shall be governed by the laws of the United States
            and the state of Massachusetts, without regard to its conflict of
            law principles.
          </p>
          <p>
            2. Users waive the right to collective lawsuits and agree to resolve
            disputes through binding arbitration in Massachusetts.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>10. Disclaimer of Warranties</h2>
          <hr style={dividerStyle} />
          <p>
            1. QuantUniversity LLC provides the Platforms “as is” without any
            warranties of any kind, whether express or implied.
          </p>
          <p>
            12. The Company disclaims liability for errors, interruptions, or
            any unauthorized use of the Platforms.
          </p>
        </section>

        <section>
          <h2 style={topicStyle}>11. Limitation of Liability</h2>
          <hr style={dividerStyle} />
          <p>
            1. QuantUniversity LLC shall not be liable for any indirect,
            incidental, or consequential damages arising from the use of its
            Platforms.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;
