import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Card, CardActionArea, Grid, IconButton } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        fontFamily: 'Roboto, sans-serif',
    },
    activeCard: {
        backgroundColor: 'rgba(63, 81, 181, 0.1)',
        transition: 'background-color 0.3s, border 0.3s',
    },
    card: {
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: theme.shadows[3],
    },
    cardTitle: {
        fontSize: '1.1rem',
        fontWeight: 500,
        marginBottom: theme.spacing(1),
        wordWrap: 'break-word',
    },
    cardDescription: {
        fontSize: '0.9rem',
        color: theme.palette.text.secondary,
        wordWrap: 'break-word',
    },
    iconContainer: {
        width: 50,
        height: 50,
        backgroundColor: theme.palette.grey[200],
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
    },
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(3),
        justifyContent: 'space-evenly',
    },
}));


export default function DeliverableTiles(props) {
    const search = useLocation().search;
    const stage = new URLSearchParams(search).get("stage");
    const [active, setActive] = React.useState(props.active);

    useEffect(() => {
        if (stage) {
            setActive(parseInt(stage));
        }
    }, [stage]);

    const handleChange = (active) => {
        setActive(active);
    };

    const classes = useStyles();
    const { tabs } = props;

    const tabWidth = 3;
    const tabSpacing = 1;

    const tabButtons = (
        <Grid container spacing={tabSpacing}>
            {tabs.map((prop, key) => (
                <Grid item xs={12} sm={6} md={6} lg={tabWidth} key={key}>
                    <Card
                        className={`${classes.card} ${active === key ? classes.activeCard : ''}`}
                    >
                        <CardActionArea
                            onClick={() => {
                                handleChange(key);
                                if (prop.onClick) {
                                    prop.onClick(); // Call the onClick handler passed in props
                                }
                            }}
                        >
                            <CardBody>
                                <Grid container>
                                    <Grid item xs={2}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                        <prop.icon className={classes.tabIcon} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <h4 className={classes.cardTitle}>
                                            <b>{prop.heading}</b>
                                        </h4>
                                        <p>{prop.description}</p>
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </CardActionArea>

                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <div>
            {tabButtons}
        </div>
    );
}

DeliverableTiles.defaultProps = {
    active: 0,
};

DeliverableTiles.propTypes = {
    active: PropTypes.number,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            heading: PropTypes.string.isRequired,
            icon: PropTypes.object.isRequired,
            description: PropTypes.string.isRequired,
            onClick: PropTypes.func, // onClick prop
        })
    ).isRequired,
};
