import {
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import { CloudDownload, RotateLeft } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import PDFViewer from "views/Components/PDFViewer";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { convertToPDFForLabAPI } from "views/APIs/APIUtility";
import { format, formatISO } from "date-fns";
import { saveBusinessUseCaseAPI } from "views/APIs/APIUtility";
import { saveTechnicalSpecificationsAPI } from "views/APIs/APIUtility";
import { regenerateFeedbackAPI } from "views/APIs/APIUtility";
import { fetchLabAPI } from "views/APIs/APIUtility";
import { generateTechnicalSpecificationsAPI } from "views/APIs/APIUtility";

const identifiers = {
  research_report: "Research Report",
  white_paper: "White Paper",
  project_plan: "Project Plan",
  blog: "Blog",
  news_letter: "Newsletter",
  case_study: "Case Study",
  key_insights: "Key Insights",
  handout: "Handout",
  business: "Business Use Case",
  technical: "Technical Specification",
};
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function LabUseCase({
  projectID,
  projectType,
  setActiveStep,
  useCase,
  setLoadingTechnicalUseCase,
}) {
  const labID = projectID;
  console.log("lab id is:", labID);
  const sweetAlertClasses = usesweetAlertStyle();
  const [alert, setAlert] = React.useState(null);

  const urlIdentifier = {
    business:
      "https://qucoursify.s3.us-east-1.amazonaws.com/qu-writing-design/673e52fd9154ffce678b940c/pre_processed_deliverables/674f2b4e176394d39a0a176e.pdf",
    technical:
      "https://qucoursify.s3.us-east-1.amazonaws.com/qu-writing-design/673f86e59289fbfb28cecce9/pre_processed_deliverables/674f2a19e0763e38834efa69.pdf",
    // Add more mappings as needed
  };

  const [writing, setWriting] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");

  const { showNotification, setNotification } = React.useContext(
    NotificationContext
  );

  const [outline, setOutline] = useState("# Hello This is sample markdown");
  const [pdfUrl, setPdfUrl] = useState(urlIdentifier[useCase]);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [version, setVersion] = useState(1);
  const [latestVersion, setLatestVersion] = useState(1);
  const [versionOptions, setVersionOptions] = useState([
    {
      business_use_case: "",
      version: 1,
      timestamp: formatISO(new Date()),
    },
  ]);

  useEffect(() => {
    const fetchLabData = async () => {
      try {
        const data = await fetchLabAPI(labID);
        console.log("Data: ", data);
        if (useCase === "business") {
          setOutline(data.business_use_case);
          setPdfUrl(data.business_use_case_pdf);
          setVersionOptions(data.business_use_case_history);
          console.log("Printing latestVersion", data.business_use_case_history);
          const latestVersionObj = data.business_use_case_history[data.business_use_case_history.length - 1];  // Get the last version
          setVersion(latestVersionObj.version);
          setLatestVersion(latestVersionObj.version);  // Set the latest version
        } else {
          setOutline(data.technical_specifications);
          setPdfUrl(data.technical_specifications_pdf);
          setVersionOptions(data.technical_specifications_history);
          const latestVersionObj = data.technical_specifications_history[data.technical_specifications_history.length - 1];  // Get the last version
          setVersion(latestVersionObj.version);
          setLatestVersion(latestVersionObj.version);  // Set the latest version
        }
      } catch (error) {
        showNotification(error, "danger");
      } finally {
        setLoading(false);
        setPdfLoading(false);
      }
    };

    fetchLabData();
  }, [labID, useCase]);

  const handleSaveChanges = async () => {
    try {
      const formData = new FormData();
      formData.append("lab_id", labID);
      let data = {};
      if (useCase === "business") {
        formData.append("business_use_case", outline);
        data = await saveBusinessUseCaseAPI(formData);
        setPdfUrl(data["business_use_case_pdf"]);
      } else {
        formData.append("technical_specifications", outline);
        data = await saveTechnicalSpecificationsAPI(formData);
        setPdfUrl(data["technical_specifications_pdf"]);
      }
      console.log(data);

      hideAlert();
      showNotification("Changes saved successfully", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      showNotification(error, "danger");
    } finally {
    }
  };

  const saveChangesAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleSaveChanges()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, Save Changes!"
        cancelBtnText="Cancel"
        showCancel
      >
        The changes will be saved as the latest version. Are you sure you want
        to save these changes?
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const getPdf = async (markdown, download) => {
    if (download) {
      showNotification("Generating PDF...", "quSandBoxColor");
    }
    const formData = new FormData();
    formData.append("lab_id", labID);
    formData.append("markdown", markdown);
    if (useCase === "business") {
      formData.append("template_name", "business");
      formData.append("lab_design_step", 1);
    } else if (useCase === "technical") {
      formData.append("template_name", "technical");
      formData.append("lab_design_step", 2);
    }

    try {
      const data = await convertToPDFForLabAPI(formData);
      const url = data;
      setPdfUrl(url);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        if (useCase === "business") {
          link.setAttribute("download", "Business_Use_Case.pdf");
        } else if (useCase === "technical") {
          link.setAttribute("download", "Technical_Specifications.pdf");
        }
        document.body.appendChild(link);
        link.click();
        link.remove();
        showNotification("PDF generated successfully", "success");
      }
    } catch (error) {
      showNotification(error, "danger");
    } finally {
    }
  };

  const regenerateWithFeedback = async () => {
    try {
      const formData = new FormData();
      formData.append("content", outline);
      formData.append("feedback", feedback);

      const response = await regenerateFeedbackAPI(formData);
      setOutline(response);

      showNotification("Content regenerated successfully", "success");
    } catch (error) {
      showNotification(error, "danger");
    } finally {
    }
  };

  const LabUseCaseSubmitHandler = async () => {
    if (useCase === "business") {
      setLoadingTechnicalUseCase(true);
      const formData = new FormData();
      formData.append("lab_id", labID);
      await generateTechnicalSpecificationsAPI(formData);
      setLoadingTechnicalUseCase(false);
      setActiveStep(2);
    } else {
      showNotification(
        "Technical Specifications saved successfully. You will be notified when we create the application for you.",
        "success"
      );
    }
  };

  const templateOptions = [
    "academic",
    "business",
    "technical",
    "whitepaper",
    "presentation",
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleChangeVersion = (event) => {
    setLoading(true);
    const selectedVersion = event.target.value;
    setVersion(selectedVersion);

    // Optionally find and use the selected version's details
    const selectedOption = versionOptions.find(
      (option) => option.version === selectedVersion
    );
    if (selectedOption) {
      console.log("Selected Version Details:", selectedOption);
      if (useCase === "business") {
        setOutline(selectedOption.business_use_case);
        getPdf(selectedOption.business_use_case);
      } else {
        setOutline(selectedOption.technical_specifications);
        getPdf(selectedOption.technical_specifications);
      }
    }
    setLoading(false);
  };


  const handleRefreshContent = async (outline, download) => {
    setPdfLoading(true);
    await getPdf(outline, download);
    setPdfLoading(false);
  };


  return (
    <div>
      {alert}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <Typography variant="h5"> {identifiers[useCase]}</Typography>
          <Typography variant="body1">
            {" "}
            {useCase === "business"
              ? "Describe the business use case for the project."
              : "Describe the technical specifications for the project."}
          </Typography>
        </div>
        <div>
          <Button
            onClick={() => getPdf(outline, true)}
            color="quSandBoxColor"
            style={{
              float: "right",
            }}
          >
            <CloudDownload /> &nbsp;Download
          </Button>

          <Button
            onClick={() => {
              saveChangesAlert();
            }}
            color="quCreateColor"
            style={{
              float: "right",
            }}
            disabled={version !== latestVersion}
          >
            <SaveIcon /> &nbsp;Save Changes
          </Button>
          <FormControl
            style={{ minWidth: "180px", float: "right", marginRight: "10px" }}
            variant="filled"
          >
            {" "}
            {/* Ensure consistent width */}
            <InputLabel>Select Version</InputLabel>
            <Select
              value={version}
              renderValue={(selected) => {
                // Display only the version number when the dropdown is not open
                const selectedOption = versionOptions.find(
                  (option) => option.version === selected
                );
                return selectedOption
                  ? `Version ${selectedOption.version}`
                  : "";
              }}
              onChange={handleChangeVersion}
              fullWidth
              disableUnderline
            >
              {versionOptions.map((option, index) => (
                <MenuItem key={index} value={option.version}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {" "}
                        {"Version: " + option.version}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        style={{ fontStyle: "italic" }}
                      >
                        {"Last Updated: " +
                          format(
                            new Date(option.timestamp),
                            "dd MMM yyyy, hh:mm a"
                          )}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Divider />
      <Grid
        container
        spacing={2}
        style={{
          position: "relative",
          marginTop: "2px",
          marginBottom: "10px",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div
            data-color-mode="light"
            style={{
              marginTop: "20px",
            }}
          >
            {version === latestVersion ? (
              <MDEditor
                preview="edit"
                value={outline}
                height={600}
                onChange={(value) => setOutline(value)}
              />
            ) : (
              <MDEditor
                preview="preview"
                value={outline}
                height={600}
                readOnly={true}
              />
            )}

          </div>
        </Grid>

        {/* Floating Button */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Button
            onClick={() => handleRefreshContent(outline, false)} // Replace with your update function
            color="quSandBoxColor"
            variant="contained"
            style={{
              borderRadius: "50%",
              padding: "12px",
            }}
            disabled={version !== latestVersion}
          >
            <RotateLeft />
          </Button>
        </div>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          {pdfLoading ? (
            // Show a loader if pdfUrl is not available
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </div>
          ) : (
            // Render the PDFViewer once pdfUrl is available
            pdfUrl && <PDFViewer pdfUrl={pdfUrl} scrollView={true} height={580} width={""} />
          )}
        </Grid>
      </Grid>

      <>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" style={{ marginTop: "10px" }}>
            Feedback
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="body1"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Regenerate the output with your suggestions.
          </Typography>
        </Grid>
        {/* TextField for user input */}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter your feedback here..."
            style={{
              backgroundColor: "white",
              padding: "10px",
            }}
            // Uncomment the following line to enable feedback state
            onChange={(e) => setFeedback(e.target.value)}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {/* Regenerate Links Button */}
          <Button
            color="success"
            style={{
              float: "left",
            }}
            startIcon={<RotateLeft />}
            // Uncomment the following line to enable the click handler
            onClick={regenerateWithFeedback}
          >
            Regenerate
          </Button>
        </Grid>
      </>

      <div
        style={{
          marginTop: "10px",
        }}
      >
        {/* Next Button */}
        <Button
          color="quCreateColor"
          onClick={LabUseCaseSubmitHandler}
          style={{
            float: "right",
          }}
          endIcon={<NavigateNextIcon />}
        >
          {useCase === "business"
            ? "Generate Technical Specifications"
            : "Submit For Creation"}
        </Button>
      </div>
    </div>
  );
}
