import React, { useEffect } from 'react';
import { Grid, Icon, Typography } from "@material-ui/core";
import NotificationContext from "views/Components/Context.js";
import Button from "components/CustomButtons/Button.js";
import ArtifactStructureReview from "../ArtifactStructureReview";
import LinkTable from '../LinkTable'; // Import the new table component

export default function LabsStructureReview(props) {
    const [labs, setlabs] = React.useState([]);
    const { showNotification } = React.useContext(NotificationContext);

    const setLinksForCard = () => {
        const card = props.links;

        if (card) {
            console.log("Card is: ", card)
            const updatedLinks = card.map((link) => {
                return {
                    name: link.resource_name,
                    url: link.resource_link,
                    description: link.resource_description,
                };
            });
            setlabs(updatedLinks);
        } else {
            console.log("Card not found");
        }
    };

    // Call setFilesForCard whenever the id changes
    useEffect(() => {
        setLinksForCard();
    }, []);
    const headerTitle = "Labs Structure Review"
    const headerDescription = "Review the the codelab by downloading it and checking the content. You can replace the codelab by uploading another one in its place."


    return (
        <Grid container>
            {/* Render LinkTable with dynamic header and showNotification function */}
            <Grid item xs={12} sm={12}>
                <LinkTable
                    labs={labs}
                    title={headerTitle}
                    description={headerDescription}
                    showNotification={showNotification}
                />
            </Grid>
        </Grid>
    );
}