import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Dialog,
  Tooltip,
} from "@material-ui/core";
import { Delete, Visibility, GetApp, RestorePage } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import PDFViewer from "views/Components/PDFViewer";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",
    boxSizing: "border-box",
  },
  addButton: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    padding: "10px",
    margin: "10px",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
  title: { marginBottom: "20px" },
  tableContainer: {
    width: "100%",
    maxHeight: "70vh",
    margin: "0 auto",
  },
  dialogContent: {
    width: "500px",
    maxWidth: "90vw",
  },
  dialogButtonSpacing: { margin: "10px 0" },
  pdfViewer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  pdfPage: {
    margin: "10px 0",
  },
  headerCell: {
    padding: "26px", // Adjust for additional spacing if needed
  },
  tableCell: {
    maxWidth: "200px", // Add maxWidth to ensure text truncation
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center", // Center the buttons
    gap: "10px", // Space out buttons if needed
  },
});

const getFileNameWithoutExtension = (fileName) =>
  fileName.replace(/\.[^/.]+$/, "");

export default function FileGallery({
  files,
  handleDeleteResource,
  handleReplace,
}) {
  const classes = useStyles();

  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);

  const handlePreview = async (file) => {
    try {
      setPdfFile(file);
      setPdfModalOpen(true); // Open the modal for the PDF preview
    } catch (error) {
      window.open(file.url, "_blank");
    }
  };

  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.click();
  };

  const handlePdfClose = () => {
    setPdfModalOpen(false);
    setPdfFile(null);
  };

  return (
    <div className={classes.container}>
      {files.length === 0 && (
        <Typography variant="body1">
          No files uploaded. You can upload files using the + button above.
        </Typography>
      )}
      {files.length > 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Description</strong>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      title={file.name}
                      disableHoverListener={file.name.length < 20}
                    >
                      <div>
                        <span>{getFileNameWithoutExtension(file.name)}</span>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      title={file.description}
                      disableHoverListener={file.description.length < 20}
                    >
                      <div>
                        <span>{file.description}</span>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handlePreview(file)}
                      disabled={!file.url.endsWith(".pdf")}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleDownload(file)}>
                      <GetApp />
                    </IconButton>
                    <IconButton onClick={() => handleReplace(file, index)}>
                      <RestorePage />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteResource(file)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* PDF Preview Modal */}
      <Dialog
        open={pdfModalOpen}
        onClose={handlePdfClose}
        maxWidth="md"
        fullWidth
      >
        {pdfFile && (
          <PDFViewer
            pdfUrl={pdfFile.url}
            scrollView={false}
            showButtons={true}
          />
        )}
      </Dialog>
    </div>
  );
}
