import React, { useState, useEffect } from "react";
import { Card, Grid, Input, Select } from "@material-ui/core";

export default function ReviewAssessment(props) {
    const [quizQuestion, setQuizQuestion] = useState(props.question);
    const [quizOptions, setQuizOptions] = useState(props.options);
    const [quizAnswer, setQuizAnswer] = useState(props.answer);
    const [quizExplanation, setQuizExplanation] = useState(props.explanation);

    // Use effect to update local state if props change
    useEffect(() => {
        setQuizQuestion(props.question);
        setQuizOptions(props.options);
        setQuizAnswer(props.answer);
        setQuizExplanation(props.explanation);
    }, [props.question, props.options, props.answer, props.explanation]);

    // Handler to update quiz question
    const handleQuestionChange = (event) => {
        setQuizQuestion(event.target.value);
        props.setQuizData(event.target.value, 'question');
    };

    // Handler to update quiz explanation
    const handleExplanationChange = (event) => {
        setQuizExplanation(event.target.value);
        props.setQuizData(event.target.value, 'explanation');
    };

    // Handler to update quiz options
    const handleOptionChange = (index, event) => {
        const newOptions = [...quizOptions];
        newOptions[index] = event.target.value;
        setQuizOptions(newOptions);
        props.setQuizData(newOptions, 'options');
    };

    // Handler to update quiz answer
    const handleAnswerChange = (event) => {
        setQuizAnswer(event.target.value);
        props.setQuizData(event.target.value, 'answer_option');
    };

    return (
        <Card style={{ padding: "20px" }}>
            <Grid container spacing={3}>
                {/* Question input */}
                <Grid item xs={12}>
                    <label>Question</label>
                    <Input
                        variant="outlined"
                        fullWidth
                        onChange={handleQuestionChange}
                        value={quizQuestion}
                    />
                </Grid>

                {/* Answer Options */}
                <Grid item xs={12}>
                    <label>Options</label>
                    {quizOptions.map((option, index) => {
                        const optionLabel = String.fromCharCode(97 + index); // This will generate 'a', 'b', 'c', etc.
                        return (
                            <Grid container key={index} alignItems="center" style={{ marginBottom: "10px", display: 'flex' }}>
                                <Grid item style={{ paddingRight: '5px' }}>
                                    <label>{optionLabel}.</label>
                                </Grid>
                                <Grid item xs style={{ paddingLeft: '0' }}>
                                    <Input
                                        variant="outlined"
                                        placeholder={`Answer Option ${optionLabel}`}
                                        fullWidth
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, e)} // Handle change per option
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>

                {/* Correct Answer Selection */}
                <Grid item xs={12}>
                    <label>Correct Answer</label>
                    <Select
                        native
                        value={quizAnswer || ''}  // Make sure quizAnswer is a string (e.g., 'a', 'b', etc.)
                        onChange={handleAnswerChange}  // Handle change for correct answer selection
                        fullWidth
                        inputProps={{
                            name: 'correct_answer',
                            id: 'correct-answer-select',
                        }}
                    >
                        <option value="" disabled>Select the correct answer</option>
                        {Array.from({ length: quizOptions.length }).map((_, index) => {
                            const letter = String.fromCharCode(97 + index);  // Convert index to 'a', 'b', 'c', etc.
                            return (
                                <option key={index} value={letter}>
                                    {letter} {/* 'a', 'b', 'c', etc. */}
                                </option>
                            );
                        })}
                    </Select>
                </Grid>


                {/* Explanation input */}
                <Grid item xs={12}>
                    <label>Explanation</label>
                    <Input
                        variant="outlined"
                        placeholder="Explanation"
                        fullWidth
                        multiline
                        rows={6}
                        onChange={handleExplanationChange}
                        value={quizExplanation}
                    />
                </Grid>
            </Grid>
        </Card>
    );
}