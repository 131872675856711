export const moduleStages = [
    "In Design Phase",
    "In Content Generation Queue",
    "Content Review",
    "In Structure Generation Queue",
    "Structure Review",
    "In Deliverables Generation Queue",
    "Deliverables Review",
    "In Publishing Queue",
    "Published"
];

export const displayModuleStages = [
    "Resources",
    "Content Review",
    "Structure Review",
    "Deliverables"
];

export const lectureStages = [
    "In Design Phase",
    "In Content Generation Queue",
    "In Outline Review",
    "In Content Review Queue",
    "Content Review",
];