import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Icon } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import { CloudUpload } from '@material-ui/icons';

export default function FileUploadDialog({ open, onClose, newFile = {}, setNewFile, onSave, replace }) {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewFile({ ...newFile, file });
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{replace ? 'Replace File' : 'Add a New File'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="File Name"
                    type="text"
                    fullWidth
                    value={newFile?.name || ''}
                    onChange={(e) => setNewFile({ ...newFile, name: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Description"
                    type="text"
                    fullWidth
                    value={newFile?.description || ''}
                    onChange={(e) => setNewFile({ ...newFile, description: e.target.value })}
                />
                <Button variant="contained" component="label" color="quSandBoxColor" style={{ marginTop: '10px' }}>
                    <CloudUpload />
                    {replace ? "Replace" : "Upload"} File
                    <input type="file" hidden onChange={handleFileChange} />
                </Button>
                {newFile?.file && <Typography>{newFile.file.name}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="quSandBoxColor">Cancel</Button>
                <Button onClick={onSave} color="quSandBoxColor">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
