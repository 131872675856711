// templates.js

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import GestureIcon from '@material-ui/icons/Gesture';
import SchoolIcon from '@material-ui/icons/School';
import TableChartIcon from '@material-ui/icons/TableChart';

const templates = [
    {
        heading: "Financial Professional",
        icon: AttachMoneyIcon,
        content: `Create a professional module for financial professionals based on the resources provided. The module should consist of 8 slides, with each slide containing no more than 5 bullet points. Focus on essential concepts, practical insights, and actionable strategies relevant to financial professionals, presented in a polished, professional tone. For each slide, provide speaker notes that expand on the bullet points without any introductory or concluding remarks (e.g., avoid 'hello' or 'thank you').

Module Requirements:
Highlight key industry practices, regulations, and strategic insights that are relevant to financial professionals in a clear and concise format.
Ensure speaker notes add detailed context and depth to each bullet point, guiding the audience through complex financial topics.
Avoid expanding acronyms in the speaker notes to maintain an industry-standard tone.`
    },
    {
        heading: "Board Executives",
        icon: BusinessCenterIcon,
        content: `Design a professional module for board executives based on the provided resources. The module should include 8 slides, each with no more than 5 bullet points. The content should be focused on high-level insights, strategic considerations, and governance principles that are relevant to board executives. Each slide should maintain a direct, formal tone, and speaker notes should expand on the bullet points without any introductory or closing remarks (e.g., avoid 'hello' or 'thank you').

Module Requirements:
Emphasize strategic priorities, regulatory implications, and oversight responsibilities in concise, impactful bullet points.
Provide speaker notes with additional context for each bullet point, designed to inform executive decision-making.
Do not expand acronyms in the speaker notes, adhering to a high-level, executive tone throughout.`
    },
    {
        heading: "AI Professional",
        icon: GestureIcon,
        content: `Create a professional module tailored for AI professionals based on the resources provided. The module should consist of 8 slides, each containing no more than 5 bullet points. Focus on concepts and applications of AI in financial or strategic contexts, while maintaining a professional and technical tone. For each slide, provide speaker notes that expand on the bullet points without any introductory or closing remarks (e.g., avoid 'hello' or 'thank you').

Module Requirements:
Emphasize key trends, models, and applications of AI within financial or data-driven domains in concise bullet points.
Expand on each bullet point in the speaker notes with detailed context, offering insights relevant to AI professionals' technical expertise.
Avoid expanding acronyms in the speaker notes, maintaining a specialized tone appropriate for AI practitioners.`
    },
    {
        heading: "Quant Student",
        icon: SchoolIcon,
        content: `Develop a professional module for quantitative finance students using the resources provided. The module should consist of 8 slides, each containing no more than 5 bullet points. Focus on foundational concepts, mathematical models, and data-driven strategies used in quantitative finance, with a professional and educational tone. For each slide, provide speaker notes that expand on the bullet points without any introductory or closing remarks (e.g., avoid 'hello' or 'thank you').

Module Requirements:
Highlight core concepts, equations, and quantitative strategies relevant to the field of finance in clear, structured bullet points.
Use speaker notes to offer in-depth explanations for each bullet point, providing guidance that enhances a quant student's understanding of complex material.
Avoid expanding acronyms in the speaker notes, maintaining a specialized tone suited for quantitative finance education.`
    },
    {
        heading: "Analyst",
        icon: TableChartIcon,
        content: `Create a professional module for financial analysts based on the provided resources. The module should include 8 slides, with each slide containing no more than 5 bullet points. Focus on analytical techniques, data interpretation, and best practices relevant to financial analysis, presented in a professional tone. For each slide, provide speaker notes that expand on the bullet points without any introductory or concluding remarks (e.g., avoid 'hello' or 'thank you').

Module Requirements:
Highlight critical analytical tools, data-driven strategies, and industry standards in concise bullet points for each slide.
Ensure speaker notes add context and detail to each bullet point, providing actionable insights that an analyst can apply to their work.
Avoid expanding acronyms in the speaker notes, maintaining a technical, industry-specific tone throughout the module.`
    }
];

export default templates;
