import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
// import { withRouter } from "react-router-dom";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// import Tab from "@material-ui/core/Tab";
// import Tabs from "@material-ui/core/Tabs";
import { useLocation } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/components/tilesStyle.js";
import { Card, CardActionArea } from "@material-ui/core";
// import { Home } from "@material-ui/icons";
import CardBody from "components/Card/CardBody";
// import Button from "components/CustomButtons/Button.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(styles);

export default function Tiles(props) {
  // const myRef = React.useRef(null);
  // const { path, url, params } = useRouteMatch();
  const search = useLocation().search;
  const stage = new URLSearchParams(search).get("stage");
  const [active, setActive] = React.useState(props.active);
  useEffect(() => {
    if (stage) {
      setActive(parseInt(stage));
    }
  }, [stage]);

  const handleChange = (active) => {
    setActive(active);
  };
  const handleChangeIndex = (index) => {
    setActive(index);
  };
  const classes = useStyles();
  const { tabs, direction, color, horizontal, alignCenter } = props;
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined,
  });
  const tabButtons = (
    // <div ref={myRef}>
    <GridContainer spacing={3}>
      {tabs.map((prop, key) => {
        var icon = {};
        if (prop.tabIcon !== undefined) {
          icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
        }
        // const pillsClasses = classNames({
        //   [classes.pills]: true,
        //   [classes.horizontalPills]: horizontal !== undefined,
        //   [classes.pillsWithIcons]: prop.tabIcon !== undefined,
        // });
        return (
          <GridItem xs={12} sm={6} md={6} lg={3} key={key}>
            <Card style={{ position: "relative", marginTop: "30px" }}>
              <CardActionArea
                onClick={() => {
                  handleChange(key);
                  // if (myRef.current) {
                  //   myRef.current.scrollIntoView();
                  // }

                }}
              >
                <CardBody>
                  <GridContainer>
                    <GridItem className={classes.icon}>
                      <prop.icon className={classes.tabIcon} />
                    </GridItem>
                    <GridItem>
                      <h4 className={classes.cardTitle}>
                        <b>{prop.heading}</b>
                      </h4>
                      <p>{prop.description}</p>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </CardActionArea>
              {active === key && (
                <CheckCircleIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
            </Card>
          </GridItem>
        );
      })}
    </GridContainer>
    // </div>
    // </Tabs>
  );
  const tabContent = (
    <div
      className={classes.contentWrapper}
    // ref={myRef}
    >
      <SwipeableViews
        axis={direction === "rtl" ? "x-reverse" : "x"}
        index={active}
        onChangeIndex={handleChangeIndex}
        style={{ overflowY: "hidden" }}
      //   animateHeight={true}
      // animateTransitions={true}
      >
        {tabs.map((prop, key) => {
          if (key == active) {
            return (
              <div className={classes.tabContent} key={key}>
                {prop.tabContent}
              </div>
            );
          } else {
            return (<div></div>)
          }
        })}
      </SwipeableViews>
    </div>
  );

  const tabSummary = (
    // <div className={classes.contentWrapper}>
    <SwipeableViews
      axis={direction === "rtl" ? "x-reverse" : "x"}
      index={active}
      onChangeIndex={handleChangeIndex}
      style={{ overflowY: "hidden" }}
      animateHeight={true}
    // animateTransitions={true}
    // onTransitionEnd = {()=>{
    //   if (myRef.current) {
    //     myRef.current.scrollIntoView();
    //   }
    // }}
    >
      {tabs.map((prop, key) => {
        return (
          <div className={classes.tabContent} key={key}>
            {prop.summary}
          </div>
        );
      })}
    </SwipeableViews>
    // </div>
  );

  return horizontal !== undefined ? (
    <div>
      <GridItem {...horizontal.contentGrid}>{tabSummary}</GridItem>
      <GridContainer justify="center">
        <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      </GridContainer>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </div>
  ) : (
    <div>
      {tabSummary}
      {tabButtons}
      {tabContent}
    </div>
  );
}

Tiles.defaultProps = {
  active: 0,
  color: "primary",
};

Tiles.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node,
      summary: PropTypes.node,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object,
  }),
  alignCenter: PropTypes.bool,
};
