import React, { useState } from "react";
import {
  Grid,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import Button from "components/CustomButtons/Button.js";
import { CloudUpload } from "@material-ui/icons";
import NotificationContext from "views/Components/Context.js";
import { DropzoneArea } from "material-ui-dropzone";
import { generateCourseOutlineAPI } from "views/APIs/APIUtility";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { quCreateColor } from "assets/jss/material-dashboard-pro-react";
import SweetAlert from "react-bootstrap-sweetalert";
import StepConnector from "@material-ui/core/StepConnector";

const useStyles = makeStyles({
  ...styles,
  dropzone: {
    minHeight: "100px",
    backgroundColor: "#f0f0f0",
    borderColor: quCreateColor,
    border: "2px dashed",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  previewText: {
    color: "#007BFF", // Change the text color
  },
  dropzoneText: {
    color: "#888888", // Grey color for instructions
  },
});

const QontoConnector = withStyles({
  active: {
    color: quCreateColor,
  },
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: quCreateColor,
    borderTopWidth: 3,
    borderRadius: 1,
  },
  stepLabel: {
    color: quCreateColor,
  },
})(StepConnector);

const usesweetAlertStyle = makeStyles(sweetAlertStyle);
export default function CreateCourseModal({
  newCourseDetails,
  setNewCourseDetails,
  iconImage,
  createCourseModal,
  handleCreateCourseModalClose,
  handleCreateCourse,
  handleIconUpload,
  courseCreationLoader,
}) {
  const classes = useStyles();
  const { showNotification } = React.useContext(NotificationContext);

  const sweetAlertClasses = usesweetAlertStyle();

  const dialogStages = [
    "Basic Details",
    "Instructions & Files",
    "Course Outline",
    "Submit",
  ];
  const stepperDisplayStages = [
    "Basic Details",
    "Instructions & Files",
    "Course Outline",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [currentDialog, setCurrentDialog] = useState(dialogStages[0]);
  const [courseOutlineLoader, setCourseOutlineLoader] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const generateCourseOutline = async () => {
    setCourseOutlineLoader(true);
    const formData = new FormData();
    newCourseDetails.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("instructions", newCourseDetails.template);
    try {
      const data = await generateCourseOutlineAPI(formData);
      setNewCourseDetails({ ...newCourseDetails, courseOutline: data });
      setCourseOutlineLoader(false);
    } catch (error) {
      showNotification(error, "danger");
      setCourseOutlineLoader(false);
    }
  };

  // Function to handle file upload
  const handleFileUpload = (files) => {
    setNewCourseDetails({ ...newCourseDetails, uploadedFiles: files });
  };

  // Handle next actions on dialogs
  const handleStep1 = () => {
    if (newCourseDetails.courseName === "" || newCourseDetails.brief === "") {
      showNotification(
        "Please make sure all fields, including the course name and description, are properly filled out.",
        "danger"
      );
      return;
    }
    setActiveStep(1);
    setCurrentDialog(dialogStages[1]);
  };

  const handleStep2 = async () => {
    if (newCourseDetails.template === "") {
      showNotification(
        "Please fill in the instructions for generating the course outline.",
        "danger"
      );
      return;
    }
    generateCourseOutline();
    setActiveStep(2);
    setCurrentDialog(dialogStages[2]);
  };

  const handleStep3 = () => {
    if (newCourseDetails.courseOutline === "") {
      showNotification("Please fill in the course outline.", "danger");
      return;
    }
    setActiveStep(3);
    setCurrentDialog(dialogStages[3]);
    handleCreateCourse();
  };

  const handleStep4 = (includeModules = true) => {
    handleCreateCourse(includeModules);
    setActiveStep(0);
    setCurrentDialog(dialogStages[0]);
  };

  const handleIncludeModules = (includeModules) => {
    hideAlert();
    setActiveStep(3);
    setCurrentDialog(dialogStages[3]);
    handleStep4(includeModules);
  };

  const noModuleAlert = () => {
    if (newCourseDetails.courseName === "" || newCourseDetails.brief === "") {
      showNotification(
        "Please make sure all fields, including the course name and description, are properly filled out.",
        "danger"
      );
      return;
    }
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleIncludeModules(false)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.success
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.danger
        }
        confirmBtnText="Yes, Create Course!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action will create a course with no modules in it.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const renderDialogContent = () => {
    switch (currentDialog) {
      case "Basic Details":
        return (
          <div>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              Enter the course details:
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="courseName"
              label="Course Name"
              placeholder="AI and Machine Learning in Finance"
              type="text"
              fullWidth
              required
              value={newCourseDetails.courseName}
              onChange={(event) =>
                setNewCourseDetails({
                  ...newCourseDetails,
                  courseName: event.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              id="brief"
              label="Brief Description"
              placeholder="This course provides a comprehensive introduction to the fundamentals of Artificial Intelligence (AI) and Machine Learning (ML) in finance."
              type="text"
              fullWidth
              multiline
              required
              rows={4}
              value={newCourseDetails.brief}
              onChange={(event) =>
                setNewCourseDetails({
                  ...newCourseDetails,
                  brief: event.target.value,
                })
              }
            />
            {/* Icon Image Upload */}
            <Button
              variant="contained"
              component="label"
              color="info"
              style={{ marginTop: "20px" }}
            >
              <CloudUpload /> &nbsp; Upload Image
              <input
                type="file"
                hidden
                accept="image/*"
                required
                onChange={handleIconUpload}
              />
            </Button>
            {iconImage && (
              <div style={{ marginTop: "10px" }}>
                <strong>Uploaded Image:</strong> {iconImage.name}
              </div>
            )}
            <Typography
              variant="caption"
              style={{ display: "block", marginTop: "5px" }}
            >
              A 16:9 aspect ratio image is recommended
            </Typography>
          </div>
        );
      case "Instructions & Files":
        return (
          <Grid container spacing={3} alignItems="flex-start">
            {/* Title for the Template Editing */}
            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
                Edit the instructions to customize it to your requirements:
              </Typography>
            </Grid>

            {/* MDEditor for Template Editing */}
            <Grid item xs={12} style={{ flexGrow: 1 }}>
              <div data-color-mode="light">
                <MDEditor
                  value={newCourseDetails.template
                    .replace("{{courseName}}", newCourseDetails.courseName)
                    .replace("{{courseDescription}}", newCourseDetails.brief)}
                  onChange={(event) =>
                    setNewCourseDetails({
                      ...newCourseDetails,
                      template: event,
                    })
                  }
                  height={250}
                />
              </div>
            </Grid>

            {/* Title for File Upload */}
            <Grid item xs={12} style={{ flexGrow: 1 }}>
              <Typography
                variant="h5"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Upload supporting files to generate the course outline:
              </Typography>
            </Grid>

            {/* DropzoneArea for File Upload */}
            <Grid item xs={12}>
              <DropzoneArea
                initialFiles={newCourseDetails.uploadedFiles}
                onChange={handleFileUpload}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                maxFileSize={50000000}
                acceptedFiles={[
                  "application/pdf",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "text/html",
                  "text/x-c++src",
                  "application/json",
                  "text/markdown",
                ]}
                previewText="Uploaded files"
                dropzoneText="Drag and drop files or click here."
                filesLimit={100}
                dropzoneClass={classes.dropzone}
              />
            </Grid>
          </Grid>
        );
      case "Course Outline":
        return (
          <div>
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              Course outline:
            </Typography>
            {courseOutlineLoader ? (
              <div style={{ display: "flex" }}>
                <CircularProgress size={20} /> &nbsp;
                <Typography variant="body1">
                  Generating course outline...
                </Typography>
              </div>
            ) : (
              <div data-color-mode="light">
                <MDEditor
                  value={newCourseDetails.courseOutline}
                  onChange={(event) =>
                    setNewCourseDetails({
                      ...newCourseDetails,
                      courseOutline: event,
                    })
                  }
                  height={400}
                />
              </div>
            )}
          </div>
        );
      case "Submit":
        return (
          <div style={{ display: "flex" }}>
            <CircularProgress size={20} /> &nbsp;
            <Typography variant="body1">Creating course for you...</Typography>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {alert}
      <Dialog
        open={createCourseModal}
        onClose={handleCreateCourseModalClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        {/* Stepper Component */}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {stepperDisplayStages.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Divider />
        <DialogTitle id="form-dialog-title">
          <div style={{ fontSize: "30px" }}>
            {newCourseDetails.courseName
              ? newCourseDetails.courseName
              : "Create a New Course"}
          </div>
        </DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions>
          {currentDialog !== "Basic Details" && (
            <Button
              onClick={() => {
                setActiveStep(activeStep - 1);
                setCurrentDialog(dialogStages[activeStep - 1]);
              }}
              color="quCreateColor"
              disabled={courseOutlineLoader || courseCreationLoader}
            >
              Back
            </Button>
          )}
          <Button
            onClick={handleCreateCourseModalClose}
            color="quCreateColor"
            disabled={courseOutlineLoader || courseCreationLoader}
          >
            Cancel
          </Button>
          {currentDialog === "Basic Details" && (
            <Button
              onClick={() => {
                noModuleAlert();
              }}
              color="quCreateColor"
            >
              Add modules Incrementally
            </Button>
          )}
          <Button
            onClick={() => {
              switch (currentDialog) {
                case "Basic Details":
                  return handleStep1();
                case "Instructions & Files":
                  return handleStep2();
                case "Course Outline":
                  return handleStep3();
                case "Submit":
                  return handleStep4();
                default:
                  return null;
              }
            }}
            disabled={courseOutlineLoader || courseCreationLoader}
            color="quCreateColor"
          >
            {currentDialog === "Course Outline" ? "Submit" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
