import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import styles from "assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.js";

const useStyles = makeStyles(styles);

export default function SnackbarContent(props) {
  const classes = useStyles();
  const { message, color, close, icon } = props;
  var action = [];
  const [copyMessage, setcopyMessage] = React.useState('Copy');
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined
  });
  const copy = () => {
    navigator.clipboard.writeText(message).then(function () {
      setcopyMessage('Copied')
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
      setcopyMessage('Error')
    })
  }
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
      >
        <Close className={classes.close} />
      </IconButton>
    ];
  }
  if (close === 'copy') {
    action = [
      <Tooltip
        id="tooltip-top"
        title={copyMessage}
        placement="bottom"
        key="copytooltip"
      >
        <IconButton
          className={classes.iconButton}
          key="copy"
          aria-label="copy"
          color="inherit"
          size='medium'
          onClick={copy}
        >
          <FileCopyOutlinedIcon className={classes.close} />
        </IconButton>
      </Tooltip>
    ];
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === "info",
    [classes.successIcon]: color === "success",
    [classes.warningIcon]: color === "warning",
    [classes.dangerIcon]: color === "danger",
    [classes.primaryIcon]: color === "primary",
    [classes.roseIcon]: color === "rose"
  });
  return (
    <Snack
      message={
        <div>
          {icon !== undefined ? <props.icon className={iconClasses} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      classes={{
        root: classes.root + " " + classes[color],
        message: classes.message
      }}
      action={action}
    />

  );
}

SnackbarContent.defaultProps = {
  color: "info"
};

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "rose",
    "transparent"
  ]),
  icon: PropTypes.object
};
